import './App.css';
import React from 'react';
import update from 'react-addons-update';
import { v2 } from './v2.mjs';

const canvasSize = 800;

const SPEED_ROTATION_MULT = 0.01;
const SPEED_TRANSFORM_MULT = 1;
const SPEED_SCALE_MULT = 0.01;

const DRAW = 0;
const EDIT = 1;
const ADD_DOT = 2;

class Canv extends React.Component {

  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();

    this.state = this.initialState(props);

    this.mouseClick = this.mouseClickEvent.bind(this);
    this.mouseDelete = this.mouseDeleteEvent.bind(this);
    this.mouseMove = this.mouseMoveEvent.bind(this);
    this.mouseRemove = this.mouseRemoveEvent.bind(this);
    this.mouseDouble = this.mouseDoubleEvent.bind(this);

    this.keyDown = this.keyDownEvent.bind(this);
  }

  initialState(_props) {
    return {
      obstacles: [],
      points: [],
      selectMove: -1,
      mode: DRAW,
      speed: 1,
      selected: -1,
    };
  }

  componentDidMount() {
    this.renderCanvas();
    document.addEventListener('mousedown', this.mouseClick);
    document.addEventListener('contextmenu', this.mouseDelete);
    document.addEventListener('keydown', this.keyDown);
    document.addEventListener('mouseup', this.mouseRemove);
    document.addEventListener('mousemove', this.mouseMove);
    document.addEventListener('dblclick', this.mouseDouble);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.mouseClick);
    document.removeEventListener('contextmenu', this.mouseDelete);
    document.removeEventListener('keydown', this.keyDown);
    document.removeEventListener('mouseup', this.mouseRemove);
    document.removeEventListener('mousemove', this.mouseMove);
    document.removeEventListener('dblclick', this.mouseDouble);
  }

  mouseRemoveEvent(_e) {
    this.setState({
      selected: -1,
    });
  }

  componentDidUpdate() {
    this.renderCanvas();
  }

  mouseClickEvent(e) {
    if (e.which === 3) return;

    const cursor = this.canvasCursor(e);
    if (!cursor) {
      return;
    }

    const { points, mode, obstacles, selectMove, addCursor } = this.state;
    if (mode === EDIT) {
      let selected = this.canvasCursorPointIndex(cursor);
      this.setState({ selected });
    } else if (mode === DRAW) {
      this.setState({
        points: points.concat(cursor),
      });
    } else if (mode === ADD_DOT) {
      const selected = this.canvasCursorPointIndex(cursor);
      const obstacle = obstacles[selectMove];
      if (selected === -1) return;
      this.setState({
        obstacles: update(obstacles, {
          [selectMove]: {
            $set: [...obstacle.slice(0, selected + 1), addCursor, ...obstacle.slice(selected + 1)],
          }
        }),
        mode: EDIT,
      })
    }
  }

  mouseDeleteEvent(e) {
    e.preventDefault();
    const cursor = this.canvasCursor(e);
    if (!cursor) {
      return;
    }

    let { obstacles, mode, points, selectMove } = this.state;
    if (mode === EDIT) {
      const selected = this.canvasCursorPointIndex(cursor);
      if (selected >= 0) {
        if (obstacles[selectMove].length === 1) {
          this.setState({
            obstacles: update(obstacles, {
              $splice: [[selectMove, 1]]
            }),
            selectMove: -1,
            mode: DRAW,
          });
          return;
        }
        this.setState({
          obstacles: update(obstacles, {
            [selectMove]: {
              $splice: [[selected, 1]]
            }
          }
          )
        });
      }
    }
    else if (mode === DRAW) {
      this.setState({
        points: points.slice(0, -1),
      });
    }
  }

  mouseDoubleEvent(e) {
    const { mode } = this.state;
    const cursor = this.canvasCursor(e);
    if (!cursor || mode === DRAW) {
      return;
    }

    this.setState({
      mode: ADD_DOT,
      addCursor: cursor,
    });
  }

  createObstacle() {
    const { points, obstacles, mode } = this.state;
    if (mode === EDIT) return;
    if (points.length < 3) {
      alert("3개이상의 점이 필요합니다.");
      return;
    }
    this.setState({
      obstacles: [...obstacles, points],
      points: [],
      mode: EDIT,
      selectMove: obstacles.length,
    });
  }

  saveObstacles() {
    const { obstacles } = this.state;
    window.localStorage.setItem("all", JSON.stringify(obstacles));
    for (let i = 0; i < obstacles.length; i++) {
      window.localStorage.setItem(i.toString(), JSON.stringify([obstacles[i]]));
    }
    this.setState({
      obstacles: [],
      points: [],
      selectMove: -1,
      selected: -1,
      mode: DRAW,
    });
  }

  deleteObstacle(i) {
    const { obstacles } = this.state;
    this.setState({
      obstacles: update(obstacles, {
        $splice: [[i, 1]]
      }),
      points: [],
      mode: DRAW,
      selectMove: -1,
    });
  }

  changeObstacle(x) {
    this.setState({
      selectMove: x,
      mode: EDIT,
      points: [],
    });
  }

  keyDownEvent(e) {
    const { obstacles, selectMove, speed } = this.state;
    if (selectMove === -1) return;

    let obstacle = obstacles[selectMove];

    const transform_amount = speed * SPEED_TRANSFORM_MULT;
    const rotation_amount = speed * SPEED_ROTATION_MULT;
    const scale_mult = speed * SPEED_SCALE_MULT;

    const centroid = v2.centroid(obstacle);
    if (e.key === 'r') {
      this.setState({
        selectMove: -1,
        mode: DRAW,
        points: [],
      });
      return;
    } else if (e.key === 'w') {
      obstacle = obstacle.map((p) => {
        return p.sub(new v2(0, transform_amount));
      });
    } else if (e.key === 's') {
      obstacle = obstacle.map(p => {
        return p.add(new v2(0, transform_amount));
      });
    } else if (e.key === 'a') {
      obstacle = obstacle.map(p => {
        return p.sub(new v2(transform_amount, 0));
      });
    } else if (e.key === 'd') {
      obstacle = obstacle.map(p => {
        return p.add(new v2(transform_amount, 0));
      });
    } else if (e.key === 'e') {
      obstacle = obstacle.map(p => {
        return p.add(new v2((p.x - centroid.x) * scale_mult, (p.y - centroid.y) * scale_mult));
      });
    } else if (e.key === 'q') {
      obstacle = obstacle.map(p => {
        return p.sub(new v2((p.x - centroid.x) * scale_mult, (p.y - centroid.y) * scale_mult));
      });
    } else if (e.key === 'z') {
      obstacle = obstacle.map(p => {
        return p.rot(centroid, rotation_amount);
      });
    } else if (e.key === 'c') {
      obstacle = obstacle.map(p => {
        return p.rot(centroid, -1 * rotation_amount);
      });
    } else {
      return;
    }

    this.setState({
      obstacles: update(obstacles, {
        [selectMove]: {
          $set: obstacle
        }
      }),
    });
  }

  handleInput(e) {
    this.local = e.target.value;
  }

  clickBtn(_e) {
    const { obstacles } = this.state;
    const objs = JSON.parse(localStorage.getItem(this.local));
    if (objs === null) return;
    const addObstacles = []
    for (const obj of objs) {
      addObstacles.push(obj.map(p => new v2(p.x, p.y)));
    }
    this.setState({
      obstacles: obstacles.concat(addObstacles),
    });
  }

  canvasCursor(e) {
    const canvas = this.canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    if (x < 0 || y < 0 || x > canvasSize || y > canvasSize) {
      return null;
    }

    return new v2(x, y);
  }

  canvasCursorPointIndex(cursor) {
    const { obstacles, selectMove } = this.state;
    for (let i = 0; i < obstacles[selectMove].length; i++) {
      const p = obstacles[selectMove][i];
      const dist = Math.abs(cursor.x - p.x) + Math.abs(cursor.y - p.y);
      if (dist < 30) {
        return i;
      }
    }
    return -1;
  }

  mouseMoveEvent(e) {
    const cursor = this.canvasCursor(e);
    if (!cursor) {
      return;
    }

    const { mode } = this.state;
    if (mode === DRAW) return;

    this.onMoveDot(cursor);
  }

  onMoveDot(cursor) {
    const { selected, obstacles, selectMove } = this.state;
    if (selected === -1) {
      return;
    }
    this.setState({
      obstacles: update(obstacles, {
        [selectMove]: {
          [selected]: {
            $set: cursor
          }
        }
      })
    });
  }

  speedChange() {
    const { speed } = this.state;
    this.setState({
      speed: speed === 1 ? 10 : 1,
    })
  }

  render() {
    const { obstacles, speed } = this.state;

    const ObstacleView = function (props) {
      const { obstacle, idx } = props;
      let txt = "";
      for (const p of obstacle) {
        txt += "(" + p.x.toFixed(1) + ", " + p.y.toFixed(1) + "),";
      }

      return (<div>
        <p>{idx} : </p>
        {txt.length >= 100 ? <p>{txt.slice(0, 100)} ... </p> : <p>{txt}</p>}
        <button onClick={this.deleteObstacle.bind(this, idx)}>삭제</button>
        <button onClick={this.changeObstacle.bind(this, idx)}>수정</button>
      </div>);
    }

    return (
      <>
        <canvas id="canvas" width={canvasSize} height={canvasSize}
          ref={this.canvasRef}
        ></canvas>
        <div>
          <button onClick={this.createObstacle.bind(this)}>make</button>
          <button onClick={this.speedChange.bind(this)}>speed={speed}</button>
        </div>
        <div className='box'>
          {obstacles.map((obj, i) => <ObstacleView obstacle={obj} idx={i} key={i}/>)}
        </div>
        <button onClick={this.saveObstacles.bind(this)}>저장</button>
        <div className='box'>
          <input type="text" onChange={this.handleInput.bind(this)} />
          <button onClick={this.clickBtn.bind(this)}>불러오기</button>
        </div>
      </>
    );
  }

  renderCanvas() {
    const { points, obstacles, selectMove, mode, addCursor } = this.state;

    const obstacle = obstacles[selectMove];

    const canvas = this.canvasRef.current;
    const ctx = canvas.getContext('2d');

    ctx.resetTransform();

    ctx.fillStyle = 'black';
    ctx.fillRect(0, 0, canvasSize, canvasSize);

    const drawObj = function (i) {
      const obj = obstacles[i];

      if (!obj) return;
      ctx.beginPath();
      ctx.moveTo(obj[0].x, obj[0].y);
      for (const points of obj.slice(1)) {
        ctx.lineTo(points.x, points.y);
      }
      ctx.closePath();
      ctx.fill();

      ctx.fillStyle = 'green';
      ctx.font = 'bold 24px gulim';
      const centroid = v2.centroid(obj)
      ctx.fillText(i.toString(), centroid.x, centroid.y);
    }

    const drawPoints = function (ps, size) {
      ctx.fillStyle = 'yellow';
      for (const point of ps) {
        ctx.beginPath();
        ctx.arc(point.x, point.y, size, 0, Math.PI * 2);
        ctx.fill();
      }
    }

    // obstacles 그리기
    for (let i = 0; i < obstacles.length; i++) {
      if (selectMove === i) continue;
      ctx.fillStyle = 'pink';
      drawObj(i);
    }

    // 수정 선택된 obstacle 그리기
    if (selectMove !== -1) {
      ctx.fillStyle = 'red';
      drawObj(selectMove);
      drawPoints(obstacle, 5);
    } else {
      drawPoints(points, 2);
    }

    if (mode === ADD_DOT) {
      ctx.fillStyle = 'pink';
      for (let i = 0; i < obstacle.length; i++) {
        const point = obstacle[i];
        ctx.font = 'bold 24px gulim';
        if (i === 0) {
          ctx.fillText(i.toString() + "/" + obstacle.length.toString(), point.x, point.y);
        }
        else ctx.fillText(i.toString(), point.x, point.y);
      }

      ctx.beginPath();
      ctx.arc(addCursor.x, addCursor.y, 5, 0, Math.PI * 2);
      ctx.fill();
    }
  }
}

class MapMake extends React.Component {

  render() {
    return <>
      <p>좌클릭: 점 생성, 우클릭: 최신 점 삭제</p>
      <p>--수정버튼--(only Eng)</p>
      <p>방향: wasd</p>
      <p>축소: e, 확대: q</p>
      <p>우회전: c, 좌회전: z</p>
      <p>점추가: 더블클릭 후 어느 점 다음에 추가할지 클릭</p>
      <p>점삭제: 해당 점 우클릭</p>
      <p>완료: r</p>
      <Canv />
    </>;
  }
}

export default MapMake;
