
import seedrandom from 'seedrandom';

export class Rng {
  static randomseed() {
    return Math.floor(Math.random() * 100000);
  }

  constructor (seed) {
    this.rng = seedrandom(seed);
  }

  randomRange (start, end) {
    const interval = end - start;
    return this.rng() * interval + start;
  }

  randomInt (start, end) {
    return Math.floor(this.randomRange(start, end + 1));
  }

  randomChoice (items) {
    return items[Math.floor(this.randomRange(0, items.length))];
  }
  randomAngle () {
    return this.randomRange(0, Math.PI * 2);
  }
}
