
let wasm;

const heap = new Array(32).fill(undefined);

heap.push(undefined, null, true, false);

function getObject(idx) { return heap[idx]; }

let heap_next = heap.length;

function dropObject(idx) {
    if (idx < 36) return;
    heap[idx] = heap_next;
    heap_next = idx;
}

function takeObject(idx) {
    const ret = getObject(idx);
    dropObject(idx);
    return ret;
}

const cachedTextDecoder = new TextDecoder('utf-8', { ignoreBOM: true, fatal: true });

cachedTextDecoder.decode();

let cachedUint8Memory0 = new Uint8Array();

function getUint8Memory0() {
    if (cachedUint8Memory0.byteLength === 0) {
        cachedUint8Memory0 = new Uint8Array(wasm.memory.buffer);
    }
    return cachedUint8Memory0;
}

function getStringFromWasm0(ptr, len) {
    return cachedTextDecoder.decode(getUint8Memory0().subarray(ptr, ptr + len));
}

function addHeapObject(obj) {
    if (heap_next === heap.length) heap.push(heap.length + 1);
    const idx = heap_next;
    heap_next = heap[idx];

    heap[idx] = obj;
    return idx;
}

function _assertClass(instance, klass) {
    if (!(instance instanceof klass)) {
        throw new Error(`expected instance of ${klass.name}`);
    }
    return instance.ptr;
}

let cachedFloat64Memory0 = new Float64Array();

function getFloat64Memory0() {
    if (cachedFloat64Memory0.byteLength === 0) {
        cachedFloat64Memory0 = new Float64Array(wasm.memory.buffer);
    }
    return cachedFloat64Memory0;
}

let WASM_VECTOR_LEN = 0;

function passArrayF64ToWasm0(arg, malloc) {
    const ptr = malloc(arg.length * 8);
    getFloat64Memory0().set(arg, ptr / 8);
    WASM_VECTOR_LEN = arg.length;
    return ptr;
}
/**
*/
export class Simplical {

    static __wrap(ptr) {
        const obj = Object.create(Simplical.prototype);
        obj.ptr = ptr;

        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.ptr;
        this.ptr = 0;

        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_simplical_free(ptr);
    }
    /**
    * @returns {Simplical}
    */
    static new() {
        const ret = wasm.simplical_new();
        return Simplical.__wrap(ret);
    }
    /**
    * @returns {Simplical}
    */
    dup() {
        const ret = wasm.simplical_dup(this.ptr);
        return Simplical.__wrap(ret);
    }
    /**
    * @param {number} x
    * @param {number} y
    * @param {number} extent_x
    * @param {number} extent_y
    * @param {number} rot
    * @param {number} subdivide
    * @returns {Simplical}
    */
    static from_rect(x, y, extent_x, extent_y, rot, subdivide) {
        const ret = wasm.simplical_from_rect(x, y, extent_x, extent_y, rot, subdivide);
        return Simplical.__wrap(ret);
    }
    /**
    * @returns {Float32Array}
    */
    simplices() {
        const ret = wasm.simplical_simplices(this.ptr);
        return takeObject(ret);
    }
    /**
    * @param {Simplical} other
    * @returns {Simplical}
    */
    union(other) {
        _assertClass(other, Simplical);
        const ret = wasm.simplical_union(this.ptr, other.ptr);
        return Simplical.__wrap(ret);
    }
    /**
    * @param {Simplical} other
    * @returns {Simplical}
    */
    intersect(other) {
        _assertClass(other, Simplical);
        const ret = wasm.simplical_intersect(this.ptr, other.ptr);
        return Simplical.__wrap(ret);
    }
    /**
    * @param {Simplical} other
    * @returns {Simplical}
    */
    subtract(other) {
        _assertClass(other, Simplical);
        const ret = wasm.simplical_subtract(this.ptr, other.ptr);
        return Simplical.__wrap(ret);
    }
}
/**
*/
export class Triangulated {

    static __wrap(ptr) {
        const obj = Object.create(Triangulated.prototype);
        obj.ptr = ptr;

        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.ptr;
        this.ptr = 0;

        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_triangulated_free(ptr);
    }
    /**
    * @param {Simplical} sim
    * @returns {Triangulated}
    */
    static from(sim) {
        _assertClass(sim, Simplical);
        const ret = wasm.triangulated_from(sim.ptr);
        return Triangulated.__wrap(ret);
    }
    /**
    * @param {number} x
    * @param {number} y
    * @returns {Float32Array}
    */
    visibility(x, y) {
        const ret = wasm.triangulated_visibility(this.ptr, x, y);
        return takeObject(ret);
    }
    /**
    * @param {number} x
    * @param {number} y
    * @param {number} limit
    * @returns {Float32Array}
    */
    visibility_limit(x, y, limit) {
        const ret = wasm.triangulated_visibility_limit(this.ptr, x, y, limit);
        return takeObject(ret);
    }
    /**
    * @param {number} x
    * @param {number} y
    * @param {number} limit
    * @param {Float64Array} gridinfo
    * @param {Float32Array} grid
    * @returns {number}
    */
    visibility_limit_fill(x, y, limit, gridinfo, grid) {
        const ptr0 = passArrayF64ToWasm0(gridinfo, wasm.__wbindgen_malloc);
        const len0 = WASM_VECTOR_LEN;
        const ret = wasm.triangulated_visibility_limit_fill(this.ptr, x, y, limit, ptr0, len0, addHeapObject(grid));
        return ret >>> 0;
    }
}

async function load(module, imports) {
    if (typeof Response === 'function' && module instanceof Response) {
        if (typeof WebAssembly.instantiateStreaming === 'function') {
            try {
                return await WebAssembly.instantiateStreaming(module, imports);

            } catch (e) {
                if (module.headers.get('Content-Type') != 'application/wasm') {
                    console.warn("`WebAssembly.instantiateStreaming` failed because your server does not serve wasm with `application/wasm` MIME type. Falling back to `WebAssembly.instantiate` which is slower. Original error:\n", e);

                } else {
                    throw e;
                }
            }
        }

        const bytes = await module.arrayBuffer();
        return await WebAssembly.instantiate(bytes, imports);

    } else {
        const instance = await WebAssembly.instantiate(module, imports);

        if (instance instanceof WebAssembly.Instance) {
            return { instance, module };

        } else {
            return instance;
        }
    }
}

function getImports() {
    const imports = {};
    imports.wbg = {};
    imports.wbg.__wbindgen_object_drop_ref = function(arg0) {
        takeObject(arg0);
    };
    imports.wbg.__wbg_buffer_34f5ec9f8a838ba0 = function(arg0) {
        const ret = getObject(arg0).buffer;
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_newwithbyteoffsetandlength_fb619fd76ea1f132 = function(arg0, arg1, arg2) {
        const ret = new Float32Array(getObject(arg0), arg1 >>> 0, arg2 >>> 0);
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_new_8544c2225dbdbc42 = function(arg0) {
        const ret = new Float32Array(getObject(arg0));
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_setindex_8fa021c83e585549 = function(arg0, arg1, arg2) {
        getObject(arg0)[arg1 >>> 0] = arg2;
    };
    imports.wbg.__wbindgen_throw = function(arg0, arg1) {
        throw new Error(getStringFromWasm0(arg0, arg1));
    };
    imports.wbg.__wbindgen_memory = function() {
        const ret = wasm.memory;
        return addHeapObject(ret);
    };

    return imports;
}

function initMemory(imports, maybe_memory) {

}

function finalizeInit(instance, module) {
    wasm = instance.exports;
    init.__wbindgen_wasm_module = module;
    cachedFloat64Memory0 = new Float64Array();
    cachedUint8Memory0 = new Uint8Array();


    return wasm;
}

function initSync(bytes) {
    const imports = getImports();

    initMemory(imports);

    const module = new WebAssembly.Module(bytes);
    const instance = new WebAssembly.Instance(module, imports);

    return finalizeInit(instance, module);
}

async function init(input) {
    if (typeof input === 'undefined') {
        input = new URL('asm_bg.wasm', import.meta.url);
    }
    const imports = getImports();

    if (typeof input === 'string' || (typeof Request === 'function' && input instanceof Request) || (typeof URL === 'function' && input instanceof URL)) {
        input = fetch(input);
    }

    initMemory(imports);

    const { instance, module } = await load(await input, imports);

    return finalizeInit(instance, module);
}

export { initSync }
export default init;
