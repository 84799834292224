import squarify from 'squarify';
import { v2 } from './v2.mjs';

const DEFAULT_WALL_WIDTH = 5;
const DEFAULT_DOOR_OFFSET = 10;
const DEFAULT_DOOR_SIZE = 30;
export const DEFAULT_SECTION_SIZE = 50;

const VERTICAL = 0;
const HORIZONTAL = 1;

function intersect(a, b, c, d) {
  return (a === c && b === d) || (a < d && b > c);
}

class Segment {
  constructor(start, end, type) {
    this.x0 = start.x;
    this.y0 = start.y;
    this.x1 = end.x;
    this.y1 = end.y;
    this.start = start.clone();
    this.end = end.clone();
    this.type = type;
  }

  edgeContacted(other, type) {
    if (type === VERTICAL) {
      return this.x0 === other.x0
        && intersect(this.y0, this.y1, other.y0, other.y1);
    } else {
      return this.y0 === other.y0
        && intersect(this.x0, this.x1, other.x0, other.x1);
    }
  }

  findDoorStart(other) {
    if ((this.x0 === other.x0 && this.y0 > other.y0)
      || (this.y0 === other.y0 && this.x0 > other.x0)) {
      return this.start.clone();
    } else {
      return other.start.clone();
    }
  }

  findDoorEnd(other) {
    if ((this.x0 === other.x0 && this.y1 < other.y1)
      || (this.y0 === other.y0 && this.x1 < other.x1)) {
      return this.end.clone();
    } else {
      return other.end.clone();
    }
  }
}

class Room {
  constructor(x0, y0, x1, y1) {
    this.x0 = x0;
    this.y0 = y0;
    this.x1 = x1;
    this.y1 = y1;

    this.tl = new v2(x0, y0);
    this.tr = new v2(x1, y0);
    this.bl = new v2(x0, y1);
    this.br = new v2(x1, y1);

    this.edge = [new Segment(this.tl, this.tr, HORIZONTAL), new Segment(this.bl, this.br, HORIZONTAL),
    new Segment(this.tl, this.bl, VERTICAL), new Segment(this.tr, this.br, VERTICAL)];
  }

  roomContacted(other) {
    for (let i = 0; i < 2; i++) {
      for (let j = 0; j < 2; j++) {
        if (this.edge[i].edgeContacted(other.edge[j], HORIZONTAL)) {
          return new Segment(this.edge[i].findDoorStart(other.edge[j]),
            this.edge[i].findDoorEnd(other.edge[j]), HORIZONTAL);
        }
      }
    }
    for (let i = 2; i < 4; i++) {
      for (let j = 2; j < 4; j++) {
        if (this.edge[i].edgeContacted(other.edge[j], VERTICAL)) {
          return new Segment(this.edge[i].findDoorStart(other.edge[j]),
            this.edge[i].findDoorEnd(other.edge[j]), VERTICAL);
        }
      }
    }
    return false;
  }

  findContact(livingroom, rooms) {
    for (const n of livingroom) {
      const contact = this.roomContacted(rooms[n]);
      if (contact) {
        return contact;
      }
    }
    return null;
  }

  makeDoor(livingroom, rooms, offset, size) {
    const contact = this.findContact(livingroom, rooms);
    if (!contact) return;
    let start = contact.start;
    let end = start;
    let door;
    if (contact.type === VERTICAL) {
      door = new Segment(start.add(new v2(0, offset)), end.add(new v2(0, offset + size)), VERTICAL);
    } else {
      door = new Segment(start.add(new v2(offset, 0)), end.add(new v2(offset + size, 0)), HORIZONTAL);
    }
    this.door = door;
    return door;
  }
}

export function fitToGrid(x, gridSize) {
  return Math.floor((x + gridSize / 2) / gridSize) * gridSize;
}

export function squarifyFitToGrid(rooms, container) {

  return squarify.default(rooms, container).map((v) => {
    const x0 = fitToGrid(v.x0, DEFAULT_SECTION_SIZE);
    const x1 = fitToGrid(v.x1, DEFAULT_SECTION_SIZE);
    const y0 = fitToGrid(v.y0, DEFAULT_SECTION_SIZE);
    const y1 = fitToGrid(v.y1, DEFAULT_SECTION_SIZE);
    return new Room(x0, y0, x1, y1);
  });
}

export class Structure {
  static create(rng, props) {
    for (let i = 0; i < 10; i++) {
      const s = new Structure(rng, props);
      if (!s.failed) {
        return s;
      }
    }
    return null;
  }

  constructor(rng, props) {
    props = props ?? {};

    const startX = props.x ?? 0;
    const startY = props.y ?? 0;
    const width = props.width ?? rng.randomInt(5, 7) * 100;
    const height = props.height ?? rng.randomInt(5, 7) * 100;
    let roomCount = props.roomCount ?? 0;

    const wall_width = props.wall_width ?? DEFAULT_WALL_WIDTH;
    const door_offset = props.door_offset ?? DEFAULT_DOOR_OFFSET;
    const door_size = props.door_size ?? DEFAULT_DOOR_SIZE;

    if (roomCount === 0) {
      roomCount = rng.randomInt(5, 10);
    }
    let rooms = [];
    for (let i = 0; i < roomCount; i++) {
      rooms.push({ value: rng.randomInt(3, 10) });
    }

    const container = new Room(startX, startY, startX + width, startY + height);

    const squarifyTreemap = squarifyFitToGrid(rooms, container);

    const edges = [];
    const n = squarifyTreemap.length;

    for (let i = 0; i < n; i++) {
      for (let j = i + 1; j < n; j++) {
        if (squarifyTreemap[i].roomContacted(squarifyTreemap[j])) {
          edges.push({ from: i, to: j });
          edges.push({ from: j, to: i });
        }
      }
    }

    edges.sort((a, b) => {
      if (a.from !== b.from) {
        return a.from - b.from;
      }
      return a.to - b.to;
    });

    //몇번째부터 시작인지
    const edgeN = [0,];
    for (let i = 1; i < edges.length; i++) {
      if (edges[i].from !== edges[i - 1].from) {
        edgeN.push(i);
      }
    }

    let reachables = 0;
    const reachablemap = new Uint8Array(squarifyTreemap.length);
    const merged = [];

    function liveDegree(from) {
      let degree = 0;
      for (let i = edgeN[from]; i < edgeN[from === n ? edges.length : from + 1]; i++) {
        if (edges[i].from === from && !reachablemap[edges[i].to]) {
          degree += 1;
        }
      }
      return degree;
    }

    function markReachable(n) {
      if (!reachablemap[n]) {
        reachables += 1;
        reachablemap[n] = 1;
      }
    }

    function visit(from) {
      merged.push(from);
      markReachable(from);
      for (const edge of edges) {
        if (edge.from === from) {
          markReachable(edge.to);
        }
      }
    }

    function findMaxDegree() {
      // find max-degree node
      let max_idx = 0;
      let max_degree = 0;

      for (let i = 0; i < n; i++) {
        const degree = liveDegree(i);
        if (degree > max_degree) {
          max_degree = degree;
          max_idx = i;
        }
      }
      return max_idx;
    }

    let start = findMaxDegree();
    visit(start);

    while (reachables < n) {
      let max_idx = -1;
      let max_degree = -1;

      for (let i = 0; i < n; i++) {
        if (merged.includes(i) || !reachablemap[i]) {
          continue;
        }

        const degree = liveDegree(i);
        if (degree > max_degree) {
          max_degree = degree;
          max_idx = i;
        }
      }
      visit(max_idx);
    }

    let doors = [];
    let contactOuterDoor = container.makeDoor(merged, squarifyTreemap, door_offset, door_size);
    if (contactOuterDoor) {
      // 첫 번째 문, 구조 밖으로 생성되어야 함.
      doors.push(contactOuterDoor);
    } else {
      // TODO
      this.failed = true;
      return;
    }

    let walls = [];
    walls = walls.concat(makeWalls(container));

    for (let i = 0; i < squarifyTreemap.length; i++) {
      const room = squarifyTreemap[i];
      if (merged.includes(i)) {
        continue;
      }

      doors.push(room.makeDoor(merged, squarifyTreemap, door_offset, door_size));

      walls = walls.concat(makeWalls(room));
    }

    function makeWalls(room) {
      function createRect0(start, end, type) {
        return new Segment(start, end.add(new v2(DEFAULT_WALL_WIDTH, DEFAULT_WALL_WIDTH)), type);
      }

      function createRect(edge) {
        // split wall if there's room
        const door = room.door;
        const type = edge.type
        if (edge.edgeContacted(door, type)) {
          return [
            createRect0(edge.start, door.start, type),
            createRect0(door.end, edge.end, type),
          ];
        }
        return [createRect0(edge.start, edge.end, type)];
      }

      let walls = [];
      for (let i = 0; i < 4; i++) {
        walls = walls.concat(createRect(room.edge[i]));
      }
      return walls;
    }

    const thickness = wall_width;
    for (const door of doors) {
      door.x1 += thickness;
      door.y1 += thickness;
      door.end.x += thickness;
      door.end.y += thickness;
    }

    const houseDots = [
      new v2(startX, startY),
      new v2(startX + width, startY + height)
    ];
    const pos = v2.centroid(houseDots);

    this.squarifyTreemap = squarifyTreemap;
    this.merged = merged;
    this.doors = doors;
    this.walls = walls;
    this.rooms = rooms;
    this.pos = pos;
  }
}

export function placeStructures (rng, opts) {
  const { center, extent, count } = opts;

  let rooms = [];
  if (rooms.length === 0) {
    for (let i = 0; i < count; i++) {
      rooms.push({ value: rng.randomInt(3, 5) });
    }
  }
  const tl = center.sub(extent);
  const br = center.add(extent);
  const container = { x0: tl.x, y0: tl.y, x1: br.x, y1: br.y };
  const squarifyTreemap = squarifyFitToGrid(rooms, container);

  function drop(a) {
    return a - a % DEFAULT_SECTION_SIZE;
  }

  let placements = [];
  for (let area of squarifyTreemap) {
    if (area.x1 - area.x0 < 110 || area.y1 - area.y0 < 110) {
      continue;
    }

    let dots = [
      new v2(area.x0, area.y0),
      new v2(area.x1, area.y0),
      new v2(area.x1, area.y1),
      new v2(area.x0, area.y1)];

    const pos = v2.centroid(dots);
    const dir = rng.randomAngle() / 6;
    let ratio = 1;
    for (const dot of dots) {
      const rotDot = dot.rot(pos, dir);
      if (rotDot.x < area.x0) {
        ratio = Math.min((area.x0 - pos.x) / (rotDot.x - pos.x), ratio);
      }
      if (rotDot.x > area.x1) {
        ratio = Math.min((area.x1 - pos.x) / (rotDot.x - pos.x), ratio);
      }
      if (rotDot.y < area.y0) {
        ratio = Math.min((area.y0 - pos.y) / (rotDot.y - pos.y), ratio);
      }
      if (rotDot.y > area.y1) {
        ratio = Math.min((area.y1 - pos.y) / (rotDot.y - pos.y), ratio);
      }
    }

    const width = drop((area.x1 - area.x0) * ratio);
    const height = drop((area.y1 - area.y0) * ratio);

    if (width <= 50 || height <= 50) {
      continue;
    }

    const center = new v2(area.x0 + area.x1, area.y0 + area.y1).mul(0.5);
    placements.push({
      center,
      offset: center.sub(new v2(width, height).mul(0.5)),
      extent: new v2(width / 2, height / 2),
      dir,
    });
  }

  return placements;
}

export function createStructures(rng, placements) {
  const structures = [];
  for (let placement of placements) {
    const { extent } = placement;
    const width = extent.x * 2;
    const height = extent.y * 2;

    if (width <= 50 || height <= 50) {
      continue;
    }

    const roomCountMax = Math.max(width, height) / 50;
    const roomCountMin = Math.min(width, height) / 50;
    const roomCount = rng.randomInt(roomCountMin, roomCountMax);

    // TODO: 공간 크기에 따라 방 개수를 적절히 정하게 -chae
    const structure = Structure.create(rng, {
      x: 0, y: 0,
      width, height,
      roomCount
    });

    structures.push({
      ...placement,
      structure,
    });
  }

  return structures;
}
