import './App.css';
import React from 'react';
import _ from 'lodash';

import { opts, ENTITY_CONFIG_TMPL } from './opts';
import { World } from './world.mjs';
import { v2 } from './v2.mjs';
import { Rng } from './rand.mjs';
import { Simulator, checkcover, routePathfind, routePathfindAll, hostilityNetwork, stats_populate } from './sim';
import {
  team0_tmpl_agent_dmr_mid,
  team0_tmpl_indoor_smg_low,
  team0_tmpl_rescue_ar_low,
  team0_tmpl_firearm_sg,
  team0_tmpl_agent_sg,
  tmpl_firearm_ar_low,
  tmpl_bulletproof_low,
  team0_tmpl_agent_sg_high,
  team0_tmpl_agent_ar_mid,
  tmpl_firearm_ar_high,
  tmpl_firearm_ar_mid,
  enemy_bulletproof_mid,
} from './presets.mjs';

const TEAM_COLORS = [
  'lightgreen',
  'red',
  'cyan',
];

const sw = function (msg, f) {
  const start = Date.now();
  const ret = f();
  console.log(`${msg} took ${Date.now() - start}ms`);
  return ret;
}

function EntityView(props) {
  const { entity, onEntityOver, entityOver,
    onDebugCover, onDebugRoute, onDebugUpdateGrid, onDebugScore, onDebugDist, onMST } = props;
  let dir = Math.floor(entity.dir * 180 / Math.PI).toString();

  let cls = 'box';
  if (entityOver === entity) {
    cls += ' entity-over';
  }

  let waypoint_dist = 0;
  let at_waypoint = false;
  if (entity.waypoint) {
    at_waypoint = entity.pos.eq(entity.waypoint.pos);
    waypoint_dist = entity.pos.dist(entity.waypoint.pos) / 10;
  }

  const printrules = entity.rules.map((r) => {
    return {
      ty: r.ty,
      area: r.area?.name || null,
      target: r.target?.name || null,
      goal: r.goal?.goalstate || null,
    };
  });
  printrules.reverse();

  return <div className={cls} onMouseOver={() => onEntityOver(entity)}>
    {entity.team} {entity.name} {entity.waypoint_rule.ty}/{entity.state} {Math.floor(entity.pos.x)}x{Math.floor(entity.pos.y)} dir={dir}
    <br />
    {`life=${entity.life} ammo=${entity.ammo} moving=${entity.moving} aim=${entity.aimmult.toFixed(3)}/${entity.aimvar.toFixed(3)}/${entity.aimtarget?.name ?? 'none'} `}<br />
    {printrules.map((r, i) => <p key={i}>{JSON.stringify(r)}</p>)}
    <div>
      debug {`waypoint=${waypoint_dist.toFixed(1)}m/${at_waypoint}`}
      <button onClick={() => onDebugCover(entity)}>cover</button>
      <button onClick={() => onDebugRoute(entity)}>route</button>
      <button onClick={() => onDebugUpdateGrid(entity)}>grid</button>
      <button onClick={() => onDebugScore(entity)}>score</button>
      <button onClick={() => onDebugDist(entity)}>dist</button>
      <button onClick={() => onMST(entity)}>mst</button>
    </div>
  </div>;
}

function GoalView(props) {
  const { goal } = props;
  return <div className="box">
    {goal.name}: {JSON.stringify(goal.goalstate)}
  </div>;
}

const V2Edit = (props) => {
  const { pos, onChange } = props;
  return <>
    <input type="number" value={pos.x} onChange={(e) => { pos.x = parseInt(e.target.value); onChange(pos) }} />
    <input type="number" value={pos.y} onChange={(e) => { pos.y = parseInt(e.target.value); onChange(pos) }} />
  </>;
};

class EntityConfig extends React.Component {
  render() {
    const { entity, onChange, onDelete } = this.props;

    function onChangeInt(k) {
      return (e) => {
        entity[k] = parseInt(e.target.value);
        onChange(entity);
      }
    }

    function onChangeFloat(k) {
      return (e) => {
        entity[k] = parseFloat(e.target.value);
        onChange(entity);
      }
    }

    function onChangeBool(k) {
      return (e) => {
        entity[k] = !entity[k];
        onChange(entity);
      }
    }

    function renderInt(k, min, max) {
      return <>
        {k}: <input type="number" value={entity[k]} min={min} max={max}
          onChange={onChangeInt(k)} /><br />
      </>;
    }

    function renderFloat(k, min, max) {
      return <>
        {k}: <input type="number" value={entity[k]} min={min} max={max}
          onChange={onChangeFloat(k)} /><br />
      </>;
    }

    function renderBool(k) {
      return <>
        {k}: <input type="checkbox" checked={entity[k]}
          onChange={onChangeBool(k)} /><br />
      </>;
    }

    function renderPos() {
      let posview = null;
      const has_pos = entity.pos !== undefined;
      if (has_pos) {
        posview = <>
          <V2Edit pos={entity.pos} onChange={(pos) => { entity.pos = pos; onChange(entity) }} />
          <br />
        </>;
      }

      return <>
        specify pos?: <input type="checkbox" checked={has_pos}
          onChange={() => {
            if (has_pos) {
              delete (entity.pos);
            } else {
              entity.pos = new v2(200, 200);
            }
            onChange(entity);
          }} /><br />
        {posview}
      </>;
    }

    return <div className="box">
      name: (random)<br />
      {renderInt('team', 0, 1)}
      {renderInt('speed', 10, 1000)}
      {renderInt('firearm_range', 10, 1000)}
      {renderFloat('firearm_rot_mult', 0, 10)}
      {renderBool('allow_crawl')}
      {renderBool('allow_hide')}
      {renderPos()}
      <button onClick={() => onDelete(entity)}>delete</button>
    </div>
  }
}

function AreaEdit(props) {
  const { area, onChange } = props;
  return <div className="box">
    <V2Edit pos={area.pos} onChange={(pos) => { area.pos = pos; onChange(area) }} />
    <br />
    <V2Edit pos={area.size} onChange={(size) => { area.size = size; onChange(area) }} />
    <br />
    <button onClick={() => this.onDeleteArea(area)}>delete</button>
  </div>;
}


export class Configurator extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fold_areas: true,
      fold_entities: true,
    };
  }

  onNewEntity() {
    this.props.onNewEntity();
  }

  onDeleteEntity(e) {
    this.props.onDeleteEntity(e);
  }

  onChangeEntity(e) {
    this.props.onChangeEntity(e);
  }

  onNewArea() {
    this.props.onNewArea();
  }

  onChangeArea(a) {
    this.props.onChangeArea(a);
  }

  render() {
    let { entities, spawnareas } = this.props;
    const { fold_entities, fold_areas } = this.state;

    const toggleState = (k) => {
      return (e) => {
        const obj = {};
        obj[k] = !this.state[k];
        this.setState(obj);
      };
    }

    if (fold_areas) {
      spawnareas = [];
    }

    if (fold_entities) {
      entities = [];
    }

    return <div>
      <p>configurator</p>
      <div className="box">
        <p>spawnareas</p>
        fold? <input type="checkbox" checked={fold_areas} onChange={toggleState('fold_areas')} />
        <button onClick={this.onNewArea.bind(this)}>add</button>

        {spawnareas.map((area, i) => <AreaEdit key={i} area={area} onChange={this.onChangeArea.bind(this)} />)}
      </div>

      <div className="box">
        <p>entity configs </p>
        fold? <input type="checkbox" checked={fold_entities} onChange={toggleState('fold_entities')} />
        <button onClick={this.onNewEntity.bind(this)}>add</button>
        {entities.map((entity, i) =>
          <EntityConfig key={i} entity={entity}
            onChange={this.onChangeEntity.bind(this)}
            onDelete={this.onDeleteEntity.bind(this)}
          />)}
      </div>
    </div>;
  }
}


const SCALES = [2, 1];

function renderGrid(ctx, world, grid) {
  for (let y = 0; y < world.grid_count_y; y++) {
    for (let x = 0; x < world.grid_count_x; x++) {
      let idx = world.idx(new v2(x, y));

      let posX = x * opts.GRID_SIZE - world.width / 2;
      let posY = y * opts.GRID_SIZE - world.height / 2;

      const val = grid[idx];

      const style = `rgba(${Math.floor(val * 255)}, 0, 0, 0.3)`;

      ctx.fillStyle = style;
      ctx.fillRect(posX, posY, opts.GRID_SIZE, opts.GRID_SIZE);
    }
  }

}

export class Sim extends React.Component {
  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
    this.timer = null;
    this.restartTimer = null;

    this.state = this.initialState(props);
    this.keyBind = this.keyDown.bind(this);

    this.last_ms = Date.now();

    this.onTimer = () => {
      let { last_ms } = this;
      let { simtps } = this.state;
      const now = Date.now();

      let idx = 0;
      while (last_ms < now && idx < 2) {
        last_ms += 1000 / simtps;
        this.onTick();
        const stats = stats_populate();
        this.setState({ stats });
        idx += 1;
      }

      this.startTimer();
      this.last_ms = last_ms;
    };
  }

  initialState(props) {
    const sim = null;

    return {
      sim,
      seed: 0,
      simtps: opts.SIMTPS,

      paused: false,

      debugRenderRoute: false,
      debugRenderRisk: false,
      entityOver: null,

      scale: 1,

      states: stats_populate(),
      debugScore: [],
      debugDist: [],
      debugScoreProp: 'score',
      debugScoreRule: 'covergoal',

      debugMST: [],
      debugGrid: null,
    };
  }

  componentDidMount() {
    if (!this.state.paused) {
      this.startTimer();
    }

    document.addEventListener('keydown', this.keyBind);

    this.setState(this.newSimState(this.props, this.props.seed), () => {
      this.renderCanvas();
    });
  }

  newSimState(props, seed) {
    if (isNaN(seed)) {
      seed = Rng.randomseed();
    }
    const sim = new Simulator({ ...props, seed });
    return { seed, sim };
  }

  startTimer() {
    const { simtps } = this.state;
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
    this.last_ms = Date.now();
    this.timer = setTimeout(this.onTimer, 1000 / simtps);
  }

  stopTimer() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  }

  componentWillUnmount() {
    this.stopTimer();
    document.removeEventListener('keydown', this.keyBind);
  }

  componentDidUpdate() {
    this.renderCanvas();
  }

  keyDown(ev) {
    const { sim, paused, scale, simtps } = this.state;
    if (!sim) {
      return;
    }

    if (ev.key === 'r' && !(ev.metaKey || ev.ctrlKey)) {
      ev.preventDefault();
      if (paused) {
        this.startTimer();
      }
      this.setState(this.newSimState(this.props, this.props.seed), () => {
        sim.free();
      });
    } else if (ev.key === 'R') {
      // replay with same seed
      ev.preventDefault();
      if (paused) {
        this.startTimer();
      }
      const seed = this.state.seed;

      const seed2 = Rng.randomseed();
      const initstate = this.newSimState(this.props, seed);
      this.setState({
        ...initstate,
        rng: new Rng(seed2),
      }, () => {
        sim.free();
      });
    } else if (ev.key === 'b') {
      const seed = this.state.seed;

      const sim = new Simulator({ ...this.props, seed });
      // reset runtime seed
      const seed2 = Rng.randomseed();
      sim.rng = new Rng(seed2);

      console.log('before', sim);
      const start = Date.now();
      while (sim.onTick() === -1 && sim.tick < 10000) { }
      const res = sim.onTick();
      sim.free();
      const dt = Date.now() - start;
      console.log(`took ${dt}ms, ${(sim.tick * 1000 / dt).toFixed(1)}tps`, res, sim);

    } else if (ev.key === 'z') {
      const idx = SCALES.indexOf(scale);
      this.setState({
        scale: SCALES[(idx + 1) % SCALES.length],
      });
      // TODO
    } else if (ev.key === ' ') {
      ev.preventDefault();
      if (paused) {
        this.startTimer();
      } else {
        this.stopTimer();
      }
      this.setState({ paused: !paused });
    } else if (ev.key === 's') {
      // single step
      ev.preventDefault();
      this.onTick();
    } else if (ev.key === 'Tab') {
      ev.preventDefault();

      let idx = opts.SIMTPS_OPTS.indexOf(simtps);
      this.setState({
        simtps: opts.SIMTPS_OPTS[(idx + 1) % (opts.SIMTPS_OPTS.length)],
      });
    } else {
      console.log('keyev', ev);
    }
  }

  renderCanvas() {
    const canvas = this.canvasRef.current;
    const { sim, entityOver, mousepos, scale } = this.state;
    if (!sim) {
      return;
    }
    const { debugRenderRoute, debugRenderRisk, debugMST } = this.state;
    const { world, seed, entities, spawnareas, routes, tick, obstacles, trails, goals } = sim;
    const ctx = canvas.getContext('2d');

    ctx.resetTransform();

    ctx.font = '12px monospace bold';
    ctx.fillStyle = 'black';
    ctx.fillRect(0, 0, world.width, world.height);

    const renderProgressBar = function (pos, size, ratio) {
      pos = pos.round();
      const b = 1;

      ctx.fillStyle = 'white';
      ctx.fillRect(pos.x, pos.y, size.x, size.y);
      ctx.fillStyle = 'red';
      ctx.fillRect(pos.x + b, pos.y + b, (size.x - b * 2) * ratio, size.y - b * 2);
    }

    ctx.translate(world.width / 2, world.height / 2);
    ctx.scale(scale, scale);

    const renderSymbolHexagon = function (canvasX, canvasY, size) {
      ctx.beginPath();
      let moved = false;
      for (let i = 0; i < 7; i++) {
        // hexagon
        const angle = (30 + i * 60) * Math.PI / 180;
        let x = canvasX + Math.sin(angle) * size;
        let y = canvasY + Math.cos(angle) * size;
        if (!moved) {
          ctx.moveTo(x, y);
          moved = true;
        } else {
          ctx.lineTo(x, y);
        }
      }
      ctx.stroke();
    }

    function renderCircle(pos, size) {
      ctx.beginPath();
      ctx.arc(pos.x, pos.y, size, 0, Math.PI * 2);
      ctx.stroke();
    }

    function renderPoly(polygon) {
      const start = polygon[polygon.length - 1];
      ctx.beginPath();
      ctx.moveTo(start.x, start.y);
      for (const p of polygon) {
        ctx.lineTo(p.x, p.y);
      }
    }

    if (debugRenderRoute) {
      ctx.strokeStyle = 'rgba(0, 0, 255)';
      ctx.beginPath();
      for (const edge of routes.edges) {
        ctx.moveTo(edge.from.x, edge.from.y);
        ctx.lineTo(edge.to.x, edge.to.y);
      }
      ctx.stroke();
    }

    if (debugMST) {
      ctx.strokeStyle = 'cyan';
      ctx.beginPath();
      for (const edge of debugMST) {
        ctx.moveTo(edge.from.x, edge.from.y);
        ctx.lineTo(edge.to.x, edge.to.y);
      }
      ctx.stroke();

      /*
      ctx.strokeStyle = 'cyan';
      ctx.beginPath();
      for (const node of debugMST) {
        const { edge } = node;
        ctx.moveTo(edge.from.x, edge.from.y);
        ctx.lineTo(edge.to.x, edge.to.y);
      }
      ctx.stroke();

      ctx.strokeStyle = 'white';
      ctx.beginPath();
      for (const node of debugMST) {
        const { edge, leaf } = node;
        if (!leaf) {
          continue;
        }
        ctx.moveTo(edge.from.x, edge.from.y);
        ctx.lineTo(edge.to.x, edge.to.y);
      }
      ctx.stroke();
      */
    }

    for (const entity of entities) {
      const { aimdir, pos } = entity;

      const dead = entity.state === 'dead';
      const teamcolor = TEAM_COLORS[entity.team];
      ctx.strokeStyle = dead ? 'gray' : teamcolor;

      renderCircle(pos, entity.size);

      // dir
      if (!dead) {
        ctx.beginPath();
        ctx.moveTo(pos.x, pos.y);

        const dirX = Math.sin(entity.dir) * 10;
        const dirY = -1 * Math.cos(entity.dir) * 10;
        ctx.lineTo(pos.x + dirX, pos.y + dirY);
        ctx.stroke();
      }

      // aimdir
      if (!dead) {
        // debugaimdir
        if (entity === entityOver) {
          const dirvec = v2.fromdir(entity.debugaimdir).mul(100);
          const end = pos.add(dirvec);

          const oldwidth = ctx.lineWidth;
          ctx.lineWidth = 10;
          ctx.strokeStyle = 'blue';
          ctx.beginPath();
          ctx.moveTo(pos.x, pos.y);
          ctx.lineTo(end.x, end.y);
          ctx.stroke();

          ctx.lineWidth = oldwidth;
        }

        const dirvec = v2.fromdir(aimdir).mul(20);
        const end = pos.add(dirvec);

        ctx.beginPath();
        ctx.moveTo(pos.x, pos.y);
        ctx.lineTo(end.x, end.y);
        ctx.stroke();

        // aimarc
        ctx.beginPath();
        ctx.moveTo(pos.x, pos.y);

        const aimvar = entity.aimvar * entity.firearm_aimvar_mult;
        ctx.strokeStyle = entity.team === 0 ? 'gray' : 'rgba(255, 255, 255, 0.15)';
        // TODO
        ctx.arc(pos.x, pos.y, entity.firearm_range
          , entity.aimdir - aimvar - Math.PI / 2
          , entity.aimdir + aimvar - Math.PI / 2);
        ctx.lineTo(pos.x, pos.y);
        ctx.stroke();
      }

      // route
      if (true || entity === entityOver) {
        // render route
        ctx.strokeStyle = 'red';
        ctx.beginPath();
        if (entity.waypoint && entity.waypoint.path) {
          const path = entity.waypoint.path;
          for (let i = 0; i < path.length; i++) {
            const p = path[i].pos;
            if (i === 0) {
              ctx.moveTo(p.x, p.y);
            } else {
              ctx.lineTo(p.x, p.y);
            }
          }
        }
        ctx.stroke();
      }

      // highlight: aimtarget info
      if (entity === entityOver && entity.aimtarget) {
        const p = entity.aimtarget.pos;
        renderSymbolHexagon(p.x, p.y, 5);

        const aimdist = entity.aimtarget.pos.dist(pos) / 10;
        ctx.fillStyle = 'yellow';
        ctx.fillText(`${aimdist.toFixed(0)}m`, p.x, p.y + 20);
      }

      // highlight: riskdir
      if (debugRenderRisk && entity === entityOver) {
        const { samples, samples_dist, sample_count, selected_idx, selected_val, pos } = sim.riskdir0(entity);

        function renderRiskLine(i, label) {
          let dir = i * Math.PI * 2 / sample_count;
          let dirvec = v2.fromdir(dir);

          let lineend = pos.add(dirvec.mul(samples_dist[i]));

          ctx.beginPath();
          ctx.moveTo(pos.x, pos.y);
          ctx.lineTo(lineend.x, lineend.y);
          ctx.stroke();

          ctx.fillText(label, lineend.x, lineend.y);
        }

        ctx.strokeStyle = 'white';
        ctx.fillStyle = 'white';

        for (let i = 0; i < sample_count; i++) {
          renderRiskLine(i, samples[i].toFixed(0));
        }

        ctx.fillStyle = 'red';
        renderRiskLine(selected_idx, selected_val.toFixed(0));
      }

      // highlight: perception grid
      if (entity === entityOver) {
        renderGrid(ctx, world, entity.grid);
      }

      ctx.fillStyle = dead ? 'gray' : teamcolor;
      ctx.fillText(`${entity.name}`, pos.x, pos.y - 14);
      ctx.fillText(`${entity.state}/${entity.life}(${entity.armor})/${entity.ammo}`, pos.x, pos.y);

      if (!entity.reloadTick.expired(tick)) {
        renderProgressBar(pos, new v2(50, 6), entity.reloadTick.progress(tick));
      }
    }

    for (let i = 0; i < spawnareas.length; i++) {
      const obj = spawnareas[i];

      // obstacle outline
      ctx.strokeStyle = 'yellow';
      renderPoly(obj.polygon);
      ctx.stroke();

      ctx.fillStyle = 'yellow';
      ctx.fillText(obj.name, obj.pos.x, obj.pos.y);

      if (obj.areastate.structures) {
        for (const structure of obj.areastate.structures) {
          for (const room of structure.structure.squarifyTreemap) {
            renderPoly(room.shape.polygon);
            ctx.stroke();
          }
        }
      }
    }

    for (const node of routes.nodes) {
      ctx.strokeStyle = node.is_coverpoint ? 'gray' : 'blue';
      renderCircle(node.pos, 1);
    }

    for (const obj of obstacles) {
      // obstacle outline
      if (obj.goalstate) {
        ctx.fillStyle = 'purple';
      } else if (obj.ty === 'full') {
        ctx.fillStyle = 'lightgray';
      } else if (obj.ty === 'half') {
        ctx.strokeStyle = 'lightgray';
      } else if (obj.ty === 'door') {
        ctx.strokeStyle = 'darkcyan';
        ctx.fillStyle = 'darkcyan';
      } else {
        console.log(`unexpected ty=${obj.ty}`, obj);
      }

      renderPoly(obj.polygon);

      if (obj.ty === 'full' || obj.goalstate || (obj.ty === 'door' && !obj.doorstate.open)) {
        ctx.fill();
      } else {
        ctx.stroke();
      }
    }

    for (let i = 0; i < goals.length; i++) {
      ctx.strokeStyle = 'purple';
      ctx.fillStyle = 'purple';

      const obj = goals[i];
      renderCircle(obj.pos, opts.GOAL_RADIUS);

      const { owner, occupying_team, occupy_tick } = obj.goalstate;
      let txt = obj.name;
      if (owner >= 0) {
        txt += ` own ${owner}`;
      } else if (occupying_team >= 0) {
        const ticks = sim.ticksFromSec(opts.GOAL_OCCUPY_DURATION);
        txt += ` hold ${occupying_team} ${tick - occupy_tick}/${ticks}`;
      }
      ctx.fillText(txt, obj.pos.x + 10, obj.pos.y);
    }

    for (const trail of trails) {
      const { hit, pos, dir, len } = trail;
      const duration = hit ? opts.TRAIL_HIT_DURATION : opts.TRAIL_DURATION;

      if (trail.tick + sim.ticksFromSec(duration) <= tick) {
        continue;
      }

      ctx.strokeStyle = hit ? 'white' : 'gray';
      ctx.beginPath();
      ctx.moveTo(pos.x, pos.y);

      const dirX = Math.sin(dir) * len;
      const dirY = -1 * Math.cos(dir) * len;

      ctx.lineTo(pos.x + dirX, pos.y + dirY);
      ctx.stroke();
    }

    const { debugScore, debugScoreProp } = this.state;

    if (debugScore) {
      ctx.fillStyle = 'green';
      for (const item of debugScore) {
        const { query, node } = item;
        const { pos } = node;
        if (!query || query[debugScoreProp] === undefined) {
          continue;
        }

        let v = query[debugScoreProp];
        if (!isNaN(v)) {
          v = v.toFixed(0);
        }
        if (v instanceof Object) {
          v = JSON.stringify(v);
        }
        ctx.fillText(v, pos.x, pos.y);
      }
    }

    const { debugDist } = this.state;
    if (debugDist) {
      for (let i = 0; i < debugDist.length; i++) {
        let dist = debugDist[i];
        let { pos } = sim.routes.nodes[i];


        ctx.fillText(dist.toFixed(0), pos.x, pos.y);
      }
    }

    const { debugGrid } = this.state;
    if (debugGrid) {
      ctx.fillStyle = 'white';
      ctx.font = '8px monospace';

      const { risks } = debugGrid;

      for (let y = 0; y < world.grid_count_y; y++) {
        for (let x = 0; x < world.grid_count_x; x++) {
          const idx = world.idx(new v2(x, y));
          const cost = risks[idx];
          if (cost < 2) {
            continue;
          }
          const pos = this.state.world.gridToWorld(new v2(x, y));

          ctx.fillText(`${cost}`, pos.x, pos.y);
          /*
          ctx.fillStyle = `rgba(${Math.floor(cost)}, 0, 0, 0.3)`;
          ctx.fillRect(pos.x, pos.y, opts.GRID_SIZE, opts.GRID_SIZE);
          */
        }
      }
    }

    function decodePoints(res) {
      const points = [];
      for (let i = 0; i < res.length/2; i++) {
        const mult = 1;
        const x = res[i*2+0] * mult;
        const y = res[i*2+1] * mult;
        points.push(new v2(x, y));
      }

      return points;
    }

    if (false) {
      const simplices = sim.sx.simplices();
      ctx.strokeStyle = 'purple';
      ctx.beginPath();
      const simpoints = decodePoints(simplices);
      for (let i = 0; i < simpoints.length/2; i++) {
        let p0 = simpoints[i*2+0];
        let p1 = simpoints[i*2+1];
        ctx.moveTo(p0.x, p0.y);
        ctx.lineTo(p1.x, p1.y);
      }
      ctx.stroke();

      if (mousepos) {
        const mouseworldpos = mousepos.sub(new v2(world.width/2, world.height/2)).mul(1/scale);
        let res = sim.triangulated.visibility_limit(mouseworldpos.x, mouseworldpos.y, 400);
        const vispoints = decodePoints(res);

        if (vispoints.length > 0) {
          ctx.strokeStyle = 'white';
          ctx.beginPath();
          ctx.moveTo(vispoints[vispoints.length-1].x, vispoints[vispoints.length-1].y);
          for (let i = 0; i < vispoints.length; i++) {
            ctx.lineTo(vispoints[i].x, vispoints[i].y);
          }
          ctx.stroke();
        }

        const buf = new Float32Array(world.grid_count);
        sim.triangulated.visibility_limit_fill(
          mouseworldpos.x, mouseworldpos.y, 400,
          [-world.width/2, -world.height/2, world.width, world.height, opts.GRID_SIZE],
          buf
        );
        renderGrid(ctx, world, buf);
      }
    }

    ctx.resetTransform();

    ctx.fillStyle = 'white';
    ctx.font = '12px monospace';
    const mapWidth = Math.floor(world.width / 10 / scale);
    const mapHeight = Math.floor(world.height / 10 / scale);
    let msg = `seed=${seed}, tick=${tick}, scale=${scale}/[${mapWidth}m x ${mapHeight}m], network=${routes.nodes.length}/${routes.edges.length}`;
    if (mousepos) {
      msg += `, mousepos=${mousepos.x}x${mousepos.y}`;

      const mouseworldpos = mousepos.sub(new v2(world.width/2, world.height/2)).mul(1/scale);
      msg += `/${mouseworldpos.x}x${mouseworldpos.y}`;
    }

    ctx.fillText(msg, 5, 12);

    const perfbuf = sim.perfbuf;
    const perfsum = _.sumBy(perfbuf, (a) => a.dt);
    const tick_ms = perfsum / perfbuf.length;

    let tick_tps = sim.tps;
    if (perfbuf.length > 2) {
      const now = Date.now();
      const start = _.minBy(perfbuf, (a) => a.start);
      tick_tps = 1000 * perfbuf.length / (now - start.start);
    }

    msg = `tps=${sim.tps}, targettps=${this.state.simtps}, realtps=${tick_tps.toFixed(1)}, ${tick_ms.toFixed(1)}ms`;
    ctx.fillText(msg, 5, 24);
  }

  onEntityOver(e) {
    this.setState({ entityOver: e });
  }

  onDebugCover(entity) {
    if (entity.aimtarget === null) {
      return;
    }
    const { obstacles } = this.state;

    const cover = checkcover(entity.aimtarget.pos, entity.pos, obstacles);
    console.log('cover', cover, entity);
  }

  onDebugRoute(entity) {
    if (entity.aimtarget === null) {
      return;
    }
    const { routes } = this.state;

    let cp = null;
    if (entity.waypoint_rule.ty === 'fire') {
      cp = this.choosefirepoint(entity.aimtarget);
    } else {
      cp = this.choosecoverpoint(entity);
    }

    if (cp === null) {
      console.log('onDebugRoute: cp === null');
      return;
    }

    const atcp = cp?.pos.eq(entity.pos);

    const path = routePathfind(routes, entity.pos, cp.pos, null, true);
    console.log('onDebugRoute', entity, cp, path, `atcp=${atcp}`);
  }

  onDebugUpdateGrid(entity) {
    const start = Date.now();

    // this.sim.entityUpdateGrids(entity);
    const res = this.state.sim.entityUnexploredGrids(entity, entity.pos);

    this.setState({ debugGrid: res });
    console.log(`grid took ${Date.now() - start}ms`);
  }

  projectEvent(e) {
    const c = this.canvasRef.current;
    const rect = c.getBoundingClientRect();
    const x = Math.floor(e.clientX - rect.left);
    const y = Math.floor(e.clientY - rect.top);
    return new v2(x, y);
  }

  canvasMouseMove(e) {
    const pos = this.projectEvent(e);

    this.setState({ mousepos: pos });
  }

  onDebugDist(entity) {
    const { sim } = this.state;
    const dist = routePathfindAll(sim.routes, entity.pos);

    this.setState({ debugDist: dist });
  }

  onDebugScore(entity) {
    const { debugScoreRule, sim } = this.state;
    // const items = this.choosefirepoint(entity.aimtarget, this.debugchoosepoint.bind(this));
    let items = [];
    if (!debugScoreRule) {
      console.error(`debugScoreRule not specified`);
      return;
    }

    const f = sim.debugchoosepoint.bind(sim);
    if (debugScoreRule === 'cover') {
      items = sim.choosecoverpoint(entity, 1000000, f);
    } else if (debugScoreRule === 'capture') {
      items = sim.choosecapturepoint(entity, null, f);
    } else if (debugScoreRule === 'covergoal') {
      items = sim.choosecovergoalpoint(entity, null, f);
    } else {
      const fname = `choose${debugScoreRule}point`;
      if (sim[fname]) {
        items = (sim[fname].bind(sim))(entity, f);
        console.log(items);
      } else {
        console.error(`unknown function: ${fname}`);
      }
    }

    this.setState({
      debugScore: items,
    });
  }

  onMST(entity) {
    const { sim } = this.state;

    const out = sw('hostilityNetwork', () => hostilityNetwork(sim.routes, entity.pos));
    this.setState({ debugMST: out });
  }

  renderDebugScoreRule() {
    const rules = ['fire', 'cover', 'explore', 'capture', 'covergoal'];

    const onDebugScoreRule = (r) => { this.setState({ debugScoreRule: r }); }
    return <>
      <p>debugrule</p>
      {rules.map((p, i) => <button key={i} onClick={() => onDebugScoreRule(p)}>{p}</button>)}
    </>;
  }

  renderDebugScoreProps() {
    const { debugScore } = this.state;
    if (!debugScore) {
      return null;
    }

    let props = [];
    for (const item of debugScore) {
      if (item.query) {
        props = Object.keys(item.query);
        break;
      }
    }

    const onDebugScoreProp = (p) => {
      this.setState({
        debugScoreProp: p,
      });
    }

    return <>
      <p>debugprops</p>
      {props.map((p, i) => <button key={i} onClick={() => onDebugScoreProp(p)}>{p}</button>)}
    </>;
  }

  renderDebug() {
    return <>
      {this.renderDebugScoreRule()}
      {this.renderDebugScoreProps()}
    </>;
  }

  restart() {
    if (this.restartTimer !== null) {
      return;
    }

    this.stopTimer();
    this.restartTimer = setTimeout(() => {
      this.restartTimer = null;
      this.startTimer();
      this.setState(this.newSimState(this.props, this.props.seed));
    }, 1000);
  }

  onTick() {
    const { sim } = this.state;
    const res = sim.onTick();

    if (res >= 0) {
      this.restart();
    }

    this.renderCanvas();
    this.setState({ sim });
  }

  render() {
    const { sim, entityOver, debugRenderRoute, debugRenderRisk } = this.state;
    if (!sim) {
      return;
    }
    const { world, entities, goals, journal } = sim;

    return (
      <>
        <div>
          debug
          <button onClick={this.onTick.bind(this)}>step</button>
          <button onClick={() => {
            this.setState({ debugRenderRoute: !debugRenderRoute });
          }}>toggle net</button>
          <button onClick={() => {
            this.setState({ debugRenderRisk: !debugRenderRisk });
          }}>toggle risknet</button>
          <button onClick={() => this.setState({ entityOver: null })}>unselect</button>
          {this.renderDebug()}
          <p style={{wordWrap: "break-word"}}>
            stats={JSON.stringify(this.state.stats)}
          </p>
        </div>

        <canvas id="canvas" width={world.width} height={world.height}
          ref={this.canvasRef}
          onMouseMove={this.canvasMouseMove.bind(this)}
        ></canvas>
        <div>
          <p>info</p>
          {entities.map((e, i) =>
            <EntityView key={i} entity={e}
              onEntityOver={this.onEntityOver.bind(this)}
              onDebugCover={this.onDebugCover.bind(this)}
              onDebugRoute={this.onDebugRoute.bind(this)}
              onDebugUpdateGrid={this.onDebugUpdateGrid.bind(this)}
              onDebugScore={this.onDebugScore.bind(this)}
              onDebugDist={this.onDebugDist.bind(this)}
              onMST={this.onMST.bind(this)}
              entityOver={entityOver}
            />)}
          {goals.map((item, i) => <GoalView key={i} idx={i} goal={item} />)}
        </div>

        <div>
          <p>journal</p>
          {journal.map((s, i) => <div key={i}>{s}</div>)}
        </div>
      </>
    );
  }
}

function preset_rescue () {
  const world = new World({ width: 800, height: 1600 });
  const size = world.height / 2 - 100;

  const spawnSize = new v2(100, 20);

  // 적 에이전트 스펙 정의
  const team1_tmpl = {
    ...ENTITY_CONFIG_TMPL, team: 1, spawnarea: 1,
    armor: 1,
    default_rule: 'idle', allow_crawl: false, use_visibility: true
  };

  const team1_tmpl_cover_t = {
    ...team1_tmpl, spawnarea: 3,
    default_rule: 'cover', use_visibility: false
  };
  const team1_tmpl_cover_d = {
    ...team1_tmpl_cover_t, spawnarea: 4, group: 0,
    default_rule: 'dummy'
  };

  const vip_tmpl = {
    ...ENTITY_CONFIG_TMPL,
    ty: 'vip',
    team: 2,
    default_rule: 'idle',
    firearm_range: 5,
    firearm_aimvar_mult: 30,

    speed: 20,
    spawnarea: 1,
  };

  const room_size = new v2(world.width - 100, 500).mul(0.5);
  const buf = 200;

  const buf2 = 100;

  return {
    world,

    obstacle_spec: {
      center: new v2(0, 0),
      extent: new v2(
        (world.width / 2) - 100,
        (world.height / 2) - 100,
      ),
      count: 30,
    },

    entities: [
      team0_tmpl_agent_dmr_mid,
      team0_tmpl_agent_dmr_mid,
      team0_tmpl_agent_dmr_mid,
      team0_tmpl_agent_dmr_mid,
      team0_tmpl_agent_dmr_mid,
      team0_tmpl_agent_dmr_mid,

      vip_tmpl,

      team1_tmpl,
      team1_tmpl,
      team1_tmpl,
      team1_tmpl,
      team1_tmpl,

      team1_tmpl_cover_t,
      team1_tmpl_cover_t,
      team1_tmpl_cover_t,
      team1_tmpl_cover_t,

      team1_tmpl_cover_d,
      team1_tmpl_cover_d,
      team1_tmpl_cover_d,
    ],

    spawnareas: [
      { pos: new v2(0, -size), extent: spawnSize, heading: 0 },
      { pos: new v2(0, 0), extent: room_size, heading: 0, vacate: true,
        structureopts: {
          count: 3,
        }
      },
      { pos: new v2(0, +size), extent: spawnSize, heading: 0 },


      {
        pos: new v2(0, -(world.height / 4 + room_size.y) + buf / 2),
        extent: new v2(world.width - buf, world.height / 2 - room_size.y / 2 - buf).mul(0.5), heading: 0
      },
      {
        pos: new v2(0, +(world.height / 4 + room_size.y) + buf / 2),
        extent: new v2(world.width - buf, 100).mul(0.5), heading: 0
      },

      // trigger area
      {
        pos: new v2(0, +(world.height / 4 + room_size.y) - buf2 / 2),
        extent: new v2(world.width - buf2, world.height / 2 - room_size.y / 2 - buf2).mul(0.5),
        heading: 0,

        // TODO
        triggers: [
          {
            condition: 'enter',
            conditiontarget: {
              ty: 'entity',
              team: 0,
            },

            action: 'push_rule',
            actiontarget: {
              ty: 'entity',
              group: 0,
            },
            actionrule: { ty: 'cover' },
          }
        ],
      },
    ],

    goals: [
      { name: 'escape area', area: 2 },
    ],

    mission_rules: [
      { ty: 'capture' },
      { ty: 'explore', area: 4 },
      { ty: 'explore', area: 1 },
      { ty: 'explore', area: 3 },
    ],
  };
}

function preset_indoor () {
  const world = new World({
    width: 600,
    height: 600,
    simover_rule: 'eliminate',
    exp_prepopulate_grid: true,
  });
  const size = world.height / 2 - 100;

  const spawnSize = new v2(100, 20);
  const room_size = new v2(150, 150);

  const team1_tmpl = {
    ...ENTITY_CONFIG_TMPL, team: 1, spawnarea: 1,
    life: 50,
    armor: 20,
    armor_hit_prob: 0.4,
    default_rule: 'idle', allow_crawl: false, use_visibility: true,
    use_riskdir: false,
  };

  return {
    world,

    obstacle_spec: {
      center: new v2(0, 0),
      extent: new v2(
        (world.width / 2) - 100,
        (world.height / 2) - 100,
      ),
      count: 0,
    },

    entities: [
      team0_tmpl_indoor_smg_low,
      team0_tmpl_indoor_smg_low,

      team1_tmpl,
      team1_tmpl,
      team1_tmpl,
      team1_tmpl,
      team1_tmpl,
      team1_tmpl,
      team1_tmpl,
      team1_tmpl,
      team1_tmpl,
    ],

    spawnareas: [
      { pos: new v2(0, -200), extent: spawnSize, heading: 0 },
      { pos: new v2(0, 0), extent: room_size, heading: 0, vacate: true,
        structureopts: {
          count: 1,
        }
      },
      { pos: new v2(0, +size), extent: spawnSize, heading: 0 },
    ],

    goals: [
    ],

    mission_rules: [
      { ty: 'capture' },
      { ty: 'explore', area: 4 },
      { ty: 'explore', area: 1 },
      { ty: 'explore', area: 3 },
    ],
  };
}

function preset_outdoor () {
  const world = new World({
    width: 800,
    height: 800,
    simover_rule: 'eliminate',
    exp_team_shared_grid: true,
    exp_team_shared_grid_team1: true,
  });
  const size = world.height / 2 - 100;

  const spawnSize = new v2(100, 20);

  const team1_tmpl = {
    ...enemy_bulletproof_mid,
    ...tmpl_firearm_ar_low,
    life: 50,
    speed: 35,

    team: 1, spawnarea: 1,
    default_rule: 'explore',
    use_visibility: true,
    use_riskdir: true,
  };

  return {
    world,

    obstacle_spec: {
      center: new v2(0, 0),
      extent: new v2(
        (world.width / 2) - 100,
        (world.height / 2) - 100,
      ),
      count: 30,
    },

    entities: [
      team0_tmpl_agent_sg_high,
      team0_tmpl_agent_ar_mid,

      team1_tmpl,
      team1_tmpl,
      team1_tmpl,
      team1_tmpl,
    ],

    spawnareas: [
      { pos: new v2(0, -size), extent: spawnSize, heading: 0 },
      { pos: new v2(0, +size), extent: spawnSize, heading: 0 },
    ],

    goals: [
    ],

    mission_rules: [
      { ty: 'capture' },
      { ty: 'explore', area: 4 },
      { ty: 'explore', area: 1 },
      { ty: 'explore', area: 3 },
    ],
  };
}


export class App extends React.Component {
  constructor(props) {
    super(props);

    this.serializeRef = React.createRef();
    this.simRef = React.createRef();

    const presets = {
      'rescue': preset_rescue,
      'indoor': preset_indoor,
      'outdoor': preset_outdoor,
    };
    const preset_selected = 'indoor';

    this.state = {
      presets,
      preset_selected,
      simstate: presets[preset_selected](),
    };
  }

  onChangeConfig(entities) {
    this.setState({ entities });
  }

  onExport () {
    const state = {...this.state.simstate};
    state.seed = this.simRef.current.state.seed;

    const text = JSON.stringify(state);
    this.serializeRef.current.value = text;
  }

  onImport () {
    const state = JSON.parse(this.serializeRef.current.value);
    this.setState({simstate: state});
    this.simRef.current.restart();
  }

  onPreset (p) {
    const { presets } = this.state;
    this.setState({
      preset_selected: p,
      simstate: presets[p](),
    });
    this.simRef.current.restart();
  }

  render() {
    const { presets, simstate, seed } = this.state;

    return <>
      <p>shortcuts: (r) restart / (R) restart with same map / (z) zoom / (Tab) change speed / ( ) pause/resume / (s) single-step</p>
      <div>
        presets
        {Object.keys(presets).map((p) => {
          return <button onClick={() => this.onPreset(p)} key={p}>{p}</button>;
        })}
      </div>
      <div>
        save&load
        <button onClick={this.onExport.bind(this)}>export</button>
        <button onClick={this.onImport.bind(this)}>import</button>
        <input ref={this.serializeRef} type="text"></input>
      </div>
      <Sim ref={this.simRef}
        seed={seed}
        {...simstate}
      />
    </>;
  }
}
