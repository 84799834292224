import React from 'react';
import * as m from 'asm';

export default class Wasm extends React.Component {
  constructor(props) {
    super(props);

    this.canvasRef = React.createRef();
    this.state = {
      canvasSize: 400,
      rot: 0,
      simplices: new Float64Array(0),
      visibility: new Float64Array(0),
    };
  }

  componentDidMount() {
    window.requestAnimationFrame(this.onAnimationFrame.bind(this));
  }

  onAnimationFrame() {
    let rot = this.state.rot + 1 / 60;
    this.setState({ rot });

    window.requestAnimationFrame(this.onAnimationFrame.bind(this));
  }

  componentDidUpdate() {
    this.renderCanvas();
  }

  renderCanvas() {
    const { canvasSize, rot } = this.state;
    let sx = m.Simplical.new();

    for (let i = 0; i < 10; i++) {
        const sx0 = m.Simplical.from_rect(i+0.1, i+0.1, 2, 2, rot, 1);
        const sx1 = sx.union(sx0);

        sx.free();
        sx0.free();
        sx = sx1;
    }

    const triangulated = m.Triangulated.from(sx);
    const visibility = triangulated.visibility(0, 0);
    triangulated.free();

    const canvas = this.canvasRef.current;
    const ctx = canvas.getContext('2d');

    ctx.resetTransform();
    ctx.fillStyle = 'black';
    ctx.fillRect(0, 0, canvasSize, canvasSize);

    ctx.translate(canvasSize / 2, canvasSize / 2);

    ctx.strokeStyle = 'white';
    ctx.beginPath();
    for (let i = 0; i < visibility.length/2; i++) {
      const x = visibility[i*2+0] * 10;
      const y = visibility[i*2+1] * 10;

      if (i === 0) {
        ctx.moveTo(x, y);
      } else {
        ctx.lineTo(x, y);
      }
    }
    ctx.closePath();
    ctx.stroke();

    let simplices = sx.simplices();
    ctx.strokeStyle = 'yellow';
    ctx.beginPath();
    for (let i = 0; i < simplices.length/4; i++) {
      const x0 = simplices[i*4+0] * 10;
      const y0 = simplices[i*4+1] * 10;
      const x1 = simplices[i*4+2] * 10;
      const y1 = simplices[i*4+3] * 10;

      ctx.moveTo(x0, y0);
      ctx.lineTo(x1, y1);
    }
    ctx.stroke();
  }

  render() {
    return <>
      <canvas width="400" height="400" ref={this.canvasRef} />
    </>;
  }
}
