import { v2 } from './v2.mjs';
import { opts } from './opts.mjs';

export class World {
  constructor (params) {
    const { width, height } = params;

    this.width = width;
    this.height = height;

    this.grid_count_x = width / opts.GRID_SIZE;
    this.grid_count_y = height / opts.GRID_SIZE;
    this.grid_count = this.grid_count_x * this.grid_count_y;

    // TODO: mission, goal, eliminate
    this.simover_rule = params.simover_rule ?? 'mission';

    // misc
    // 맵 주변부 시야를 시작할 때 밝힙니다. 실내 탐색 디버깅용
    this.exp_prepopulate_grid = params.exp_prepopulate_grid ?? false;

    this.exp_team_shared_grid = params.exp_team_shared_grid ?? true;
    this.exp_team_shared_grid_team1 = params.exp_team_shared_grid_team1 ?? false;
  }

  idx(p) {
    return p.y * this.grid_count_x + p.x;
  }

  valid(p) {
    const { x, y } = p;
    return x >= 0 && x < this.grid_count_x && y >= 0 && y < this.grid_count_y;
  }

  gridIdxToWorld(idx) {
    let x = idx % this.grid_count_x;
    let y = Math.floor(idx / this.grid_count_x);
    return this.gridToWorld(new v2(x, y));
  }

  gridToWorld(p) {
    const { x, y } = p;
    return new v2(
      (x + 0.5) * opts.GRID_SIZE - this.width / 2,
      (y + 0.5) * opts.GRID_SIZE - this.height / 2,
    );
  }

  worldToGrid(p) {
    return new v2(
      Math.floor((p.x + this.width / 2) / opts.GRID_SIZE),
      Math.floor((p.y + this.height / 2) / opts.GRID_SIZE),
    );
  }
}
