// persistent properties
export const ENTITY_CONFIG_TMPL = {
  ty: 'agent',
  team: 0,
  life: 100,
  speed: 20,
  size: 2.5,

  firearm_ammo_max: 30,
  firearm_range: 400,
  firearm_aimvar_mult: 1,

  // 매 tick마다 이동으로 벌어질 수 있는 aim의 최대값입니다.
  firearm_aimvar_incr_move_cap: 0.01,

  // 매 tick마다 회전으로 벌어질 수 있는 aim의 최대값입니다.
  firearm_aimvar_incr_rot_cap: 0.3,

  // 한 사격 시퀀스 안에서의 사격 패턴. 예를 들어 firearm_shoot_pattern이 [0.1,
  // 0.1, 0.1]인 경우, 0.1초 간격으로 네 번 연속으로 사격합니다. 이후,
  // firearm_shoot_pattern_interval_sec 만큼 사격을 중지한 후 다음 사격
  // 시퀀스를 시작합니다.
  firearm_shoot_pattern: [0.2,0.2],
  firearm_shoot_pattern_interval_sec: 0.5,

  firearm_projectile_per_shoot: 1,
  firearm_projectile_damage: 33,
  // 매 사격에서 projectile의 방향은 다음 단계로 나뉘어 결정됩니다.
  //  - 먼저, aimvar (현재 agent의 aim을 표현하는 arc) 중 한 방향으로 총구의
  //  방향이 정해집니다. 해당 shoot에서 발사되는 모든 projectile의 기준 총구
  //  방향은 이 방향으로 고정됩니다.
  //  - 이후, 각 projectile이 총구 방향에서 얼마나 분산되는지 결정합니다. 이
  //  정도는 아래 firearm_projectile_aimvar 값에 따라 결정됩니다. 0인 경우,
  //  projectile은 항상 총구 방향으로 발사됩니다. PI/2인 경우, 총구 방향에서
  //  좌우 45도(PI/4) 사이로 발사됩니다.
  firearm_projectile_aimvar: Math.PI / 256,

  firearm_reload_duration: 2.5,
  firearm_reload_idle_visibility_duration: 2.5,
  firearm_reload_idle_duration: 10,

  // 피격 판정에 이 만큼의 이득을 제공합니다. 예를 들어, 0.1인 경우 피격 확률이
  // 10%p 상승합니다.
  firearm_additional_hit_prob: 0.1,


  aimvar_hold: Math.PI / 32,
  // 오랜 시간 움직이지 않고 가만히 있는 경우에 대한 최소 aim입니다.
  aimvar_hold_max: Math.PI / 256,
  // 매 사격마다 aim이 얼마나 벌어지는지 결정합니다.
  aimvar_incr_per_shoot: 0.4,

  // 피격당했을 때 aim이 얼마나 벌어지는지 결정합니다.
  aimvar_incr_per_hit: 0.4,

  // projectile이 조준 대상에게 한 개 이상 맞을 확률이 아래 이상일 경우에만
  // 사격합니다.
  aim_samples_fire_thres: 0.0,

  // 방호복 내구도. 방호복에 피격하는 경우, life 대신 방호복의 내구도가
  // 소모됩니다. 방호복의 내구도가 0인 경우, 방호복에 피격하는 경우에도 life가
  // 감소합니다.
  armor: 100,
  // 방호복 피격 확률. projectile이 방호복에 피격하는 경우, life 대신 armor를
  // 먼저 차감합니다.
  armor_hit_prob: 0.75,


  // behavioral options
  allow_crawl: true,
  allow_hide: false,

  // cover, fire, cover-fire, capture, idle
  default_rule: 'cover',

  use_visibility: false,
  use_riskdir: true,

  spawnarea: 0,

  // agent가 목표물을 조준할 때, 조준 대상 방향과 현재 방향 사이의 각거리
  // (angular distance)에 따른 agent의 회전 속도를 정의합니다. 이 값을 조절하여
  // 조준 대상 방향과 현재 방향의 차이가 큰 경우 조준이 빠르게, 차이가 적은
  // 경우 조준이 느리게 진행되도록 조절할 수 있습니다.
  aim_rot_rules: [
    // { aimvar: Math.PI / 16, aimspeed: 0.01 },
    { aimvar: Math.PI / 2, aimspeed: 0.08 },
    { aimvar: Math.PI * 2, aimspeed: 0.1 },
  ],

  // agent의 바라보는 방향과 진행 방향이 다를 때 이동 속도를 정의합니다. 이
  // 값을 조절하여 몇몇 시나리오에서 agent가 신중하게 움직이고, 위협에 빠르게
  // 대응할 수 있도록 할 수 있습니다.
  movespeed_rules: [
    // 기타: 옆뒤 혹은 뒤로 움직일 때, 10%로 이동합니다.
    { reldir: Math.PI * 2, multiplier: 0.1 },
    // 진행방향 좌/우 90도 안쪽을 바라볼 때 (옆걸음), 30%로 이동합니다.
    { reldir: Math.PI / 2, multiplier: 0.3 },
    // 진행방향 좌/우 30도 안쪽을 바라볼 때, 정상 속도로 이동합니다.
    { reldir: Math.PI / 6, multiplier: 1 },
  ],

};

export const GOALSTATE_TMPL = {
  occupying_team: -1,
  occupy_tick: 0,
  owner: -1,
  count_team0: 0,
  count_team1: 0,
};

export const opts = {
  tps: 30,

  // TODO: mission, goal, eliminate
  SIMOVER_RULE: 'mission',

  SIMTPS: 30,
  SIMTPS_OPTS: [30, 60, 120],

  GRID_SIZE: 10,
  GRID_VIS_PER_TICK: 1,

  // 'full' / 'half' / 'mixed'
  OBSTACLE_TY: 'mixed',
  DEMO_DOOR: true,

  VIS_RANGE: 500,

  GOAL_SIZE: 5,
  GOAL_COUNT: 1,
  GOAL_RADIUS: 30,

  RESCUE_RADIUS: 50,

  GOAL_OCCUPY_DURATION: 5,

  ROUTE_WITH_SAMPLING: false,

  eps: 1,

  // 매 tick마다 아래 값 만큼 aimvar이 decay합니다. 낮을수록 aim이 빠르게 개선됩니다.
  AIMVAR_DECAY_PER_TICK: 0.9,

  // 회전할 때 aim이 얼마나 벌어지는지를 결정합니다. 1인 경우, 회전하는 만큼 aim이 벌어집니다.
  AIMVAR_INCR_ROT_MULTIPLER: 10,

  // 이동할 때 aim이 얼마나 벌어지는지를 결정합니다. 1인 경우, 회전하는 만큼 aim이 벌어집니다.
  AIMVAR_INCR_MOVE_MULTIPLER: 0.1,


  HIT_PROB_STAND: 1.0,
  HIT_PROB_OBSTRUCTED: 0.5,
  HIT_PROB_COVERED: 0.4,
  HIT_PROB_HIDE: 0.01,
  HIT_PROB_CRAWL: 0.5,

  // 피격 판정 방법을 정합니다. 현재 시뮬레이션이 2차원이고, projectile의 선이
  // 목표 agent의 hitbox (시각화에서 원으로 표현되는 범위) 에 닿으면 이후 피격
  // 판정을 진행합니다. 사격 원점으로부터 사격 대상까지의 거리가 멀어질 때
  // 명중률이 실제보다 높게 표현될 수 있는데, 현실 세계에서는 projectile이 수평
  // 방향/수직 방향으로 모두 분산되지만 시뮬레이션에서는 수평 방향으로만
  // 분산되기 때문입니다. 아래 파라미터를 조절해서 projectile이 3차원 공간에서
  // 흩어지는 것을 흉내낼 수 있습니다. 아래 파라미터는 다음과 같이 동작합니다.
  //  - 먼저, 명시된 횟수만큼 projectile 방향을 sampling합니다.
  //  - 이후, sampling 된 projectile 중 사격 대상과 제일 먼 projectile을 선택합니다.
  // 아래 값이 2일 경우, 현재 시뮬레이션이 3차원으로 그대로 확장된 경우를
  // 흉내낼 수 있습니다: 사격 반경이 원호(arc)가 아니라 사각뿔 (pyramid)로
  // 표현되고, 사격 대상이 원이 아니라 정육면제인 경우와 동일하게 동작합니다:
  // 이 경우 가로/세로 방향으로 projectile의 방향을 정하고, 가로/세로가 모두
  // 표적 안에 진입했을 경우 피격되었다고 생각할 수 있는데, 이는 가로/세로 중
  // projectile과 제일 먼 축이 표적 안으로 진입했을 때로 바꾸어 표현할 수
  // 있습니다.
  AIM_ITER_FIRE: 2,
  AIM_ITER_PROJECTILE: 2,

  RETARGET_INTERVAL_SEC: 2,
  TRAIL_DURATION: 0.2,
  TRAIL_HIT_DURATION: 0.5,
  SHOT_IDLE_DURATION: 2,
  HIDE_DURATION: 3,
  COVER_IDLE_DURATION: 5,

  REROUTE_MIN_INTERVAL: 1,
  RISK_DIR_PERSISTENT_DURATION: 1,

  WAYPOINT_DASH_DIST: 100,

  REROUTE_INTERVAL: 1.0,

  COVERPOINT_SAMPLE_DIST: 40,

  RISKDIR_THRES: 0,

  EXPLORED_THRES: 10,

  AIM_SAMPLES_FIRE: 10,

  // 회전 속도를 없애고, 바로 원하는 방향을 바라봅니다.
  EXP_ROTATE_INSTANT: false,

  // 간단한 충돌 메커니즘으로 겹침을 줄입니다.
  EXP_SOFT_COLLISION: false,

  // 실내 데모용 플래그
  // 주기적으로 agent의 시야 정보를 공유합니다. 실내 문 진입 환경 문제 우회용
  EXP_TRANSFER_KNOWLEDGE: true,

  // 문 진입 전, 해당 문제 진입하려고 하는 다른 agent를 기다립니다.
  EXP_INDOOR_DOOR_WAIT: true,

  // 부드러운 시야를 켭니다. 문 진입 등 좁은 공간을 탐색할 때 더 정확하게 경계합니다.
  EXP_VISIBILITY_SOFT: false,

  // etc
  PERF_BUF_SIZE: 30,
};

