
import Heap from 'heap';
import bounds from 'binary-search-bounds';
import { Rng } from './rand.mjs';
import { names } from './names.mjs';
import { opts } from './opts.mjs';
import { v2 } from './v2.mjs';
import { createStructures, placeStructures } from './room.mjs';
import * as m from 'asm';

class Stats {
  constructor() {
    this.aabb_intersect = 0;
    this.obstructed0 = 0;
    this.obstructed0_t = 0;
    this.obstructed = 0;
    this.obstructed0_t = 0;
    this.checkcover = 0;
    this.onReachableGrid = 0;
    this.onReachableGridWasm = 0;
  }
}

let stats = new Stats();

export function stats_populate() {
  const res = stats;
  stats = new Stats();
  return res;
}

export function segment_intersect(a, b, c, d) {
  return a < d && b > c;
}

export function aabb_intersect(a, b, c, d) {
  stats.aabb_intersect += 1;
  return segment_intersect(a.x, b.x, c.x, d.x) && segment_intersect(a.y, b.y, c.y, d.y);
}

const lerp = function (a, b, t) {
  return a + (b - a) * t;
}
const clamp = function (x, min, max) {
  return Math.min(Math.max(x, min), max);
}

function lineToPointDist(p, dir, target) {
  return Math.abs(Math.cos(dir) * (p.x - target.x) + Math.sin(dir) * (p.y - target.y));
}

function dirnorm(d) {
  while (d > Math.PI * 2) {
    d -= Math.PI * 2;
  }
  while (d < 0) {
    d += Math.PI * 2;
  }
  return d;
}
function dirnorm0(d) {
  while (d > Math.PI) {
    d -= Math.PI * 2;
  }
  while (d < -Math.PI) {
    d += Math.PI * 2;
  }
  return d;
}
function dircontains(dir, mindir, maxdir) {
  while (dir < mindir) {
    dir += Math.PI * 2;
  }
  return dir < maxdir;
}

function projectile_dice_chart() {
  const pos = new v2(0, 0);
  const dir = Math.PI / 2;
  const aimvar = Math.PI / 32;
  const samples = 1000;
  const rng = new Rng();
  const size = 3;
  const dice_n = 2;

  let out = [];
  for (let dist = 10; dist < 1000; dist += 10) {
    const targetpos = pos.add(new v2(dist, 0));

    let hitcount = 0;
    for (let i = 0; i < samples; i++) {
      const firedir = projectile_dice(rng, pos, targetpos, dir - aimvar, dir + aimvar, dice_n);
      const dist = lineToPointDist(pos, firedir, targetpos);
      if (dist < size) {
        hitcount += 1;
      }
    }
    out.push(hitcount / samples);
  }
  return out;
}
window.projectile_dice_chart = projectile_dice_chart;

// n번 주사위를 굴려서 제일 안 좋은 방향을 샘플링합니다.
function projectile_dice(rng, pos, targetpos, aimdirmin, aimdirmax, runcount) {
  let dir = (aimdirmin + aimdirmax) / 2;
  let maxdist = 0;
  for (let i = 0; i < runcount; i++) {
    const firedir = rng.randomRange(aimdirmin, aimdirmax);
    const dist = lineToPointDist(pos, firedir, targetpos);
    if (dist > maxdist) {
      dir = firedir;
      maxdist = dist;
    }
  }
  return dir;
}


function createTrail(tick, pos, dir, len, source, target, hit) {
  return {
    tick,
    pos,
    dir,
    len,
    source,
    target,
    hit,
  };
}

export const AREA_CONFIG_TMPL = {
  pos: new v2(60, 300),
  size: new v2(40, 200),
  heading: 0,
};

class TickTimer {
  constructor(start, duration) {
    this.start = start;
    this.duration = duration;
    this.end = start + duration;
  }

  expired(tick) {
    return tick >= this.end;
  }

  expired_exact(tick) {
    return tick === this.end;
  }

  progress(tick) {
    return (tick - this.start) / this.duration;
  }
}

export class Entity {
  constructor(rng, tick, extra) {
    // other props
    this.name = rng.randomChoice(names);

    this.life = extra.life;
    this.firearm_ammo_max = extra.firearm_ammo_max;
    this.ammo = this.firearm_ammo_max;

    // 이동 방향 reference. entityNavigate 등에서 정해짐
    this.dir = Math.PI / 2;
    // 현재 바라보는 방향
    this.aimdir = Math.PI / 2;
    this.aimvar = extra.aimvar_hold;

    this.aimmult = 1;

    this.pos = new v2(0, 0);
    this.gridpos = new v2(0, 0);

    this.state = 'stand';
    this.moving = false;
    this.movespeed = 0;

    const tick0 = tick - 1;

    // 사격 패턴 중 다음 사격 타이머
    this.shootPatternTick = new TickTimer(tick0, 0);
    this.shootPatternIdx = 0;

    this.reloadTick = new TickTimer(tick0, 0);
    this.reloadShootIdleTick = new TickTimer(tick0, 0);

    this.lastRouteTick = new TickTimer(tick0, 0);
    this.lastRiskTick = new TickTimer(tick0, 0);

    this.aimtarget = null;
    this.aimtargetshoots = 0;

    this.waypoint = null;

    this.debugaimdir = this.aimdir;

    if (extra) {
      for (const key in extra) {
        this[key] = extra[key];
      }
    }
    this.rules = [{
      ty: this.default_rule,
    }];
  }

  get waypoint_rule() {
    return this.rules[this.rules.length - 1];
  }

  push_rule(rule) {
    this.rules.push(rule);
  }

  pop_rule() {
    if (this.rules.length === 1) {
      console.error("could not pop last rule");
      return;
    }
    this.rules.pop();
  }
}

function createPolyRect(x, y, w, h) {
  const tl = new v2(x - w / 2, y - h / 2);
  const bl = new v2(x - w / 2, y + h / 2);
  const tr = new v2(x + w / 2, y - h / 2);
  const br = new v2(x + w / 2, y + h / 2);
  return [tl, bl, br, tr];
}

function geomContains(p, polygon) {
  const p2 = new v2(p.x + 100000, p.y + 1);

  let count = 0;
  const l = polygon.length;
  for (let i = 0; i < l; i++) {
    const p3 = polygon[i];
    const p4 = polygon[(i + 1) % l];
    if(v2.intersect(p, p2, p3, p4)) {
      count += 1;
    }
  }
  return count % 2 === 1;
}

function geomSamplePoint(rng, obstacle) {
  const { polygon } = obstacle;
  const minx = Math.min(...polygon.map(p => p.x));
  const maxx = Math.max(...polygon.map(p => p.x));
  const miny = Math.min(...polygon.map(p => p.y));
  const maxy = Math.max(...polygon.map(p => p.y));

  for (let i = 0; i < 100; i++) {
    const p = new v2(
      rng.randomRange(minx, maxx),
      rng.randomRange(miny, maxy)
    );

    if (geomContains(p, polygon)) {
      return p;
    }
  }
  console.error('failed to sample point', obstacle);
  return null;
}

function samplePoints(p0, p1, n) {
  // p0부터 p1까지 n개의 점을 sampling합니다.
  // 1개인 경우 2등분의 가운데, 2개인 경우 3등분의 가운데, ..

  const l = [];
  for (let i = 0; i < n; i++) {
    const t = (i + 1) / (n + 1);
    l.push(v2.lerp(p0, p1, t));
  }
  return l
}

export function createObstacle(center, extent, heading, ty, extent_margin) {
  const { x, y } = center;
  const w = extent.x;
  const h = extent.y;
  if (!extent_margin) {
    extent_margin = new v2(10, 10);
  }
  const mw = extent_margin.x;
  const mh = extent_margin.y;

  const tl = new v2(x - w - mw, y - h - mh);
  const bl = new v2(x - w - mw, y + h + mh);
  const tr = new v2(x + w + mw, y - h - mh);
  const br = new v2(x + w + mw, y + h + mh);

  const nx = Math.max(1, Math.floor(2 * h / opts.COVERPOINT_SAMPLE_DIST));
  const ny = Math.max(1, Math.floor(2 * w / opts.COVERPOINT_SAMPLE_DIST));

  let coverpoints = [];
  coverpoints = coverpoints.concat(samplePoints(tl, bl, ny));
  coverpoints = coverpoints.concat(samplePoints(bl, br, nx));
  coverpoints = coverpoints.concat(samplePoints(br, tr, ny));
  coverpoints = coverpoints.concat(samplePoints(tr, tl, nx));

  const routepoints = createPolyRect(x, y, (w + mw) * 2, (h + mh) * 2);

  const o = new v2(x, y);
  const pos = new v2(x, y);
  const polygon = createPolyRect(x, y, 2 * w, 2 * h).map((p) => p.rot(o, heading));

  return {
    ty,
    pos,
    polygon,
    polygon_aabb: v2.polygonAABB(polygon),

    coverpoints: coverpoints.map((p) => p.rot(o, heading)),
    routepoints: routepoints.map((p) => p.rot(o, heading)),

    extent,
    heading,
  };
}

function routeEdges(routes, src) {
  const { edges } = routes;

  const lb = bounds.gt(edges, { from: src, to: new v2(-10000, -10000) }, route_cmp);
  const ub = bounds.gt(edges, { from: src, to: new v2(10000, 10000) }, route_cmp);
  return edges.slice(Math.abs(lb), Math.abs(ub));
}

function pathfindHeap(routes, p0, costFunc) {
  // dijkstra
  const heap = new Heap((a, b) => {
    return a.cost - b.cost;
  });

  const entry0 = { cost: 0, len: 0, pos: p0, edge: null, p: null, idx: -1 };
  const obstacles = routes.obstacles.filter((o) => {
    if (o.ty === 'door') {
      return !o.doorstate.open;
    }
    return true;
  });

  // find initial nodes: step 0, exact point
  const p0_idx = bounds.eq(routes.nodes, { pos: p0 }, (a, b) => a.pos.cmp(b.pos));
  if (p0_idx !== -1) {
    entry0.idx = p0_idx;
    heap.push(entry0);
    return heap;
  }

  // find initial edges
  for (let i = 0; i < routes.nodes.length; i++) {
    const node = routes.nodes[i];

    // TODO: 문 옆으로 진입하도록 cheat
    if (node.obstacle.ty === 'door') {
      if (!node.obstacle.doorstate.open && node.is_coverpoint) {
        continue;
      }
    }
    const { pos } = node;
    if (obstructed(p0, pos, obstacles)) {
      continue;
    }

    const len = p0.dist(pos);
    const edge = { from: p0, to: pos, len, obstacle: null };
    const cost = costFunc(edge);
    const entry = { cost, len, pos: pos, edge: null, p: entry0, idx: i };

    heap.push(entry);
  }

  return heap;
}

export function hostilityNetwork(routes, p0) {
  const mst = minimumSpanningTree(routes, p0);
  const closures = [];

  function find_elem(to_idx) {
    for (const elem of mst) {
      if (elem.edge.to_idx === to_idx) {
        return elem;
      }
    }
    return null;
  }

  function get_parents(elem) {
    const l = [];
    let cursor = elem;
    while (cursor.p && cursor.edge) {
      l.push(cursor.edge.from_idx);
      cursor = cursor.p;
    }
    return l;
  }

  for (const elem of mst) {
    if (!elem.leaf) {
      continue;
    }

    const visited = new Set(get_parents(elem));
    const edges = routeEdges(routes, elem.pos);
    edges.sort((a, b) => a.len - b.len);

    let max_connect = 0;
    for (const edge of edges) {
      if (edge.from_idx !== elem.edge.to_idx) {
        continue;
      }
      if (visited.has(edge.to_idx)) {
        continue;
      }
      closures.push(edge);
      max_connect += 1;
      if (max_connect === 1) {
        break;
      }

      let cursor = find_elem(edge.to_idx);
      if (!cursor) {
        continue;
      }
      // find children
      const q = [cursor];
      while (q.length > 0) {
        const cur = q.pop();
        const node = cur.edge.to_idx;
        if (visited.has(node)) {
          continue;
        }
        visited.add(node);
        for (const elem of mst) {
          if (node === elem.edge.from_idx) {
            q.push(elem);
          }
        }
      }

      while (cursor && cursor.p && cursor.edge) {
        let idx = cursor.edge.from_idx;
        if (visited.has(idx)) {
          break;
        }
        visited.add(idx);
        cursor = cursor.p;
      }
      // to_idx의 parent를 찾아야 합니다.
    }
  }

  // return mst.map((elem) => elem.edge);
  return closures.concat(mst.map((elem) => elem.edge));
}

export function minimumSpanningTree(routes, p0) {
  // dijkstra
  const heap = pathfindHeap(routes, p0, (edge) => edge.len);
  const visited = new Uint8Array(routes.nodes.length);
  const results = [];
  let visitedCount = 0;

  while (!heap.empty() && visitedCount < routes.nodes.length) {
    const elem = heap.pop();
    if (visited[elem.idx]) {
      continue;
    }
    visited[elem.idx] = 1;
    visitedCount += 1;

    if (elem.edge) {
      if (elem.p) {
        elem.p.leaf = false;
      }
      results.push(elem);
    }

    const edges = routeEdges(routes, elem.pos);
    for (const edge of edges) {
      if (visited[edge.to_idx]) {
        continue;
      }
      const entry = {
        cost: edge.len,
        len: edge.len,
        pos: edge.to,
        edge,
        p: elem,
        idx: edge.to_idx,
        leaf: true,
      };
      heap.push(entry);
    }
  }
  return results;
}

export function routePathfindAll(routes, p0) {
  // dijkstra
  const heap = pathfindHeap(routes, p0, (edge) => edge.len);
  const visited = new Float32Array(routes.nodes.length);

  while (!heap.empty()) {
    const elem = heap.pop();
    if (visited[elem.idx]) {
      continue;
    }
    visited[elem.idx] = elem.cost;

    const edges = routeEdges(routes, elem.pos);
    for (const edge of edges) {
      if (visited[edge.to_idx]) {
        continue;
      }
      const entry = {
        cost: elem.cost + edge.len,
        len: edge.len,
        pos: edge.to,
        edge,
        p: elem,
        idx: edge.to_idx,
      };
      heap.push(entry);
    }
  }
  return visited;
}

// both p1 should be in route
export function routePathfind(routes, p0, p1, costFunc, skips) {
  if (!costFunc) {
    costFunc = (edge) => {
      return edge.len;
    };
  }
  if (!skips) {
    skips = [];
  }

  const p1_idx = bounds.eq(routes.nodes, { pos: p1 }, (a, b) => a.pos.cmp(b.pos));
  if (p1_idx === -1) {
    return null;
  }

  // dijkstra
  const heap = pathfindHeap(routes, p0, costFunc);
  const visited = new Uint8Array(routes.nodes.length);

  while (!heap.empty()) {
    const elem = heap.pop();
    if (visited[elem.idx]) {
      continue;
    }
    if (skips.includes(elem.idx)) {
      continue;
    }
    visited[elem.idx] = 1;

    if (elem.idx === p1_idx) {
      const decoded = [];
      let cur = elem;
      while (cur !== null) {
        decoded.push(cur);
        cur = cur.p;
      }
      decoded.reverse();
      return decoded;
    }

    const edges = routeEdges(routes, elem.pos);
    for (const edge of edges) {
      if (visited[edge.to_idx]) {
        continue;
      }
      const entry = {
        cost: elem.cost + costFunc(edge),
        len: edge.len,
        pos: edge.to,
        edge,
        p: elem,
        idx: edge.to_idx,
      };
      heap.push(entry);
    }
  }
  return [];
}

function route_cmp(a, b) {
  const c0 = a.from.cmp(b.from);
  if (c0 !== 0) { return c0; }
  return a.to.cmp(b.to);
}

function obstructed0_t(a, b, obstacle) {
  stats.obstructed0_t += 1;

  let t_min = 1;
  if (!aabb_intersect(a.min(b), a.max(b), obstacle.polygon_aabb.tl, obstacle.polygon_aabb.br)) {
    return t_min;
  }

  const points = obstacle.polygon;
  const len = points.length;
  for (let i = 0; i < len; i++) {
    const p0 = points[i];
    const p1 = points[(i + 1) % len];
    const t = v2.intersect_t(a, b, p0, p1);
    const u = v2.intersect_t(p0, p1, a, b);
    if (t > 0 && u > 0 && u < 1 && t < t_min) {
      t_min = t;
    }
  }
  return t_min;
}

function obstructed0(a, b, obstacle) {
  stats.obstructed0 += 1;

  if (!aabb_intersect(a.min(b), a.max(b), obstacle.polygon_aabb.tl, obstacle.polygon_aabb.br)) {
    return false;
  }

  const points = obstacle.polygon;
  const len = points.length;
  for (let i = 0; i < len; i++) {
    const p0 = points[i];
    const p1 = points[(i + 1) % len];
    if (v2.intersect(a, b, p0, p1)) {
      return true;
    }
  }
  return false;
}

export function obstructed_t(a, b, obstacles) {
  stats.obstructed_t += 1;
  let t = 1;
  for (const obstacle of obstacles) {
    t = Math.min(t, obstructed0_t(a, b, obstacle));
  }
  return t;
}

export function obstructed(a, b, obstacles) {
  stats.obstructed += 1;

  for (const obstacle of obstacles) {
    if (obstructed0(a, b, obstacle)) {
      return obstacle;
    }
  }
  return null;
}

export function obstructed_all(a, b, obstacles, out) {
  for (const obstacle of obstacles) {
    if (obstructed0(a, b, obstacle)) {
      out.push(obstacle);
    }
  }
}


function buildRoute(obstacles) {
  const nodes = [];
  const edges = [];

  function isValidPoint(p) {
    for (const obstacle of obstacles) {
      if (geomContains(p, obstacle.polygon)) {
        return false;
      }
    }
    return true;
  }

  // node pruning
  for (const o of obstacles) {
    o.routepoints = o.routepoints.filter((p) => isValidPoint(p));
    o.coverpoints = o.coverpoints.filter((p) => isValidPoint(p));
  }

  const nodemap = new Set();

  function addNode(pos, obstacle, is_coverpoint) {
    // do not reduce door nodes
    if (obstacle.ty !== 'door') {
      const hash = pos.hash2(10);
      if (nodemap.has(hash)) {
        return;
      }
      nodemap.add(hash);
    }
    nodes.push({ pos, obstacle, is_coverpoint });
  }

  for (let i = 0; i < obstacles.length; i++) {
    const obstacle = obstacles[i];
    obstacle.coverpoints.map((p) => addNode(p, obstacle, true));
    obstacle.routepoints.map((p) => addNode(p, obstacle, false));
  }
  nodes.sort((a, b) => a.pos.cmp(b.pos));

  const blockers = obstacles.filter((o) => o.ty !== 'door');
  const doors = obstacles.filter((o) => o.ty === 'door');

  for (let i = 0; i < nodes.length; i++) {
    const n0 = nodes[i];
    for (let j = 0; j < nodes.length; j++) {
      if (i === j) {
        continue;
      }

      const n1 = nodes[j];

      const n1_door_route = n1.obstacle.ty === 'door' && n1.is_coverpoint;
      if (n1_door_route && n0.obstacle !== n1.obstacle) {
        continue;
      }

      const p0 = n0.pos;
      const p1 = n1.pos;

      if (obstructed(p0, p1, blockers)) {
        continue;
      }

      const door = obstructed(p0, p1, doors);
      if (door !== null) {
        if (n0.obstacle !== door || n1.obstacle !== door) {
          continue;
        }
        // connect routepoints only
        if (!n0.is_coverpoint || !n1.is_coverpoint) {
          continue;
        }
      }

      const len = p0.dist(p1);
      if (len === 0) {
        // TODO
        continue;
      }

      const obstacle = nodes[j].is_coverpoint ? nodes[j].obstacle : null;
      edges.push({ from: p0, to: p1, from_idx: i, to_idx: j, len, obstacle, door });
    }
  }

  edges.sort(route_cmp);

  const r = {
    nodes,
    edges,
    obstacles,
    covers: [],
  };

  for (let i = 0; i < nodes.length; i++) {
    for (let j = i + 1; j < nodes.length; j++) {
      let p0 = nodes[i].pos;
      let p1 = nodes[j].pos;
      r.covers.push({ from: p0, to: p1, cover: checkcover(p0, p1, r) });
      r.covers.push({ from: p1, to: p0, cover: checkcover(p1, p0, r) });
    }
  }

  r.covers.sort(route_cmp);
  return r;
}

// shoot p1, from p0, what's cover state?
//  - blocked
//  - covered: p1 is in cover: p1 is coverpoint of obstacle and line-of-sight is covered.
//  - obstructed: p1 is not in cover, obstacle in between p0 and p1
//  - uncovered: no obstacle in middle
export function checkcover(p0, p1, routes) {
  stats.checkcover += 1;

  // blocked
  for (const o of routes.obstacles) {
    if (o.ty === 'full' && obstructed0(p0, p1, o)) {
      return 3;
    }
  }

  // covered
  const node_p1_idx = bounds.eq(routes.nodes, { pos: p1 }, (a, b) => a.pos.cmp(b.pos));
  const ignores = [];
  if (node_p1_idx !== -1) {
    const node = routes.nodes[node_p1_idx];
    ignores.push(node.obstacle);
    if (node.obstacle.ty === 'half' && obstructed0(p0, p1, node.obstacle)) {
      return 2;
    }
  }

  const node_p0_idx = bounds.eq(routes.nodes, { pos: p0 }, (a, b) => a.pos.cmp(b.pos));
  if (node_p0_idx !== -1) {
    ignores.push(routes.nodes[node_p0_idx].obstacle);
  }

  for (const o of routes.obstacles) {
    if (o.ty === 'half' && !ignores.includes(o) && obstructed0(p0, p1, o)) {
      return 1;
    }
  }

  return 0;
}

function onReachableGridDist(world, obstacles, entity, pos) {
  obstacles = obstacles.filter((o) => o.ty === 'full');
  const { grid_count } = world;

  const costs = new Int16Array(grid_count);
  const parents = new Int32Array(grid_count);
  costs.fill(-1);
  parents.fill(-1);

  const heap = new Heap((a, b) => {
    return a.cost - b.cost;
  });

  function pushItem(pos, cost, p) {
    const idx = world.idx(pos);
    if (!world.valid(pos)) {
      return;
    }

    // already visited
    if (costs[idx] >= 0) {
      return;
    }

    const world_pos = world.gridToWorld(pos);
    if (p !== null) {
      parents[idx] = p.idx;

      if (obstructed(p.world_pos, world_pos, obstacles)) {
        return;
      }
    }

    const item = { world_pos, pos, idx, p, cost, children: [] };
    heap.push(item);
    return item;
  }

  function step(item) {
    const cost = item.cost;
    const { x, y } = item.pos;

    if (costs[item.idx] >= 0) {
      return;
    }
    costs[item.idx] = item.cost;

    if (item.p) {
      item.p.children.push(item);
    }

    // horizontal
    pushItem(new v2(x - 1, y), cost + 1, item);
    pushItem(new v2(x + 1, y), cost + 1, item);
    pushItem(new v2(x, y - 1), cost + 1, item);
    pushItem(new v2(x, y + 1), cost + 1, item);
    // diagonal
    pushItem(new v2(x - 1, y - 1), cost + Math.SQRT2, item);
    pushItem(new v2(x - 1, y + 1), cost + Math.SQRT2, item);
    pushItem(new v2(x + 1, y - 1), cost + Math.SQRT2, item);
    pushItem(new v2(x + 1, y + 1), cost + Math.SQRT2, item);
  }

  const risks = new Float32Array(grid_count);
  function fillrisk(item) {
    const { idx } = item;
    let risk = entity.grid[idx] === 0 ? 1 : 0;
    for (const child of item.children) {
      risk += fillrisk(child);
    }
    risks[idx] = risk;
    return risk;
  }

  const root = pushItem(world.worldToGrid(pos), 0, null);
  while (!heap.empty()) {
    const item = heap.pop();
    step(item);
  }
  fillrisk(root);

  return { costs, parents, risks };
}

export function onReachableGridWasm(world, triangulated, pos, range, fn) {
  const { grid_count } = world;
  stats.onReachableGridWasm += 1;

  const buf = new Float32Array(grid_count);
  triangulated.visibility_limit_fill(
    pos.x, pos.y, range,
    [-world.width/2, -world.height/2, world.width, world.height, opts.GRID_SIZE],
    buf
  );

  for (let idx = 0; idx < grid_count; idx++) {
    if (buf[idx] < 0.5) {
      continue;
    }
    const world_pos = world.gridIdxToWorld(idx);
    fn(idx, world_pos);
  }
}

// reference implementation
export function onReachableGrid(world, obstacles, pos, range, fn) {
  const { grid_count, grid_count_x } = world;
  stats.onReachableGrid += 1;

  obstacles = obstacles.filter((o) => o.ty === 'full' || (o.ty === 'door' && !o.doorstate.open));


  const visited = new Uint8Array(grid_count);

  function step(x, y, idx) {
    if (!world.valid(new v2(x, y))) {
      return;
    }
    if (visited[idx]) {
      return;
    }
    visited[idx] = 1;

    let world_pos = world.gridToWorld(new v2(x, y));
    if (world_pos.dist(pos) > range) {
      return;
    }
    if (obstructed(world_pos, pos, obstacles)) {
      return;
    }
    if (!fn(idx, world_pos)) {
      return;
    }

    step(x - 1, y, idx - 1);
    step(x + 1, y, idx + 1);
    step(x, y - 1, idx - grid_count_x);
    step(x, y + 1, idx + grid_count_x);
  }

  const p = world.worldToGrid(pos);
  step(p.x, p.y, p.y * grid_count_x + p.x);
}

function opponentTeam(team) {
  if (team === 0) {
    return [1];
  } else {
    return [0];
  }
}

function buildVisibility(rng, world, obstacles) {
  // visibility
  let sx_o = m.Simplical.new();
  let params = [];
  for (const obstacle of obstacles) {
    if (obstacle.ty === 'half') {
      continue;
    }
    if (obstacle.ty === 'door' && obstacle.doorstate.open) {
      continue;
    }

    const x = obstacle.pos.x + rng.randomRange(-1, 1);
    const y = obstacle.pos.y + rng.randomRange(-1, 1);
    const sx0 = m.Simplical.from_rect(x, y, obstacle.extent.x, obstacle.extent.y, -obstacle.heading, 1);
    params.push([obstacle.pos.x, obstacle.pos.y,
      obstacle.extent.x, obstacle.extent.y, -obstacle.heading]);

    const sx1 = sx_o.union(sx0);
    sx0.free();
    sx_o.free();

    sx_o = sx1;
  }

  const sx_world = m.Simplical.from_rect(0, 0, world.width/2, world.height/2, 0, 10);
  const sx = sx_world.subtract(sx_o);
  sx_world.free();
  sx_o.free();

  const triangulated = m.Triangulated.from(sx);
  return { sx, triangulated };
}

export class Simulator {
  constructor(props) {
    const { world, obstacle_spec } = props;
    let { seed } = props;
    let obstacles = [];
    const rng = new Rng(seed);

    const areas = props.spawnareas.map((area, i) => {
      const obs = createObstacle(area.pos, area.extent, area.heading, null, null);
      obs.name = `spawnarea #${i}`;
      obs.areastate = {
        area,
        vacate: area.vacate ?? false,
        triggers: area.triggers ? area.triggers.slice() : [],
      };
      return obs;
    });

    function overlapped(points0, points1) {
      let aabb0 = v2.polygonAABB(points0);
      let aabb1 = v2.polygonAABB(points1);

      if (!aabb_intersect(aabb0.tl, aabb0.br, aabb1.tl, aabb1.br)) {
        return;
      }

      for (let i = 0; i < points0.length; i++) {
        let p0 = points0[i];
        let p1 = points0[(i + 1) % points0.length];

        for (let j = 0; j < points1.length; j++) {
          let p2 = points1[j];
          let p3 = points1[(j + 1) % points1.length];

          if (v2.intersect(p0, p1, p2, p3)) {
            return true;
          }
        }
      }

      const not_contains0 = points0.find((p) => !geomContains(p, points1));
      const not_contains1 = points1.find((p) => !geomContains(p, points0));

      if (!not_contains0 || !not_contains1) {
        return true;
      }

      return false;
    }

    for (const area of areas) {
      if (area.areastate.area.structureopts === undefined) {
        continue;
      }
      const { count } = area.areastate.area.structureopts;

      // TODO: heading
      const center = area.areastate.area.pos;
      const extent = area.areastate.area.extent;

      const placements = placeStructures(rng, { center, extent, count });
      area.areastate.placements = placements.map((p) => createObstacle(p.center, p.extent, p.dir, null, null));

      const structures = createStructures(rng, placements);
      area.areastate.structures = structures;

      for (const s of structures) {
        const { structure, dir, offset } = s;
        const center_s = structure.pos.add(offset);

        for (const wall of structure.walls) {
          const { start, end } = wall;

          const center = v2.lerp(start, end, 0.5).add(offset);
          const extent = end.sub(start).mul(0.5);

          obstacles.push(createObstacle(center.rot(center_s, dir), extent, dir, 'full', null));
        }

        for (const room of structure.squarifyTreemap) {
          const tl = new v2(room.x0, room.y0);
          const br = new v2(room.x1, room.y1);
          const center = v2.lerp(tl, br, 0.5).add(offset);
          const extent = br.sub(tl).mul(0.5);

          room.shape = createObstacle(center.rot(center_s, dir), extent, dir, 'room', null);
        }

        if (opts.DEMO_DOOR) {
          for (const wall of structure.doors) {
            const { start, end } = wall;
            const center = v2.lerp(start, end, 0.5).add(offset);
            const extent = end.sub(start).mul(0.5);

            let extent_margin = new v2(1, 4);
            if (extent.y > extent.x) {
              extent_margin = new v2(4, 1);
            }

            const o = createObstacle(center.rot(center_s, dir), extent, dir, 'door', extent_margin);
            o.doorstate = { open: false };

            obstacles.push(o);
          }
        }
      }
    }

    const target_count = obstacles.length + obstacle_spec.count;
    const obstacle_area = createObstacle(obstacle_spec.center, obstacle_spec.extent, 0, '', null);
    while (obstacles.length < target_count) {
      const pos = geomSamplePoint(rng, obstacle_area);
      const width = rng.randomRange(4, 8);
      const height = rng.randomRange(15, 50);
      const rot = rng.randomAngle();
      let ty = opts.OBSTACLE_TY;
      if (opts.OBSTACLE_TY === 'mixed') {
        ty = rng.randomChoice(['full', 'half']);
      }

      const obs = createObstacle(pos, new v2(width, height), rot, ty, null);
      if (areas.find((o) => o.areastate.vacate && overlapped(o.routepoints, obs.routepoints))) {
        continue;
      }
      if (obstacles.find((o) => overlapped(o.routepoints, obs.routepoints))) {
        continue;
      }

      obstacles.push(obs);
    }

    const { GOAL_SIZE, GOAL_COUNT } = opts;
    const goals = props.goals.map((g) => {
      const area = areas[g.area];
      const ty = 'full'; // 'full';//randomChoice(['full', 'half']);

      let goal = null;
      for (let i = 0; i < 100; i++) {
        const pos = geomSamplePoint(rng, area);
        const rot = rng.randomAngle();

        const obs = createObstacle(pos, new v2(GOAL_SIZE, GOAL_SIZE), rot, ty, null);
        if (!obstacles.find((o) => overlapped(o.routepoints, obs.routepoints))) {
          goal = obs;
          break;
        }
      }
      goal.name = g.name;
      goal.goalstate = { ...opts.GOALSTATE_TMPL };

      return goal;
    });

    // spawn goals
    while (goals.length < GOAL_COUNT) {
      const x = rng.randomRange(-obstacle_spec.extent.x, obstacle_spec.extent.x);
      const y = world.height * (goals.length + 1) / GOAL_COUNT - world.height / 2 - 200;
      const rot = rng.randomAngle();
      const ty = 'full'; // 'full';//randomChoice(['full', 'half']);

      const obs = createObstacle(new v2(x, y), new v2(GOAL_SIZE, GOAL_SIZE), rot, ty, null);
      if (obstacles.find((o) => overlapped(o.routepoints, obs.routepoints))) {
        continue;
      }

      obs.name = `goal #${goals.length}`;
      obs.goalstate = { ...opts.GOALSTATE_TMPL };

      goals.push(obs);
    }

    for (const goal of goals) {
      obstacles.push(goal);
    }

    const routes = buildRoute(obstacles);

    // spawn entities
    const tick = 0;
    const entities = [];
    const placement_rooms = [];
    for (const config of props.entities) {
      const entity = new Entity(rng, tick, config);
      let sample = 0;
      let sample_idx = -1;

      if (!config.pos) {
        const area = areas[config.spawnarea];
        const { structures } = area.areastate;

        let pos = null;
        while (!pos) {
          if (structures) {
            // sample random placement
            sample = rng.randomInt(0, structures.length - 1);
            const { structure } = structures[sample];
            const maps = structure.squarifyTreemap;

            let room = (0|placement_rooms[sample]);

            const geom = maps[room].shape;
            pos = geomSamplePoint(rng, geom);
            sample_idx = (room + 1) % maps.length;
          } else {
            pos = geomSamplePoint(rng, area);
          }

          // TODO: goal에서 spawn 안 되도록
          const pos0 = pos;
          if (obstacles.find((o) => geomContains(pos0, o.routepoints))
            || goals.find((g) => g.pos.dist(pos0) < GOAL_SIZE)) {
            pos = null;
            continue;
          }
        }

        entity.pos = pos;
        entity.gridpos = world.worldToGrid(pos);

        // heading center
        entity.dir = rng.randomAngle();
        entity.aimdir = entity.dir;

        if (sample_idx !== -1) {
          placement_rooms[sample] = sample_idx;
        }
      }

      // grid
      const grid = new Float32Array(world.grid_count);
      grid.fill(0);
      entity.grid = grid;

      entities.push(entity);
    }

      // 같은 팀끼리 grid를 공유합니다.
    for (const entity of entities) {
      let share = false;
      if (entity.team === 0 && world.exp_team_shared_grid) {
        share = true;
      }
      if (entity.team === 1 && world.exp_team_shared_grid_team1) {
        share = true;
      }
      if (!share) {
        continue;
      }
      entity.grid = entities.find((e) => e.team === entity.team).grid;
    }

    // mission rule
    /*
    let mission_rules = goals.map((goal) => ({ ty: 'capture-goal', goal }));
    mission_rules = mission_rules.concat((areas.map((area) => ({ ty: 'fire', area }))));
    this.mission_rules = [
      { ty: 'fire' },
      ..._.shuffle(mission_rules)
    ];
    */
    this.mission_rules = props.mission_rules.map((r) => { return { ...r, area: areas[r.area] }; });

    this.world = world;
    this.seed = seed;
    this.rng = rng;
    this.tick = tick;
    this.tps = opts.tps;
    this.entities = entities;

    this.trails = [];
    this.spawnareas = areas;
    this.obstacles = obstacles;
    this.goals = goals;
    this.routes = routes;

    this.journal = [];
    this.perfbuf = [];

    const { sx, triangulated } = buildVisibility(rng, world, obstacles);
    this.sx = sx;
    this.triangulated = triangulated;

    for (const entity of this.entities) {
      if (!entity.use_visibility) {
        continue;
      }

      this.entityUpdateGridOmniDir(entity);
    }

    if (world.exp_prepopulate_grid) {
      const old = opts.GRID_VIS_PER_TICK;
      opts.GRID_VIS_PER_TICK = 1;

      for (const entity of this.entities) {
        if (!entity.use_visibility) {
          continue;
        }

        if (entity.team !== 0) {
          continue;
        }

        for (let i = 0; i < 4; i++) {
          let x = world.width * i / 3 - world.width / 2;
          let y = world.height * i / 3 - world.height / 2;

          this.entityUpdateGridOmniDir(entity, new v2(-world.width / 2 + 1, y));
          this.entityUpdateGridOmniDir(entity, new v2(world.width / 2 - 1, y));

          this.entityUpdateGridOmniDir(entity, new v2(x, -world.height / 2 + 1));
          this.entityUpdateGridOmniDir(entity, new v2(x, world.height / 2 - 1));
        }
      }

      opts.GRID_VIS_PER_TICK = old;
    }
  }

  free() {
    if (this.sx) {
      this.sx.free();
      this.sx = null;
    }
    if (this.triangulated) {
      this.triangulated.free();
      this.triangulated = null
    }
  }

  rebuildVisibility() {
    const { rng, world, obstacles } = this;
    const { sx, triangulated } = buildVisibility(rng, world, obstacles);
    this.sx = sx;
    this.triangulated = triangulated;
  }

  debugchoosepoint(itemFunc, _cmpFunc, includes_routepints) {
    const { routes } = this;
    const items = [];

    for (let i = 0; i < routes.nodes.length; i++) {
      const node = routes.nodes[i];
      if (!includes_routepints && !node.is_coverpoint) {
        continue;
      }

      const query = itemFunc(node.pos, node.obstacle, i);
      items.push({ node, query });
    }

    return items;
  }

  choosepoint(itemFunc, cmpFunc, includes_routepints) {
    const { routes } = this;
    let item = null;

    for (let i = 0; i < routes.nodes.length; i++) {
      const node = routes.nodes[i];
      if (!includes_routepints && !node.is_coverpoint) {
        continue;
      }

      const item2 = itemFunc(node.pos, node.obstacle, i);
      if (!item2) {
        continue;
      }

      // return true if second argument is better than the first one
      if (cmpFunc(item, item2)) {
        item = item2;
      }
    }

    return item;
  }


  choosefirepoint(e1, f) {
    const { routes } = this;

    return (f ?? this.choosepoint.bind(this))((pos, obstacle) => {
      // TODO: dist: dijkstra
      let dist = pos.dist(e1.pos);
      if (dist < opts.eps) {
        return null;
      }

      const cover = checkcover(pos, e1.pos, routes);
      if (cover === 3) {
        // blocked, cannot fire
        return null;
      }

      return {
        cover,
        dist,
        pos,
        obstacle,
      };
    }, (a, b) => {
      // return true if choose b over a
      if (a === null) {
        return true;
      }
      if (b.cover < a.cover || (b.cover === a.cover && b.dist < a.dist)) {
        return true;
      }
      return false;
    }, true);
  }

  // shooter on e1, target on e0, find coverpoint of *e0*
  // find coverpoint, accounting all enemies, able to fire to aimtarget
  choosecoverpoint(entity, max_dist, f) {
    const { entities, routes } = this;

    const ot = opponentTeam(entity.team);
    const allies = entities.filter((e) => e !== entity && !ot.includes(e.team) && e.state !== 'dead');
    const enemies = entities.filter((e) => e !== entity && ot.includes(e.team) && e.state !== 'dead');

    const allies_points = allies.map((e) => e.waypoint?.cp?.pos).filter((e) => e);

    const e1 = entity.aimtarget;

    return (f ?? this.choosepoint.bind(this))((pos, obstacle) => {
      // 같은 편과 같은 자리에서 지키지 않습니다.
      if (allies_points.find((p) => pos.eq(p))) {
        return null;
      }

      if (e1.waypoint && e1.waypoint.obstacle === obstacle) {
        return null;
      }

      let dist0 = pos.dist(e1.pos);
      if (dist0 > max_dist) {
        return null;
      }

      let dist = pos.dist(entity.pos);
      // 상대방을 사격할 수 있는 위치 중 하나를 고릅니다.
      const cover_offence = checkcover(pos, e1.pos, routes);
      if (cover_offence === 3) {
        // skip blocked
        return null;
      }

      let cover = 3;
      for (const enemy of enemies) {
        // 상대방의 firearm_range 밖에 있는 경우 무시
        /*
        const dist = enemy.pos.dist(pos);
        if (dist > enemy.firearm_range) {
          continue;
        }
        */

        let cover_enemy = checkcover(enemy.pos, pos, routes);
        cover = Math.min(cover, cover_enemy);
      }

      return {
        cover,
        dist,
        pos,
        obstacle,
      };
    }, (a, b) => {
      // return true if choose b over a
      if (a === null) {
        return true;
      }
      // 높을수록 좋음
      if (b.cover > a.cover) { return true; }
      if (b.cover < a.cover) { return false; }

      // 현재 위치에서 가까울수록 좋음
      if (b.dist < a.dist) {
        return true;
      }
      return false;
    });
  }

  choosehidepoint(entity, f) {
    const { entities, routes } = this;

    const ot = opponentTeam(entity.team);
    const allies = entities.filter((e) => e !== entity && !ot.includes(e.team) && e.state !== 'dead');
    const enemies = entities.filter((e) => e !== entity && ot.includes(e.team) && e.state !== 'dead');

    const allies_points = allies.map((e) => e.waypoint?.cp?.pos).filter((e) => e);

    return (f ?? this.choosepoint.bind(this))((pos, obstacle) => {
      // 같은 편과 같은 자리에서 지키지 않습니다.
      if (allies_points.find((p) => pos.eq(p))) {
        return null;
      }

      let dist = pos.dist(entity.pos);
      let cover = 3;
      for (const enemy of enemies) {
        // 상대방의 firearm_range 밖에 있는 경우 무시
        const dist = enemy.pos.dist(pos);
        if (dist > enemy.firearm_range) {
          continue;
        }

        let cover_enemy = checkcover(enemy.pos, pos, routes);
        cover = Math.min(cover, cover_enemy);
      }

      return {
        cover,
        dist,
        pos,
        obstacle,
      };
    }, (a, b) => {
      // return true if choose b over a
      if (a === null) {
        return true;
      }
      // 높을수록 좋음
      if (b.cover > a.cover) { return true; }
      if (b.cover < a.cover) { return false; }

      // 현재 위치에서 가까울수록 좋음
      if (b.dist < a.dist) {
        return true;
      }
      return false;
    });
  }

  entityUnexploredGrids(entity, pos) {
    return onReachableGridDist(this.obstacles, entity, pos);
  }

  chooserescuepoint(entity, target, f) {
    let obstacles = this.obstacles.filter((o) => o.ty === 'full');
    const dists = routePathfindAll(this.routes, entity.pos);

    const p = (f ?? this.choosepoint.bind(this))((pos, obstacle, i) => {
      if (target.pos.dist(pos) >= opts.RESCUE_RADIUS) {
        return null;
      }
      if (obstructed(target.pos, pos, obstacles)) {
        return null;
      }

      const p = {
        score: dists[i],
        pos,
        obstacle,
      };
      return p;
    }, (a, b) => {
      // return true if choose b over a
      if (a === null) {
        return true;
      }
      if (b.score > a.score) {
        return true;
      }
      return false;
    });
    return p;
  }

  chooseexplorepoint(entity, f) {
    const { world } = this;
    const dists = routePathfindAll(this.routes, entity.pos);

    let area = entity.waypoint_rule.area;
    let maxcover = null;

    const p = (f ?? this.choosepoint.bind(this))((pos, obstacle, i) => {
      // area가 지정된 경우 해당 area만 탐색
      if (area && !geomContains(pos, area.polygon)) {
        return null;
      }

      const dist = dists[i];
      let cover = 0;
      onReachableGridWasm(this.world, this.triangulated, pos, opts.VIS_RANGE, (idx, world_pos) => {
        // area가 있는 경우, 해당 area의 탐색 여부만 확인합니다.
        if (area && !geomContains(world_pos, area.polygon)) {
          return false;
        }
        cover += 1 - entity.grid[idx];
        return true;
      });

      const normcover = cover / (world.width * world.height / Math.pow(opts.GRID_SIZE, 2));
      const normdist = dist / Math.sqrt(world.width * world.height);

      const p = {
        cover,
        dist,
        normcover,
        normdist,
        score: normcover / normdist,
        pos,
        obstacle,
      };
      if (!maxcover || maxcover.cover < p.cover) {
        maxcover = p;
      }
      return p;
    }, (a, b) => {
      // return true if choose b over a
      if (a === null) {
        return true;
      }
      if (b.score > a.score) {
        return true;
      }
      return false;
    });

    // HACK
    p.maxcover = maxcover;
    return p;
  }

  choosecapturepoint(entity, goal, f) {
    const { entities, goals } = this;
    const allies = entities.filter((e) => e !== entity && e.team === entity.team && e.state !== 'dead');
    const allies_points = allies.map((e) => e.waypoint?.cp?.pos).filter((e) => e);

    return (f ?? this.choosepoint.bind(this))((pos, obstacle) => {
      // 같은 편과 같은 자리에서 지키지 않습니다.
      if (allies_points.find((p) => pos.eq(p))) {
        return null;
      }
      if (!goals.includes(obstacle)) {
        return null;
      }
      if (obstacle.goalstate.owner >= 0) {
        return null;
      }
      if (goal && obstacle && goal !== obstacle) {
        return null;
      }

      const dist = pos.dist(entity.pos);

      return {
        dist,
        score: -dist,
        pos,
        obstacle,
      };
    }, (a, b) => {
      // return true if choose b over a
      if (a === null) {
        return true;
      }
      if (b.score > a.score) {
        return true;
      }
      return false;
    });
  }

  // goal을 지키는 위치를 찾습니다.
  choosecovergoalpoint(entity, goal, f) {
    const { entities, goals, routes } = this;

    const ot = opponentTeam(entity.team);
    const allies = entities.filter((e) => e !== entity && !ot.includes(e.team) && e.state !== 'dead');
    const enemies = entities.filter((e) => e !== entity && ot.includes(e.team) && e.state !== 'dead');

    const allies_points = allies.map((e) => e.waypoint?.cp?.pos).filter((e) => e);

    // const goals_in_danger = enemies.map((e) => e.waypoint.obstacle);

    // p에서 goal을 얼마나 지킬 수 있는지.
    function goalscore(p, goal) {
      // TODO: CHEAT: 현재 적이 점령하려고 시도하는 goal만 지킵니다.
      /*
      if (!goals_in_danger.includes(goal)) {
        return 0;
      }
      */

      // 점수가 낮을수록 안 좋음. 점령된 goal을 지키지 않습니다.
      if (goal.goalstate.owner >= 0) {
        return 0;
      }

      const scores = goal.coverpoints.map((gp) => {
        const dist = gp.dist(p);
        if (dist >= entity.firearm_range) {
          // 거리가 멀어서 사격할 수 없는 경우
          return 0;
        }

        // 높을수록 gp를 공격하기 어려움 (안좋음)
        const covera = bounds.eq(routes.covers, { from: p, to: gp }, route_cmp);
        // 높을수록 entity가 안전함 (좋음)
        const coverb = bounds.eq(routes.covers, { from: gp, to: p }, route_cmp);
        return routes.covers[coverb].cover * 2 - routes.covers[covera].cover;
      });

      return scores.reduce((a, b) => a + b, 0);
    }

    return (f ?? this.choosepoint.bind(this))((pos, obstacle) => {
      // goal에서 지키지 않습니다
      if (goals.includes(obstacle)) {
        return null;
      }
      // 같은 편과 같은 자리에서 지키지 않습니다.
      if (allies_points.find((p) => pos.eq(p))) {
        return null;
      }

      // score가 높을수록 coverage가 작음
      let score_goal = 0;
      if (goal) {
        score_goal = goalscore(pos, goal);
      } else {
        score_goal = goals.map((goal) => goalscore(pos, goal)).reduce((a, b) => a + b, 0);
      }
      score_goal = Math.min(20, score_goal);

      // 현재 위치에서 가까운 곳을 선호합니다.
      const dist = pos.dist(entity.pos);
      const score_dist = -dist / 200;

      // 현재 적의 위치에서 노출되지 않는 곳을 선호합니다.
      const score_enemy = enemies.map((e) => {
        return checkcover(e.pos, pos, routes) > 1 ? 1 : 0;
      }).reduce((a, b) => a + b, 0) * 4;

      const score = score_goal + score_dist + score_enemy;

      return {
        score_goal,
        score_dist,
        score_enemy,
        score,
        pos,
        obstacle,
      };
    }, (a, b) => {
      // return true if choose b over a
      if (a === null) {
        return true;
      }
      // score가 큰 위치를 찾습니다.
      if (b.score > a.score) {
        return true;
      }

      return false;
    });
  }

  entityWaypointDoor(entity) {
    const { routes } = this;
    const path = entity.waypoint?.path;
    if (!path) {
      return null;
    }

    for (const s of path) {
      if (s.idx === -1) {
        continue;
      }
      const node = routes.nodes[s.idx];
      if (!node.is_coverpoint && node.obstacle.ty === 'door' && !node.obstacle.doorstate.open) {
        return s.idx;
      }
    }
    return null;
  }

  doorShouldStop(entity, node) {
    const { entities, routes } = this;
    const allies = entities.filter((e) => e.team === entity.team && e !== entity && e.state !== 'dead');

    const o = node.obstacle;
    if (o.ty === 'door' && !o.doorstate.open) {
      for (const ally of allies) {
        const door = routes.nodes[this.entityWaypointDoor(ally)];
        if (!door || door.obstacle !== o) {
          continue;
        }
        if (!ally.pos.eq(door.pos)) {
          return true;
        }
      }
    }
    return false;
  }


  entityNextWaypoint(entity, arrived) {
    const { tick, entities, routes } = this;
    const rule = entity.waypoint_rule;
    const allies = entities.filter((e) => e.team === entity.team && e !== entity && e.state !== 'dead');

    // reroute 주기를 초과한 경우
    if (!entity.lastRouteTick.expired(tick)) {
      if (!entity.waypoint) {
        return;
      }

      // 이전 route가 있을 때, 이 route를 일단 계속 따라갑니다.
      const wp = entity.waypoint;
      if (wp.pos.eq(entity.pos)) {
        let path = wp.path.slice();
        while (path.length > 0 && !entity.pos.eq(path[0].pos)) {
          path = path.slice(1);
        }

        if (path.length > 1) {
          const dest = path[1];
          const dest_idx = dest.idx;
          // TODO: 문열기 정리하기
          if (dest_idx) {
            let node = routes.nodes[dest_idx];
            if (this.doorShouldStop(entity, node)) {
              return;
            }
            if (node.is_coverpoint && node.obstacle.ty === 'door') {
              node.obstacle.doorstate.open = true;
              this.rebuildVisibility();
            }
          }

          entity.waypoint = {
            pos: dest.pos,
            cp: wp.cp,
            obstacle: wp.obstacle,
            path,
            freeze_route: true,
          };
          return;
        }
        // waypoint의 끝에 도착했을 때는 항상 reroute?
      } else {
        return;
      }
    }
    entity.lastRouteTick = new TickTimer(tick, this.ticksFromSec(opts.REROUTE_MIN_INTERVAL));

    if (rule.ty === 'idle') {
      return null;
    }

    let cp = null;
    if (rule.ty === 'rescue') {
      cp = this.chooserescuepoint(entity, entity.waypoint_rule.rescue_target);
    } else if (rule.ty === 'follow') {
      cp = this.chooserescuepoint(entity, entity.waypoint_rule.follow_target);
    }

    if (rule.ty === 'explore') {
      if (arrived && entity.waypoint?.path && opts.EXP_INDOOR_DOOR_WAIT) {
        // TODO: 문 진입 데모
        const cur = entity.waypoint.path.find((p) => entity.pos.eq(p.pos));
        const node = routes.nodes[cur.idx];
        if (this.doorShouldStop(entity, node)) {
          return;
        }
      }

      cp = this.chooseexplorepoint(entity);
      // TODO: room hack
      if (cp.maxcover.cover < opts.EXPLORED_THRES) {
        console.log("explored, pop", entity.name);
        entity.pop_rule();
        return this.entityNextWaypoint(entity);
      }
    }
    if (rule.ty === 'capture-goal') {
      cp = this.choosecapturepoint(entity, rule.goal);
    }
    if (rule.ty === 'cover-goal') {
      cp = this.choosecovergoalpoint(entity, rule.goal);
    }
    if (rule.ty === 'reload') {
      cp = this.choosehidepoint(entity);
    }

    if (!cp && entity.aimtarget) {
      // rule 1. 가장 가까운 엄호물
      // rule 2. 오랫동안 상대방을 사격할 수 없는 경우, 상대방이 닿는 곳 (이건 지금 loop에서 처리)
      // rule 3. 내 앞에서 내 상대를 제압하고 있는 아군이 있는 경우, 그 아군보다 더 가까운 곳으로 이동

      if (rule.ty === 'fire') {
        cp = this.choosefirepoint(entity.aimtarget);
      } else {
        const covering_entity = entities.find((e) => e.team === entity.team
          && e !== entity
          && e.state === 'covered'
          && e.aimtarget === entity.aimtarget
          && e.aimtargetshoots > 0);

        let max_dist = 100000;
        if (rule.ty === 'cover-fire') {
          max_dist = Math.min(max_dist, entity.firearm_range);
        }

        if (covering_entity) {
          // TODO
          // max_dist = Math.min(max_dist, covering_entity.pos.dist(entity.aimtarget.pos));
        }
        cp = this.choosecoverpoint(entity, max_dist);
      }
    }

    if (cp && cp.pos.eq(entity.pos)) {
      if (!['covered', 'hide'].includes(entity.state)) {
        entity.state = 'covered';
      }
    } else if (cp) {
      // TODO: XXX: FIXME: 2인 방 진입 데모용
      const ally_doornodes = [];
      for (const e of allies) {
        const door = this.entityWaypointDoor(e);
        if (door && opts.EXP_INDOOR_DOOR_WAIT && ally_doornodes.length === 0) {
          ally_doornodes.push(door);
        }
      }

      const path = routePathfind(routes, entity.pos, cp.pos, (edge) => {
        const cost = edge.len;
        let weight = 1;

        if (opts.ROUTE_WITH_SAMPLING) {
          // check if edge is covered
          const samples = 3;
          let covers = 0;
          for (let i = 0; i < samples; i++) {
            // should be deterministic
            let samplepos = v2.lerp(edge.from, edge.to, (i + 1) / (samples + 1));
            covers += checkcover(entity.aimtarget.pos, samplepos, routes);
          }
          weight = (samples - covers / 2) / (samples);
        }

        return cost * weight;
      }, ally_doornodes);

      const dest = path.find((p) => p.len > 0);

      if (dest) {
        let dest_idx = dest.edge?.to_idx;
        if (dest_idx) {
          let node = routes.nodes[dest_idx];
          if (node.is_coverpoint && node.obstacle.ty === 'door') {
            node.obstacle.doorstate.open = true;
            this.rebuildVisibility();
          }
        }
        entity.waypoint = {
          pos: dest.pos,
          cp,
          obstacle: cp.obstacle,
          path,
          // TODO: explore일 때는 waypoint에서 re-route 안 하도록
          freeze_route: rule.ty === 'explore',
        };
      } else {
        console.log('failed to find route', entity, cp.pos);
        // routePathfind(routes, entity.pos, cp.pos, null, true);
        entity.waypoint = cp;
      }

      if (['covered', 'hide'].includes(entity.state)) {
        entity.state = 'stand';
      }
    }
  }

  ticksFromSec(seconds) {
    return Math.floor(this.tps * seconds);
  }

  entitySpeed(entity) {
    let speed = entity.speed / this.tps;

    switch (entity.state) {
      case 'stand':
        break;
      case 'low':
        speed *= 0.5;
        break;
      case 'covered':
      case 'crawl':
      case 'hide':
      case 'dead':
        speed = 0;
        break;
      default:
        throw new Error(`speed: invalid state: ${entity.state}`);
    }

    // dir/aimdir을 조합해서 이동 속도를 정합니다.
    // abs(reldir), speedmult가 (0, 1), (Math.PI/2, 0)
    const reldir = dirnorm0(entity.dir - entity.aimdir);
    let multiplier = 1;
    for (const item of entity.movespeed_rules) {
      if (reldir < item.reldir) {
        multiplier = item.multiplier;
      }
    }

    return speed * multiplier;
  }

  entityFoundVIP(entity) {

    let target = this.entities
      .filter(e => e.team === 2 && e.state !== 'dead')
      .find((e) => entity.grid[this.world.idx(e.gridpos)] > 0.1);

    if (!target) {
      return null;
    }
    this.journal.push(`${entity.name} founds ${target.name}`);
    return target;
  }

  entityUpdateAimtarget(entity) {
    // TODO: FIXME
    if (entity.ty === 'vip') {
      return;
    }
    const pattern_ended = entity.shootPatternIdx === 0;
    if (!pattern_ended) {
      return;
    }

    // rule에서 target을 명시한 경우
    const rule_target = entity.waypoint_rule.target;
    if (rule_target && rule_target.state !== 'dead') {
      entity.aimtarget = rule_target;
      entity.aimtargetshoots = 0;
      return;
    }

    const { routes } = this;

    const ot = opponentTeam(entity.team);
    const initiator = entity.waypoint_rule.initiator;
    const targets = this.entities
      .filter(e => ot.includes(e.team) && e.state !== 'dead')
      .map((e) => {
        const cover = checkcover(entity.pos, e.pos, routes);
        const dist = entity.pos.dist(e.pos);
        const reachable = dist < entity.firearm_range;
        let visible = true;
        if (entity.use_visibility) {
          visible = entity.grid[this.world.idx(e.gridpos)] > 0.5;
        }
        if (e === initiator) {
          // 실내 데모용: 교전 시작한 상대는 항상 어디있는지 알아야 함
          visible = true;
        }

        /*
        let visible = false;
        // area가 명시된 경우 해당 area의 대상만 target으로
        if (entity.waypoint_rule.area) {
          if (geomContains(e.pos, entity.waypoint_rule.area.polygon)) {
            visible = true;
          }
        }
        */

        return {
          entity: e,
          cover,
          dist,
          reachable,
          visible,
        };
      })
      .filter((e) => e.visible);

    targets.sort((a, b) => {
      const goala = a.entity.waypoint_rule.goal?.goalstate?.owner ?? 0;
      const goalb = a.entity.waypoint_rule.goal?.goalstate?.owner ?? 0;

      // 현재 닿는 상대를 aim
      if (a.reachable !== b.reachable) {
        return b.reachable - a.reachable;
      }

      // goal이 낮은 상대를 aim
      if (goala !== goalb) {
        return goala - goalb;
      }

      if (a.cover !== b.cover) {
        // cover가 낮은 상대를 aim
        return a.cover - b.cover;
      }

      // 거리가 가까운 상태를 aim
      return a.dist - b.dist;
    });

    const target = targets[0] ? targets[0].entity : null;
    if (target && target !== entity.aimtarget) {
      this.journal.push(`${entity.name} aims ${target.name}, ${entity.shootPatternIdx}, ${entity.ammo}`);
      entity.aimtarget = target;
      entity.aimtargetshoots = 0;
    } else if (pattern_ended && entity.aimtarget && entity.aimtarget.state === 'dead') {
      this.journal.push(`${entity.name} unaims, ${entity.shootPatternIdx}, ${entity.ammo}`);
      entity.aimtarget = null;
      entity.aimtargetshoots = 0;
    }
  }

  entityNavigate(entity) {
    if (!entity.waypoint) {
      return;
    }

    const { world, tick } = this;
    const d = entity.waypoint.pos.sub(entity.pos);
    const dist = d.len();

    function gettargetpos(speed) {
      if (dist === 0 || dist < speed) {
        return entity.waypoint.pos;
      }

      return entity.pos.add(d.mul(speed / dist));
    }

    // 이동 방향을 정합니다
    if (dist > 0) {
      entity.dir = dirnorm(d.dir());
    }

    let speed = this.entitySpeed(entity);
    let targetpos = gettargetpos(speed);

    // check collision
    if (opts.EXP_SOFT_COLLISION) {
      for (const other of this.entities) {
        if (other === entity) {
          continue;
        }
        const entitydist = targetpos.dist(other.pos);
        if (entitydist < entity.size + other.size && entity.name.localeCompare(other.name)) {
          speed = speed / 2;
          targetpos = gettargetpos(speed);
        }
      }
    }

    entity.pos = targetpos;
    entity.gridpos = world.worldToGrid(entity.pos);

    const movedist = entity.pos.dist(targetpos);
    if (movedist > 0) {
      entity.state = 'stand';
      entity.moving = true;
      entity.aimtargetshoots = 0;
      entity.movespeed = speed;
    } else {
      entity.moving = false;
      entity.movespeed = 0;
    }

    if (entity.pos === entity.waypoint.pos) {
      // 재장전중에는 waypoint에서 멈춥니다
      if (entity.reloadTick.expired(tick)) {
        this.entityNextWaypoint(entity, true);
      }
    }

    entity.aimmult += Math.min(entity.firearm_aimvar_incr_move_cap,
      entity.movespeed * opts.AIMVAR_INCR_MOVE_MULTIPLER);
  }

  entityUpdateAim(entity, targetdir) {
    entity.debugaimdir = targetdir;

    if (opts.EXP_ROTATE_INSTANT) {
      entity.aimdir = targetdir;
      return;
    }

    const aimdelta0 = dirnorm0(targetdir - entity.aimdir);

    const rule = entity.aim_rot_rules.find((a) => a.aimvar > Math.abs(aimdelta0));

    // angular speed?
    const aimdelta = clamp(aimdelta0, -rule.aimspeed, rule.aimspeed);
    entity.aimdir = dirnorm(entity.aimdir + aimdelta);

    // aimdelta만큼 aim이 흐트러짐
    entity.aimmult += Math.min(entity.firearm_aimvar_incr_rot_cap,
      Math.abs(aimdelta) * opts.AIMVAR_INCR_ROT_MULTIPLER);
  }

  entityHandleFireSim(entity, samples) {
    const { rng } = this;

    const target = entity.aimtarget;

    const variance = entity.aimvar * entity.firearm_aimvar_mult;
    const aimdirmin = entity.aimdir - variance;
    const aimdirmax = entity.aimdir + variance;

    let samples_hits = 0;

    for (let j = 0; j < samples; j++) {
      const firedir = projectile_dice(rng, entity.pos, target.pos, aimdirmin, aimdirmax, opts.AIM_ITER_FIRE);
      let hits = 0;
      for (let i = 0; i < entity.firearm_projectile_per_shoot; i++) {
        const projectile_dir = projectile_dice(rng,
          entity.pos, target.pos,
          firedir - entity.firearm_projectile_aimvar / 2,
          firedir + entity.firearm_projectile_aimvar / 2,
          opts.AIM_ITER_PROJECTILE);

        const dist = lineToPointDist(entity.pos, projectile_dir, target.pos);
        if (dist < target.size) {
          hits += 1;
        }
      }
      if (hits > 0) {
        samples_hits += 1;
      }
    }
    return samples_hits / samples;
  }

  entityHandleFire(entity, trails) {
    const { rng, entities, tick, routes } = this;
    const obstacles = this.obstacles.filter((o) => o.ty === 'full');

    const target = entity.aimtarget;
    const d = target.pos.sub(entity.pos);
    const dist = d.len();
    const targetdir = dirnorm(d.dir());

    this.entityUpdateAim(entity, targetdir);

    const variance = entity.aimvar * entity.firearm_aimvar_mult;
    const aimdirmin = entity.aimdir - variance;
    const aimdirmax = entity.aimdir + variance;

    const cover = checkcover(entity.pos, target.pos, routes);

    let aimvalid = dircontains(targetdir, aimdirmin, aimdirmax) && dist < entity.firearm_range && cover !== 3;
    if (aimvalid) {
      const threats = entities.filter((e) => e.aimtarget === entity && e.state !== 'dead');

      // 위협이 없는 경우, 신중하게 조준합니다.
      if (threats.length === 0) {
        const simres = this.entityHandleFireSim(entity, opts.AIM_SAMPLES_FIRE);
        aimvalid = simres > entity.aim_samples_fire_thres;
      }
    }

    // TODO
    if (entity.reloadTick.expired(tick) // cannot shoot during reload
      && entity.shootPatternTick.expired(tick)
      && entity.ammo > 0
      && aimvalid
      && entity.state !== 'hide') {

      // shoot
      entity.aimtargetshoots += 1;
      entity.aimmult += entity.aimvar_incr_per_shoot;

      entity.ammo -= 1;

      const firedir = projectile_dice(rng, entity.pos, target.pos, aimdirmin, aimdirmax, opts.AIM_ITER_FIRE);
      const target_dist = entity.pos.dist(target.pos);

      // multiple projectiles per shoot
      for (let i = 0; i < entity.firearm_projectile_per_shoot; i++) {
        const projectile_dir = projectile_dice(rng,
          entity.pos, target.pos,
          firedir - entity.firearm_projectile_aimvar / 2,
          firedir + entity.firearm_projectile_aimvar / 2,
          opts.AIM_ITER_PROJECTILE);

        const projectile_end = entity.pos.add(v2.fromdir(projectile_dir).mul(entity.firearm_range));
        const t = obstructed_t(entity.pos, projectile_end, obstacles);
        const ray_len = t * entity.firearm_range;

        entity.reloadShootIdleTick = new TickTimer(tick, this.ticksFromSec(entity.firearm_reload_idle_duration));

        let hit = false;
        const dist = lineToPointDist(entity.pos, projectile_dir, target.pos);
        if (ray_len > target_dist && dist < target.size) {
          let prob = opts.HIT_PROB_STAND;
          if (cover === 1) {
            // obstructed
            prob = opts.HIT_PROB_OBSTRUCTED;
          } else if (cover === 2) {
            if (target.state === 'hide') {
              prob = opts.HIT_PROB_HIDE;
            } else {
              prob = opts.HIT_PROB_COVERED;
            }
          } else if (cover === 3) {
            // blocked
            prob = 0;
          }
          if (target.state === 'crawl') {
            prob = opts.HIT_PROB_CRAWL;
          }

          hit = rng.randomRange(0, 1) < prob + entity.firearm_additional_hit_prob;
          const output = hit ? 'hit' : 'miss';
          this.journal.push(`${entity.name} ${output} ${target.name}, dist=${dist.toFixed(1)}, cover=${cover}, prob was=${prob}, cover=${cover}`);

          if (hit) {
            // 방호복 처리
            const hit_armor = rng.randomRange(0, 1) < target.armor_hit_prob;
            let damage = entity.firearm_projectile_damage;

            if (hit_armor) {
              const armor_damage = Math.min(damage, target.armor);
              target.armor -= armor_damage;
              damage -= armor_damage;
            }

            target.life = Math.max(0, target.life - damage);
            if (target.life === 0) {
              target.state = 'dead';
            }
          }

          target.aimmult += target.aimvar_incr_per_hit;
        }

        trails.push(createTrail(tick, entity.pos, projectile_dir, ray_len, entity, target, hit));
      }

      const pattern = entity.firearm_shoot_pattern;
      const shoot_tick = this.ticksFromSec(entity.firearm_shoot_pattern_interval_sec);
      if (entity.shootPatternIdx < pattern.length) {
        const shoot_pattern_interval = this.ticksFromSec(pattern[entity.shootPatternIdx]);

        entity.shootPatternIdx += 1;
        entity.shootPatternTick = new TickTimer(tick, shoot_pattern_interval);
      } else {
        entity.shootPatternIdx = 0;
        entity.shootPatternTick = new TickTimer(tick, shoot_tick);
      }
    }

    return { aimvalid };
  }

  riskdir0(entity, pos) {
    let obstacles = this.obstacles.filter((o) => o.ty === 'full');

    const { grid } = entity;
    let range = opts.VIS_RANGE;
    let advance = 0;

    if (!pos) {
      advance = entity.movespeed * this.ticksFromSec(1);
      pos = entity.pos.add(v2.fromdir(entity.dir).mul(advance));
      range = opts.VIS_RANGE - advance * 2;
    }

    const sample_count = 32;
    // (-PI, PI) -> (0, 2PI) -> [0, sample_count)
    const samples_count = new Float32Array(sample_count);
    const samples_weighted = new Float32Array(sample_count);
    const samples_dist = new Float32Array(sample_count);
    const sample_size = Math.PI * 2 / sample_count;

    for (let i = 0; i < sample_count; i++) {
      const dir = (i / sample_count) * Math.PI * 2;
      const vec = v2.fromdir(dir);
      const end = pos.add(vec.mul(range));
      const t = obstructed_t(pos, end, obstacles);
      samples_dist[i] = t * range;
    }

    onReachableGridWasm(this.world, this.triangulated, pos, range, (idx, world_pos) => {
      // angle
      const dir = dirnorm(world_pos.sub(pos).dir());
      const diridx = Math.floor(dir / (Math.PI * 2) * sample_count + sample_size / 2);

      if (grid[idx] < 1) {
        samples_count[diridx] += 1;
        samples_weighted[diridx] += (opts.VIS_RANGE * 2 - pos.dist(world_pos)) / (opts.VIS_RANGE * 2);
      }
      return true;
    });

    const samples = samples_weighted;

    let maxidx = 0;
    let maxval = samples[0];

    for (let i = 0; i < sample_count; i++) {
      if (samples[i] > maxval) {
        maxval = samples[i];
        maxidx = i;
      }
    }

    return {
      advance,
      pos,
      sample_count,
      samples,
      samples_dist,

      selected_idx: maxidx,
      selected_val: maxval,
      selected_dir: (maxidx / sample_count) * Math.PI * 2,
    };
  }

  entityUpdateGridOmniDir(entity, pos) {
    if (!pos) {
      pos = entity.pos;
    }
    const { grid } = entity;
    onReachableGridWasm(this.world, this.triangulated, pos, opts.VIS_RANGE, (idx) => {
      grid[idx] = Math.min(1.0, grid[idx] + opts.GRID_VIS_PER_TICK);
      return true;
    });
  }

  param_updated(obj, key, input, eps) {
    const old = obj[key];

    if (!old) {
      obj[key] = input;
      return true;
    }
    let error = 0;
    for (const itemkey in input) {
      const prev = old[itemkey];
      const next = input[itemkey];
      if (prev instanceof v2) {
        error += prev.dist(next);
      } else if (!isNaN(prev)) {
        // TODO
        error += Math.abs(prev - next);
      } else {
        throw new Error("invalid key");
      }
    }
    if (error > eps) {
      obj[key] = input;
      return true;
    }
    return false;
  }

  entityUpdateGrid(entity, pos) {
    if (!pos) {
      pos = entity.pos;
    }

    const eps = 0.1;
    const input = { pos, dir: entity.aimdir };
    if (!this.param_updated(entity, "_entityUpdateGrid", input, eps)) {
      return;
    }

    const { grid } = entity;
    onReachableGridWasm(this.world, this.triangulated, pos, opts.VIS_RANGE, (idx, world_pos) => {
      // angle
      const dirvec = world_pos.sub(pos);
      const dir = dirvec.dir();
      const reldir = dirnorm0(entity.aimdir - dir);

      const visangle = Math.PI / 4;
      const vis = visangle - Math.abs(reldir);
      if (Math.abs(reldir) < visangle) {
        if (opts.EXP_VISIBILITY_SOFT) {
          grid[idx] = Math.min(1.0, grid[idx] + vis);
        } else {
          grid[idx] = 1.0;
        }
      }

      return true;
    });
  }

  entityVisibleAllies(entity) {
    return this.entities.filter((e) => {
      if (e === entity || e.team !== entity.team) {
        return false;
      }

      if (entity.use_visibility) {
        if (entity.grid[this.world.idx(e.gridpos)] < 0.5) {
          return false;
        }
      }
      return true;
    });
  }

  entityVisibleOpponents(entity) {
    const ot = opponentTeam(entity.team);
    return this.entities.filter((e) => {
      if (e === entity || !ot.includes(e.team) || e.state === 'dead') {
        return false;
      }

      if (entity.use_visibility) {
        if (entity.grid[this.world.idx(e.gridpos)] < 0.5) {
          return false;
        }
      }
      return true;
    });
  }

  entityReload(entity) {
    if (!entity.reloadTick.expired(this.tick)) {
      // 이미 재장전중. 무시합니다.
      return;
    }

    const { tick } = this;
    entity.reloadTick = new TickTimer(tick, this.ticksFromSec(entity.firearm_reload_duration));

      // TODO: 실내 데모
    // entity.push_rule({ ty: 'reload' });
  }

  entityOffenced(entity) {
    const { tick, trails } = this;

    // 오랜 시간 공격받지 않았을 경우, 다시 일어나서 움직입니다.
    const last_attacked = trails.reverse().find((t) => t.target === entity);
    const last_attacked_tick = last_attacked ? last_attacked.tick : -1000;

    if (tick - last_attacked_tick < this.ticksFromSec(opts.SHOT_IDLE_DURATION)) {
      return last_attacked;
    }
    return null;
  }

  entityTransferKnoledge(entity) {
    const { entities } = this;
    const ot = opponentTeam(entity.team);
    const allies = entities.filter((e) => e !== entity && !ot.includes(e.team) && e.state !== 'dead');

    for (const e of allies) {
      if (entity.grid === e.grid) {
        // already sharing
        continue;
      }

      const len = entity.grid.length;
      for (let i = 0; i < len; i++) {
        const v = Math.max(e.grid[i], entity.grid[i]);
        e.grid[i] = v;
        entity.grid[i] = v;
      }
    }
  }

  entityUpdate(entity) {
    if (entity.state === 'dead') {
      return;
    }
    if (entity.waypoint_rule.ty === 'dummy') {
      return;
    }

    const { tick, routes, goals, entities, trails } = this;

    let reroute = entity.waypoint_rule.ty !== 'explore' && tick % this.ticksFromSec(opts.REROUTE_INTERVAL) === 0;
    let reload = entity.ammo === 0;

    if (entity.use_visibility) {
      this.entityUpdateGrid(entity);
    }

    // specify target
    const update_aimtarget = tick % this.ticksFromSec(opts.RETARGET_INTERVAL_SEC) === 0
      || entity.aimtarget === null
      || entity.aimtarget.state === 'dead';
    if (update_aimtarget) {
      this.entityUpdateAimtarget(entity);

      // TODO: check if VIP is discovered
      if (entity.waypoint_rule.ty === 'explore') {
        const target = this.entityFoundVIP(entity);
        if (target) {
          const found = entities.find((e) => e.team === entity.team && e.waypoint_rule.ty === 'rescue');
          if (!found) {
            entity.push_rule({ ty: 'rescue', rescue_target: target });
          }
        }
      }
    }

    // const rescue_target_remain = entities.find((e) => e.team !== 0 && e.ty === 'vip');
    if (entity.waypoint_rule.ty === 'rescue' && entity.waypoint_rule.rescue_target.team === entity.team) {
      // TODO
      entity.pop_rule();
    }

    let waypoint_near = false;
    if (entity.waypoint) {
      // waypoint가 있는 경우
      const wp_dist = entity.waypoint.pos.dist(entity.pos);
      waypoint_near = wp_dist < opts.WAYPOINT_DASH_DIST;
    }

    // 최근에 공격받은 적 있는지
    const offenced = this.entityOffenced(entity);
    if (entity.allow_crawl && ['crawl', 'stand'].includes(entity.state)) {
      if (offenced && !waypoint_near) {
        entity.state = 'crawl';
        entity.moving = false;
      } else {
        entity.state = 'stand';
      }
    }

    const opponents = this.entityVisibleOpponents(entity);
    if (entity.waypoint_rule.ty === 'idle' && entity.ty !== 'vip') {
      if (opponents.length > 0) {
        entity.push_rule({ ty: 'fire', initiator: opponents[0] });
        this.entityUpdateAimtarget(entity);
        reroute = true;
      }
    }

    {
      // idle awake
      const allies = [...this.entityVisibleAllies(entity), entity];
      const offenced = allies.map((e) => this.entityOffenced(e)).find((e) => e !== null);
      if (offenced && entity.waypoint_rule.ty === 'idle') {
        entity.push_rule({ ty: 'fire', initiator: offenced.source });
        this.entityUpdateAimtarget(entity);
        reroute = true;
      }
    }

    if (offenced && entity.waypoint_rule.ty === 'explore') {
      entity.push_rule({ ty: 'cover-fire', initiator: offenced.source });
      this.entityUpdateAimtarget(entity);
      reroute = true;
    }

    if (offenced && entity.waypoint_rule.ty === 'capture-goal' && entity.ty !== 'vip') {
      entity.push_rule({ ty: 'cover-fire', initiator: offenced.source });
      this.entityUpdateAimtarget(entity);
      reroute = true;
    }

    if (entity.waypoint_rule.initiator && entity.waypoint_rule.initiator.state === 'dead') {
      entity.pop_rule();
    }

    if (entity.aimtarget && entity.waypoint_rule.ty === 'explore') {
      entity.push_rule({ ty: 'cover-fire', initiator: entity.aimtarget });
    }

    function entityInGoal(entity, target_goal) {
      const goal = entity.waypoint_rule.goal;
      if (!goal || target_goal !== goal) {
        return false;
      }
      return goal && goal.pos.dist(entity.pos) < opts.GOAL_RADIUS;
    }

    // TODO: 미션 시나리오 만들기
    if (entity.waypoint_rule.ty === 'mission') {
      for (const rule of this.mission_rules) {
        entity.push_rule(rule);
      }
    }

    // TODO: explore: 건물 있는 경우 건물을 순차적으로 탐색하기
    if (entity.waypoint_rule.ty === 'capture') {
      // capture: choose goal
      const p = this.choosecapturepoint(entity, null);
      if (p && p.obstacle) {
        entity.push_rule({ ty: 'capture-goal', goal: p.obstacle });
      } else {
        console.error('unable to choose goal, covering');
        entity.push_rule({ ty: 'cover-fire' });
      }
    }
    if (entity.waypoint_rule.ty === 'capture-goal') {
      // 해당 goal이 이미 점령된 경우
      if (entity.waypoint_rule.goal.goalstate.owner >= 0) {
        entity.pop_rule();
      }

      // goal에 나보다 먼저 들어가 있는 상대가 있는 경우
      const opponent = entities.find((e) => e.state !== 'dead' && e.team !== entity.team && entityInGoal(e, entity.waypoint_rule.goal));
      if (opponent) {
        entity.push_rule({ ty: 'cover-fire', target: opponent });
      }
    }

    if (entity.waypoint_rule.ty === 'cover-capture') {
      const nearest = goals.slice();
      nearest.sort((a, b) => entity.pos.dist(a.pos) - entity.pos.dist(b.pos));
      if (nearest.length > 0) {
        entity.push_rule({ ty: 'cover-goal', goal: nearest[0] });
      } else {
        console.error('unable to choose goal, covering');
        entity.push_rule({ ty: 'cover-fire' });
      }
    }

    // reload
    if (entity.reloadTick.expired_exact(tick)) {
      entity.ammo = entity.firearm_ammo_max;
      // TODO: 실내 데모
      // console.assert(entity.waypoint_rule.ty === 'reload');
      // entity.pop_rule();
    }

    if (entity.ammo !== entity.firearm_ammo_max) {
      if (entity.reloadTick.expired(tick) && entity.reloadShootIdleTick.expired(tick)) {
        reload = true;
      }

      // 현재 조준중인 대상이 없고, 현재 위협이 없고, 가야 할 곳에도 위협이 없는 경우 재장전
      if (!entity.aimtarget && entity.lastRiskTick.expired(tick) && entity.waypoint) {
        const r = this.riskdir0(entity, entity.waypoint.pos);
        if (r.selected_val === 0) {
          reload = true;

          if (entity.team === 0 && opts.EXP_TRANSFER_KNOWLEDGE) {
            this.entityTransferKnoledge(entity);
          }
        }
      }
    }

    if (reload && entity.ammo !== entity.firearm_ammo_max) {
      this.entityReload(entity);
      // TODO: 실내 데모
      // reroute = true;
    }

    if (!entity.waypoint || reroute) {
      this.entityNextWaypoint(entity);
    }

    let navigate = true;

    entity.aimmult = entity.aimmult * opts.AIMVAR_DECAY_PER_TICK;
    entity.aimvar = lerp(entity.aimvar_hold_max, entity.aimvar_hold, entity.aimmult);

    // TODO: rule === 'fire'에서 재장전이 필요한 경우 대기합니다.
    if (entity.ammo === 0 && entity.waypoint_rule.ty === 'fire') {
      navigate = false;
    }

    if (!entity.aimtarget && entity.ty === 'vip') {
      // TODO: XXX: FIXME: milestone 1 데모용
      this.entityUpdateAim(entity, entity.dir);
    } else if (!entity.aimtarget && entity.use_riskdir) {
      const { selected_val, selected_dir } = this.riskdir0(entity);

      if (selected_val > opts.RISKDIR_THRES) {
        this.entityUpdateAim(entity, selected_dir);
        entity.lastRiskTick = new TickTimer(tick, this.ticksFromSec(opts.RISK_DIR_PERSISTENT_DURATION));
      }

      if (entity.lastRiskTick.expired(tick)) {
        // 자유 공간을 탐험중

        // 기본적으로 진행 방향을 봅니다.
        let dir = entity.dir;

        // 실내 데모용: 문 진입시 동작
        if (entity.waypoint && entity.waypoint.path) {
          for (const p of entity.waypoint.path) {
            if (p.idx === -1) {
              continue;
            }
            const node = routes.nodes[p.idx];
            if (node.obstacle && node.obstacle.ty === 'door' && !node.obstacle.doorstate.open) {
              // 경로에 열어야 하는 문이 있는 경우
              let d = node.pos.sub(entity.pos);
              if (d.len() < 10) {
                dir = node.obstacle.pos.sub(entity.pos).dir();
              }
              break;
            }
          }
        }

        this.entityUpdateAim(entity, dir);
      }
    }


    // aim
    // 시체에 대고도 가끔 쏩니다.
    if (entity.aimtarget) {
      const { aimvalid } = this.entityHandleFire(entity, trails);
      // TODO: rule === 'fire'에서 상대를 사격할 수 있는 상태인 경우 정지합니다.
      if (entity.waypoint_rule.ty === 'fire' && aimvalid) {
        navigate = false;
      }
    }

    if (navigate) {
      this.entityNavigate(entity);
    }
  }

  triggerAction(trigger) {
    if (trigger.action === 'push_rule') {
      const { actiontarget, actionrule } = trigger;
      const entities = this.entities.filter((e) => {
        if (e.state === 'dead') {
          return false;
        }
        if (actiontarget.team !== undefined && actiontarget.team === e.team) {
          return true;
        }
        if (actiontarget.group !== undefined && actiontarget.group === e.group) {
          return true;
        }
        return false;
      });

      for (const entity of entities) {
        entity.push_rule(actionrule);
      }
    } else {
      throw new Error(`unknown action: ${trigger.action}`);
    }
  }

  onTick() {
    const idx = this.tick % opts.PERF_BUF_SIZE;
    const start = Date.now();
    const res = this.onTick0();
    const dt = Date.now() - start;

    this.perfbuf[idx] = { start, dt };
    return res;
  }

  onTick0() {
    const { tick, world, goals, entities } = this;

    // simover check: occupy
    const occupymajor = Math.floor((goals.length + 1) / 2);
    const t0win = goals.filter((e) => e.goalstate.owner === 0).length >= occupymajor;
    const t1win = goals.filter((e) => e.goalstate.owner === 1).length >= occupymajor;

    if (world.simover_rule === 'goal') {
      if (t0win || t1win) {
        return t0win ? 0 : 1;
      }
    }

    // simover check: eliminated
    const t0dead = entities.filter((e) => e.team === 0 && e.state !== 'dead').length === 0;
    const t1dead = entities.filter((e) => e.team === 1 && e.state !== 'dead').length === 0;

    if (world.simover_rule === 'eliminate') {
      if (t0dead || t1dead) {
        return t0dead ? 0 : 1;
      }
    }

    if (world.simover_rule === 'mission') {
      if (t0win && t1dead) {
        return 0;
      }
      if (t0dead) {
        return 1;
      }
    }

    // update entities
    for (const entity of entities) {
      this.entityUpdate(entity);
    }

    // update vips
    const vips = entities.filter((e) => e.ty === 'vip');
    for (const vip of vips) {
      if (vip.waypoint_rule.ty === 'idle') {
        const nearby = entities.find((e) => e.state !== 'dead'
          && e.team === 0
          && e.pos.dist(vip.pos) < opts.RESCUE_RADIUS
          && !obstructed(vip.pos, e.pos, this.obstacles));

        if (nearby) {
          vip.team = nearby.team;
          vip.push_rule({ ty: 'follow', follow_target: nearby });
        }
      }
    }

    // update triggers
    for (const area of this.spawnareas) {
      area.areastate.triggers = area.areastate.triggers.filter((t) => {
        // check conditions
        if (t.condition === 'enter') {
          if (entities.find((e) => geomContains(e.pos, area.polygon) && e.team === t.conditiontarget.team)) {
            this.triggerAction(t);
            return false;
          }
          return true;
        } else {
          throw new Error("unknown trigger condition: " + t.condition);
        }
      });
    }

    // update goals
    for (let i = 0; i < goals.length; i++) {
      const goal = goals[i];
      const state = goal.goalstate;
      const candidates = entities.filter((e) => e.state !== 'dead' && goal.pos.dist(e.pos) < opts.GOAL_RADIUS);
      state.count_team0 = candidates.filter((e) => e.team === 0).length;
      state.count_team1 = candidates.filter((e) => e.team === 1).length;

      if (state.owner >= 0) {
        continue;
      }

      let occupying_team = -1;
      if (state.count_team0 > state.count_team1) {
        occupying_team = 0;
      } else if (state.count_team0 < state.count_team1) {
        occupying_team = 1;
      }

      if (occupying_team !== state.occupying_team) {
        state.occupying_team = occupying_team;
        if (occupying_team >= 0) {
          state.occupy_tick = tick;
        }
      }

      if (occupying_team >= 0 && tick - state.occupy_tick > this.ticksFromSec(opts.GOAL_OCCUPY_DURATION)) {
        state.owner = state.occupying_team;
      }
    }

    this.tick += 1;

    return -1;
  }
}

