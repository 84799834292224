import { Routes, Route } from 'react-router-dom';
import React from 'react';
import { App } from './App';
import Editor from './Editor';
import Rooms from './Rooms';
import Wasm from './Wasm';
import init, * as m from 'asm';

let loading = false;
let loaded = false;
const callbacks = [];
const initWasm = function (cb) {
  if (loaded) {
    cb();
    return;
  }
  callbacks.push(cb);
  if (loading) {
    return;
  }
  loading = true;
  init().then(() => {
    loaded = true;
    const pending = callbacks.splice(0);
    for (const cb of pending) {
      cb();
    }
  });
};

export class Main extends React.Component {
  constructor(props) {
    super(props);

    this.state = { loaded: false };
  }

  componentDidMount() {
    initWasm(() => {
      this.setState({ loaded: true });
    });
  }

  render() {
    if (!this.state.loaded) {
      return "Loading";
    }

    return (
      <Routes>
        <Route path="/" element={<App m={m}/>}></Route>
        <Route path="editor" element={<Editor />}></Route>
        <Route path="room" element={<Rooms />}></Route>
        <Route path="wasm" element={<Wasm />}></Route>
      </Routes>
    );
  }
}

export default Main;
