import { ENTITY_CONFIG_TMPL } from './opts.mjs';

// 아군 에이전트 정의

// 아군 에이전트 방탄복
// 낮은 스펙의 방탄복. 33의 피해를 입히는 5.56 탄을 1회 더 버티게 해줍니다.
export const tmpl_bulletproof_low = {
  ...ENTITY_CONFIG_TMPL,
  default_rule: 'mission',
  use_visibility: true,

  armor: 33,
  armor_hit_prob: 0.65,
};

// 중간 스펙의 방탄복. 33의 피해를 입히는 5.56 탄을 2회 더 버티게 해줍니다.
export const tmpl_bulletproof_mid = {
  ...ENTITY_CONFIG_TMPL,
  default_rule: 'mission',
  use_visibility: true,

  armor: 66,
  armor_hit_prob: 0.7,
};

// 높은 스펙의 방탄복. 33의 피해를 입히는 5.56 탄을 3회 더 버티게 해줍니다.
export const tmpl_bulletproof_high = {
  ...ENTITY_CONFIG_TMPL,
  default_rule: 'mission',
  use_visibility: true,

  armor: 99,
  armor_hit_prob: 0.75,
};

// 매우 높은 스펙의 방탄복. 33의 피해를 입히는 5.56 탄을 4회 더 버티게 해줍니다.
export const tmpl_bulletproof_highend = {
  ...ENTITY_CONFIG_TMPL,
  default_rule: 'mission',
  use_visibility: true,

  armor: 100,
  armor_hit_prob: 0.8,
};


// 아군 에이전트 화기 (AR)
// 낮은 스펙의 AR. 레퍼런스는 GAU-5입니다.
export const tmpl_firearm_ar_low = {
  // 피카티니 레일이 없음. 광학장비를 달수 없으니 rot_cap 값을 높여둡니다.
  firearm_aimvar_incr_rot_cap: 0.15,
  firearm_aimvar_incr_move_cap: 0.05,

  firearm_ammo_max: 30,
  firearm_range: 320,
  firearm_aimvar_mult: 1.15,

  firearm_shoot_pattern: [0.25, 0.25],
  firearm_shoot_pattern_interval_sec: 0.75,

  firearm_projectile_per_shoot: 1,
  firearm_projectile_damage: 33,
  firearm_projectile_aimvar: Math.PI / 256,

  firearm_reload_duration: 2.5,
  firearm_reload_idle_duration: 10,

  aimvar_hold: Math.PI / 32,
  aimvar_hold_max: Math.PI / 256,
  aimvar_incr_per_shoot: 0.15,
  aimvar_incr_per_hit: 0.25,

  aim_samples_fire_thres: 0.25,

  firearm_additional_hit_prob: 0,

  aim_rot_rules: [
    { aimvar: Math.PI / 2, aimspeed: 0.1},
    { aimvar: Math.PI * 2, aimspeed: 0.1 },
  ],
};

// 중간 스펙의 AR 레퍼런스는 M16A4 MWS 입니다.
export const tmpl_firearm_ar_mid = {
  firearm_aimvar_incr_rot_cap: 0.08,
  firearm_aimvar_incr_move_cap: 0.04,

  firearm_ammo_max: 30,
  firearm_range: 320,
  firearm_aimvar_mult: 1,

  firearm_shoot_pattern: [0.15, 0.15],
  firearm_shoot_pattern_interval_sec: 0.6,

  firearm_projectile_per_shoot: 1,
  firearm_projectile_damage: 33,
  firearm_projectile_aimvar: 0,

  firearm_reload_duration: 2.5,
  firearm_reload_idle_duration: 10,

  aimvar_hold: Math.PI / 32,
  aimvar_hold_max: Math.PI / 256,
  aimvar_incr_per_shoot: 0.08,
  aimvar_incr_per_hit: 0.25,

  aim_samples_fire_thres: 0.25,

  firearm_additional_hit_prob: 0.1,

  aim_rot_rules: [
    { aimvar: Math.PI / 2, aimspeed: 0.1},
    { aimvar: Math.PI * 2, aimspeed: 0.1 },
  ],

  movespeed_rules: [
    // 기타: 옆뒤 혹은 뒤로 움직일 때, 10%로 이동합니다.
    { reldir: Math.PI * 2, multiplier: 0.1 },
    // 진행방향 좌/우 90도 안쪽을 바라볼 때 (옆걸음), 30%로 이동합니다.
    { reldir: Math.PI / 2, multiplier: 0.3 },
    // 진행방향 좌/우 30도 안쪽을 바라볼 때, 정상 속도로 이동합니다.
    { reldir: Math.PI / 6, multiplier: 1 },
  ]
};

// 높은 스펙의 AR 레퍼런스는 HK416 입니다.
export const tmpl_firearm_ar_high = {
   firearm_aimvar_incr_rot_cap: 0.05,
  firearm_aimvar_incr_move_cap: 0.025,

  firearm_ammo_max: 30,
  firearm_range: 320,
  firearm_aimvar_mult: 0.8,

  firearm_shoot_pattern: [0.1],
  firearm_shoot_pattern_interval_sec: 0.25,

  firearm_projectile_per_shoot: 1,
  firearm_projectile_damage: 33,
  firearm_projectile_aimvar: 0,

  firearm_reload_duration: 2.5,
  firearm_reload_idle_duration: 10,

  aimvar_hold: Math.PI / 32,
  aimvar_hold_max: Math.PI / 256,
  aimvar_incr_per_shoot: 0.06,
  aimvar_incr_per_hit: 0.2,

  aim_samples_fire_thres: 0.25,

  firearm_additional_hit_prob: 0.15,

  aim_rot_rules: [
    { aimvar: Math.PI / 2, aimspeed: 0.125},
    { aimvar: Math.PI * 2, aimspeed: 0.1 },
  ],

  movespeed_rules: [
    // 기타: 옆뒤 혹은 뒤로 움직일 때, 10%로 이동합니다.
    { reldir: Math.PI * 2, multiplier: 0.1 },
    // 진행방향 좌/우 90도 안쪽을 바라볼 때 (옆걸음), 30%로 이동합니다.
    { reldir: Math.PI / 2, multiplier: 0.3 },
    // 진행방향 좌/우 30도 안쪽을 바라볼 때, 정상 속도로 이동합니다.
    { reldir: Math.PI / 6, multiplier: 1 },
  ]
};


// 아군 에이전트 화기 (SG)
// 낮은 스펙의 SG. 레퍼런스는 M870입니다.
export const tmpl_firearm_sg_low = {
  firearm_aimvar_incr_rot_cap: 0.1,
  firearm_aimvar_incr_move_cap: 0.07,

  firearm_ammo_max: 5,
  firearm_range: 160,
  firearm_aimvar_mult: 1,

  firearm_shoot_pattern: [],
  firearm_shoot_pattern_interval_sec: 0.8,

  firearm_projectile_per_shoot: 9,
  firearm_projectile_damage: 35,

  // 산탄 초크 같은걸 달면 펠럿이 퍼지는 범위를 줄일 수 있기 때문에 나중에 부착물이나 퍽으로 제어하는게 이상하진 않을 것으로 예상
  firearm_projectile_aimvar: Math.PI / 28,

  firearm_reload_duration: 5,
  firearm_reload_idle_duration: 10,

  aimvar_hold: Math.PI / 32,
  aimvar_hold_max: Math.PI / 280,
  aimvar_incr_per_shoot: 1,
  aimvar_incr_per_hit: 0.2,

  aim_samples_fire_thres: 0.6,

  firearm_additional_hit_prob: 0.35,

  aim_rot_rules: [
    { aimvar: Math.PI / 2, aimspeed: 0.11 },
    { aimvar: Math.PI * 2, aimspeed: 0.1 },
  ],

  movespeed_rules: [
    // 기타: 옆뒤 혹은 뒤로 움직일 때, 10%로 이동합니다.
    { reldir: Math.PI * 2, multiplier: 0.1 },
    // 진행방향 좌/우 90도 안쪽을 바라볼 때 (옆걸음), 30%로 이동합니다.
    { reldir: Math.PI / 2, multiplier: 0.3 },
    // 진행방향 좌/우 30도 안쪽을 바라볼 때, 정상 속도로 이동합니다.
    { reldir: Math.PI / 6, multiplier: 1 },
  ]
};


// 중간 스펙의 SG. 레퍼런스는 M1014입니다.
export const tmpl_firearm_sg_mid = {
  firearm_aimvar_incr_rot_cap: 0.08,
  firearm_aimvar_incr_move_cap: 0.06,

  firearm_ammo_max: 8,
  firearm_range: 160,
  firearm_aimvar_mult: 1,

  firearm_shoot_pattern: [],
  firearm_shoot_pattern_interval_sec: 0.7,

  firearm_projectile_per_shoot: 9,
  firearm_projectile_damage: 35,

  // 산탄 초크 같은걸 달면 펠럿이 퍼지는 범위를 줄일 수 있기 때문에 나중에 부착물이나 퍽으로 제어하는게 이상하진 않을 것으로 예상
  firearm_projectile_aimvar: Math.PI / 28,

  firearm_reload_duration: 4.5,
  firearm_reload_idle_duration: 10,

  aimvar_hold: Math.PI / 32,
  aimvar_hold_max: Math.PI / 280,
  aimvar_incr_per_shoot: 0.8,
  aimvar_incr_per_hit: 0.2,

  aim_samples_fire_thres: 0.6,

  firearm_additional_hit_prob: 0.35,

  aim_rot_rules: [
    { aimvar: Math.PI / 2, aimspeed: 0.12 },
    { aimvar: Math.PI * 2, aimspeed: 0.1 },
  ],

  movespeed_rules: [
    // 기타: 옆뒤 혹은 뒤로 움직일 때, 10%로 이동합니다.
    { reldir: Math.PI * 2, multiplier: 0.1 },
    // 진행방향 좌/우 90도 안쪽을 바라볼 때 (옆걸음), 30%로 이동합니다.
    { reldir: Math.PI / 2, multiplier: 0.3 },
    // 진행방향 좌/우 30도 안쪽을 바라볼 때, 정상 속도로 이동합니다.
    { reldir: Math.PI / 6, multiplier: 1 },
  ]
};

// 높은 스펙의 SG. 레퍼런스는 AA-12입니다.
export const tmpl_firearm_sg_high = {
  firearm_aimvar_incr_rot_cap: 0.06,
  firearm_aimvar_incr_move_cap: 0.05,

  firearm_ammo_max: 20,
  firearm_range: 160,
  firearm_aimvar_mult: 1,

  firearm_shoot_pattern: [],
  firearm_shoot_pattern_interval_sec: 0.55,

  firearm_projectile_per_shoot: 9,
  firearm_projectile_damage: 35,

  // 산탄 초크 같은걸 달면 펠럿이 퍼지는 범위를 줄일 수 있기 때문에 나중에 부착물이나 퍽으로 제어하는게 이상하진 않을 것으로 예상
  firearm_projectile_aimvar: Math.PI / 28,

  firearm_reload_duration: 4,
  firearm_reload_idle_duration: 10,

  aimvar_hold: Math.PI / 32,
  aimvar_hold_max: Math.PI / 280,
  aimvar_incr_per_shoot: 0.50,
  aimvar_incr_per_hit: 0.2,

  aim_samples_fire_thres: 0.6,

  firearm_additional_hit_prob: 0.35,

  aim_rot_rules: [
    { aimvar: Math.PI / 2, aimspeed: 0.11 },
    { aimvar: Math.PI * 2, aimspeed: 0.1 },
  ],

  movespeed_rules: [
    // 기타: 옆뒤 혹은 뒤로 움직일 때, 10%로 이동합니다.
    { reldir: Math.PI * 2, multiplier: 0.1 },
    // 진행방향 좌/우 90도 안쪽을 바라볼 때 (옆걸음), 30%로 이동합니다.
    { reldir: Math.PI / 2, multiplier: 0.3 },
    // 진행방향 좌/우 30도 안쪽을 바라볼 때, 정상 속도로 이동합니다.
    { reldir: Math.PI / 6, multiplier: 1 },
  ]
};

const tmpl_firearm_dmr_mid = {
  firearm_ammo_max: 10,
  firearm_range: 550,
  firearm_aimvar_mult: 0.75,

  firearm_shoot_pattern: [],
  firearm_shoot_pattern_interval_sec: 2.5,

  firearm_projectile_per_shoot: 1,
  firearm_projectile_damage: 100,
  firearm_projectile_aimvar: 0,

  firearm_reload_duration: 4.5,
  firearm_reload_idle_duration: 10,

  aimvar_hold: Math.PI / 32,
  aimvar_hold_max: Math.PI / 280,
  aimvar_incr_per_shoot: 0.5,
  aimvar_incr_per_hit: 0.6,

  aim_rot_rules: [
    { aimvar: Math.PI / 2, aimspeed: 0.02 },
    { aimvar: Math.PI * 2, aimspeed: 0.1 },
  ],

  movespeed_rules: [
    // 기타: 옆뒤 혹은 뒤로 움직일 때, 10%로 이동합니다.
    { reldir: Math.PI * 2, multiplier: 0.1 },
    // 진행방향 좌/우 90도 안쪽을 바라볼 때 (옆걸음), 30%로 이동합니다.
    { reldir: Math.PI / 2, multiplier: 0.3 },
    // 진행방향 좌/우 30도 안쪽을 바라볼 때, 정상 속도로 이동합니다.
    { reldir: Math.PI / 6, multiplier: 1 },
  ]
};

// 중간 수준의 dmr 사용하는 에이전트
export const team0_tmpl_agent_dmr_mid = {
  ...tmpl_bulletproof_low,
  ...tmpl_firearm_dmr_mid,
  life: 100,
  speed: 15,

  movespeed_rules: [
    // 기타: 옆뒤 혹은 뒤로 움직일 때, 10%로 이동합니다.
    { reldir: Math.PI * 2, multiplier: 0.1 },
    // 진행방향 좌/우 90도 안쪽을 바라볼 때 (옆걸음), 30%로 이동합니다.
    { reldir: Math.PI / 2, multiplier: 0.3 },
    // 진행방향 좌/우 30도 안쪽을 바라볼 때, 정상 속도로 이동합니다.
    { reldir: Math.PI / 6, multiplier: 1 },
  ]
};

// 높은 수준의 sg 사용하는 에이전트
export const team0_tmpl_agent_sg_high = {
  ...tmpl_bulletproof_mid,
  ...tmpl_firearm_sg_high,
  life: 100,
  speed: 50,

  movespeed_rules: [
    // 기타: 옆뒤 혹은 뒤로 움직일 때, 10%로 이동합니다.
    { reldir: Math.PI * 2, multiplier: 0.2 },
    // 진행방향 좌/우 90도 안쪽을 바라볼 때 (옆걸음), 30%로 이동합니다.
    { reldir: Math.PI / 2, multiplier: 0.5 },
    // 진행방향 좌/우 30도 안쪽을 바라볼 때, 정상 속도로 이동합니다.
    { reldir: Math.PI / 6, multiplier: 1 },
  ]
};

// 중간 수준의 ar 사용하는 에이전트
export const team0_tmpl_agent_ar_mid = {
  ...tmpl_bulletproof_mid,
  ...tmpl_firearm_ar_mid,
  life: 100,
  speed: 50,

  movespeed_rules: [
    // 기타: 옆뒤 혹은 뒤로 움직일 때, 10%로 이동합니다.
    { reldir: Math.PI * 2, multiplier: 0.2 },
    // 진행방향 좌/우 90도 안쪽을 바라볼 때 (옆걸음), 30%로 이동합니다.
    { reldir: Math.PI / 2, multiplier: 0.5 },
    // 진행방향 좌/우 30도 안쪽을 바라볼 때, 정상 속도로 이동합니다.
    { reldir: Math.PI / 6, multiplier: 1 },
  ]
};


// 적 에이전트 정의
// 낮은 스펙의 적 방탄복. 33의 피해를 입히는 5.56 탄을 1회 더 버티게 해줍니다.
export const enemy_bulletproof_low = {
  ...ENTITY_CONFIG_TMPL,
  default_rule: 'mission',
  use_visibility: true,

  armor: 33,
  armor_hit_prob: 0.25,
};

// 중간 스펙의 적 방탄복. 33의 피해를 입히는 5.56 탄을 1회 더 버티게 해줍니다. 버틸 확률이 조금 더 높습니다.
export const enemy_bulletproof_mid = {
  ...ENTITY_CONFIG_TMPL,
  default_rule: 'mission',
  use_visibility: true,

  armor: 33,
  armor_hit_prob: 0.40,
};

// 높은 스펙의 방탄복. 33의 피해를 입히는 5.56 탄을 2회 더 버티게 해줍니다.
export const enemy_bulletproof_high = {
  ...ENTITY_CONFIG_TMPL,
  default_rule: 'mission',
  use_visibility: true,

  armor: 66,
  armor_hit_prob: 0.40,
};

// 매우 높은 스펙의 방탄복. 33의 피해를 입히는 5.56 탄을 2회 더 버티게 해줍니다. 버틸 확률이 높습니다.
export const enemy_bulletproof_highend = {
  ...ENTITY_CONFIG_TMPL,
  default_rule: 'mission',
  use_visibility: true,

  armor: 66,
  armor_hit_prob: 0.6,
};



// 실내 프리셋에서의 방탄복 수준 정의

// 낮은 스펙의 방탄복. 33의 피해를 입히는 5.56 탄을 1회 더 버티게 해줍니다.
export const tmpl_bulletproof_low_indoor = {
  ...ENTITY_CONFIG_TMPL,
  default_rule: 'explore',
  use_visibility: true,
  allow_crawl: false,

  life: 100,
  armor: 33,
  armor_hit_prob: 0.65,
  speed: 20,
};

// 중간 스펙의 방탄복. 33의 피해를 입히는 5.56 탄을 2회 더 버티게 해줍니다.
export const tmpl_bulletproof_mid_indoor = {
  ...ENTITY_CONFIG_TMPL,
  default_rule: 'explore',
  use_visibility: true,
  allow_crawl: false,

  life: 100,
  armor: 66,
  armor_hit_prob: 0.7,
  speed: 20,
};

// 높은 스펙의 방탄복. 33의 피해를 입히는 5.56 탄을 3회 더 버티게 해줍니다.
export const tmpl_bulletproof_high_indoor = {
  ...ENTITY_CONFIG_TMPL,
  default_rule: 'explore',
  use_visibility: true,
  allow_crawl: false,

  life: 100,
  armor: 99,
  armor_hit_prob: 0.75,
  speed: 20,
};

// 매우 높은 스펙의 방탄복. 33의 피해를 입히는 5.56 탄을 4회 더 버티게 해줍니다.
export const tmpl_bulletproof_highend_indoor = {
  ...ENTITY_CONFIG_TMPL,
  default_rule: 'explore',
  use_visibility: true,
  allow_crawl: false,

  life: 100,
  armor: 100,
  armor_hit_prob: 0.8,
  speed: 20,
};


// 낮은 스펙의 AR. 레퍼런스는 GAU-5입니다.
export const team0_tmpl_indoor_ar_low = {
  ...tmpl_bulletproof_low_indoor,

  // 피카티니 레일이 없음. 광학장비를 달수 없으니 rot_cap 값을 높여둡니다.
  firearm_aimvar_incr_rot_cap: 0.2,

  firearm_ammo_max: 30,
  firearm_range: 380,
  firearm_aimvar_mult: 1.5,

  firearm_shoot_pattern: [0.15, 0.15],
  firearm_shoot_pattern_interval_sec: 0.75,

  firearm_projectile_per_shoot: 1,
  firearm_projectile_damage: 33,
  firearm_projectile_aimvar: Math.PI / 224,

  firearm_reload_duration: 2.5,
  firearm_reload_idle_duration: 10,

  aimvar_hold: Math.PI / 32,
  aimvar_hold_max: Math.PI / 256,
  aimvar_incr_per_shoot: 0.25,
  aimvar_incr_per_hit: 0.25,

  aim_samples_fire_thres: 0.4,

  firearm_additional_hit_prob: 0,

  aim_rot_rules: [
    { aimvar: Math.PI / 2, aimspeed: 0.1},
    { aimvar: Math.PI * 2, aimspeed: 0.1 },
  ],

  movespeed_rules: [
    // 기타: 옆뒤 혹은 뒤로 움직일 때, 10%로 이동합니다.
    { reldir: Math.PI * 2, multiplier: 0.1 },
    // 진행방향 좌/우 90도 안쪽을 바라볼 때 (옆걸음), 30%로 이동합니다.
    { reldir: Math.PI / 2, multiplier: 0.3 },
    // 진행방향 좌/우 30도 안쪽을 바라볼 때, 정상 속도로 이동합니다.
    { reldir: Math.PI / 6, multiplier: 1 },
  ]
};

// 중간 스펙의 AR 레퍼런스는 M16A4 MWS 입니다.
export const team0_tmpl_indoor_ar_mid = {
  ...tmpl_bulletproof_low_indoor,

  firearm_aimvar_incr_rot_cap: 0.15,

  firearm_ammo_max: 30,
  firearm_range: 400,
  firearm_aimvar_mult: 1.2,

  firearm_shoot_pattern: [0.15, 0.15, 0.15],
  firearm_shoot_pattern_interval_sec: 0.7,

  firearm_projectile_per_shoot: 1,
  firearm_projectile_damage: 33,
  firearm_projectile_aimvar: Math.PI / 248,

  firearm_reload_duration: 2.5,
  firearm_reload_idle_duration: 10,

  aimvar_hold: Math.PI / 32,
  aimvar_hold_max: Math.PI / 256,
  aimvar_incr_per_shoot: 0.2,
  aimvar_incr_per_hit: 0.25,

  aim_samples_fire_thres: 0.6,

  firearm_additional_hit_prob: 0,

  aim_rot_rules: [
    { aimvar: Math.PI / 2, aimspeed: 0.1},
    { aimvar: Math.PI * 2, aimspeed: 0.1 },
  ],

  movespeed_rules: [
    // 기타: 옆뒤 혹은 뒤로 움직일 때, 10%로 이동합니다.
    { reldir: Math.PI * 2, multiplier: 0.1 },
    // 진행방향 좌/우 90도 안쪽을 바라볼 때 (옆걸음), 30%로 이동합니다.
    { reldir: Math.PI / 2, multiplier: 0.3 },
    // 진행방향 좌/우 30도 안쪽을 바라볼 때, 정상 속도로 이동합니다.
    { reldir: Math.PI / 6, multiplier: 1 },
  ]
};

// 중간 스펙의 AR 레퍼런스는 HK416 입니다.
export const team0_tmpl_indoor_ar_high = {
  ...tmpl_bulletproof_mid_indoor,

  firearm_aimvar_incr_rot_cap: 0.09,

  firearm_ammo_max: 30,
  firearm_range: 400,
  firearm_aimvar_mult: 1,

  firearm_shoot_pattern: [0.1],
  firearm_shoot_pattern_interval_sec: 0.25,

  firearm_projectile_per_shoot: 1,
  firearm_projectile_damage: 33,
  firearm_projectile_aimvar: Math.PI / 256,

  firearm_reload_duration: 2.5,
  firearm_reload_idle_duration: 10,

  aimvar_hold: Math.PI / 32,
  aimvar_hold_max: Math.PI / 256,
  aimvar_incr_per_shoot: 0.15,
  aimvar_incr_per_hit: 0.25,

  aim_samples_fire_thres: 0.75,

  firearm_additional_hit_prob: 0,

  aim_rot_rules: [
    { aimvar: Math.PI / 2, aimspeed: 0.1},
    { aimvar: Math.PI * 2, aimspeed: 0.1 },
  ],

  movespeed_rules: [
    // 기타: 옆뒤 혹은 뒤로 움직일 때, 10%로 이동합니다.
    { reldir: Math.PI * 2, multiplier: 0.1 },
    // 진행방향 좌/우 90도 안쪽을 바라볼 때 (옆걸음), 30%로 이동합니다.
    { reldir: Math.PI / 2, multiplier: 0.3 },
    // 진행방향 좌/우 30도 안쪽을 바라볼 때, 정상 속도로 이동합니다.
    { reldir: Math.PI / 6, multiplier: 1 },
  ]
};


export const team0_tmpl_indoor_sg = {
  ...tmpl_bulletproof_low_indoor,
  firearm_aimvar_incr_rot_cap: 0.05,

  firearm_ammo_max: 7,
  firearm_range: 200,
  firearm_aimvar_mult: 1,

  firearm_shoot_pattern: [],
  firearm_shoot_pattern_interval_sec: 0.8,

  firearm_projectile_per_shoot: 9,
  firearm_projectile_damage: 35,
  firearm_projectile_aimvar: Math.PI / 32,

  firearm_reload_duration: 5,
  firearm_reload_idle_duration: 10,

  aimvar_hold: Math.PI / 32,
  aimvar_hold_max: Math.PI / 256,
  aimvar_incr_per_shoot: 1,
  aimvar_incr_per_hit: 0.2,

  aim_samples_fire_thres: 0.8,

  firearm_additional_hit_prob: 0.1,

  aim_rot_rules: [
    { aimvar: Math.PI / 2, aimspeed: 0.1 },
    { aimvar: Math.PI * 2, aimspeed: 0.1 },
  ],

  movespeed_rules: [
    // 기타: 옆뒤 혹은 뒤로 움직일 때, 10%로 이동합니다.
    { reldir: Math.PI * 2, multiplier: 0.1 },
    // 진행방향 좌/우 90도 안쪽을 바라볼 때 (옆걸음), 30%로 이동합니다.
    { reldir: Math.PI / 2, multiplier: 0.3 },
    // 진행방향 좌/우 30도 안쪽을 바라볼 때, 정상 속도로 이동합니다.
    { reldir: Math.PI / 6, multiplier: 1 },
  ]
};

// MAC-10
export const team0_tmpl_indoor_smg_low = {
  ...tmpl_bulletproof_low_indoor,
  firearm_aimvar_incr_rot_cap: 0.25,

  firearm_ammo_max: 32,
  firearm_range: 200,
  firearm_aimvar_mult: 1.75,

  firearm_shoot_pattern: [0.05,0.05,0.05,0.05,0.05,0.05,0.05],
  firearm_shoot_pattern_interval_sec: 0.25,

  firearm_projectile_per_shoot: 1,
  firearm_projectile_damage: 23,
  firearm_projectile_aimvar: Math.PI / 128,

  firearm_reload_duration: 1.25,
  firearm_reload_idle_duration: 10,

  aimvar_hold: Math.PI / 32,
  aimvar_hold_max: Math.PI / 256,
  aimvar_incr_per_shoot: 0.2,
  aimvar_incr_per_hit: 0.2,

  aim_samples_fire_thres: 0.2,

  firearm_additional_hit_prob: 0,

  aim_rot_rules: [
    { aimvar: Math.PI / 2, aimspeed: 0.15 },
    { aimvar: Math.PI * 2, aimspeed: 0.1 },
  ],

  movespeed_rules: [
    // 기타: 옆뒤 혹은 뒤로 움직일 때, 10%로 이동합니다.
    { reldir: Math.PI * 2, multiplier: 0.1 },
    // 진행방향 좌/우 90도 안쪽을 바라볼 때 (옆걸음), 30%로 이동합니다.
    { reldir: Math.PI / 2, multiplier: 0.3 },
    // 진행방향 좌/우 30도 안쪽을 바라볼 때, 정상 속도로 이동합니다.
    { reldir: Math.PI / 6, multiplier: 1 },
  ]
};

export const team0_tmpl_indoor_smg = {
  ...tmpl_bulletproof_low_indoor,
  firearm_aimvar_incr_rot_cap: 0.05,

  firearm_ammo_max: 35,
  firearm_range: 250,
  firearm_aimvar_mult: 1,

  firearm_shoot_pattern: [],
  firearm_shoot_pattern_interval_sec: 0.125,

  firearm_projectile_per_shoot: 1,
  firearm_projectile_damage: 23,
  firearm_projectile_aimvar: Math.PI / 256,

  firearm_reload_duration: 2,
  firearm_reload_idle_duration: 10,

  aimvar_hold: Math.PI / 32,
  aimvar_hold_max: Math.PI / 256,
  aimvar_incr_per_shoot: 0.1,
  aimvar_incr_per_hit: 0.2,

  aim_samples_fire_thres: 0.8,

  firearm_additional_hit_prob: 0.1,

  aim_rot_rules: [
    { aimvar: Math.PI / 2, aimspeed: 0.1 },
    { aimvar: Math.PI * 2, aimspeed: 0.1 },
  ],

  movespeed_rules: [
    // 기타: 옆뒤 혹은 뒤로 움직일 때, 10%로 이동합니다.
    { reldir: Math.PI * 2, multiplier: 0.1 },
    // 진행방향 좌/우 90도 안쪽을 바라볼 때 (옆걸음), 30%로 이동합니다.
    { reldir: Math.PI / 2, multiplier: 0.3 },
    // 진행방향 좌/우 30도 안쪽을 바라볼 때, 정상 속도로 이동합니다.
    { reldir: Math.PI / 6, multiplier: 1 },
  ]
};

export const team0_tmpl_indoor_dmr = {
  ...tmpl_bulletproof_low_indoor,
  ...tmpl_firearm_dmr_mid,
  firearm_aimvar_incr_rot_cap: 0.1,

  aim_samples_fire_thres: 0.9,

  firearm_additional_hit_prob: 0.1,

  movespeed_rules: [
    // 기타: 옆뒤 혹은 뒤로 움직일 때, 10%로 이동합니다.
    { reldir: Math.PI * 2, multiplier: 0.1 },
    // 진행방향 좌/우 90도 안쪽을 바라볼 때 (옆걸음), 30%로 이동합니다.
    { reldir: Math.PI / 2, multiplier: 0.3 },
    // 진행방향 좌/우 30도 안쪽을 바라볼 때, 정상 속도로 이동합니다.
    { reldir: Math.PI / 6, multiplier: 1 },
  ]
};

